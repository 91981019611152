import {Link} from 'react-router-dom';
import {Link as TypographyLink, Stack, Typography} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Success = () => {
  const container = {border: '2px solid #00ac57', borderRadius: 2, p: 2, m: 'auto', mt: 10, maxWidth: 430};

  return (
    <Stack spacing={2} alignItems="center" sx={container}>
      <CheckCircleOutlineIcon color="success" sx={{fontSize: 120}} />
      <Typography variant="h4" fontWeight={500} textAlign="center">
        Новый договор успешно создан
      </Typography>
      <TypographyLink component={Link} to="/" replace={true} underline="hover">
        Вернуться на главную
      </TypographyLink>
    </Stack>
  );
};

export default Success;
