import {Button, IconButton, Stack, Typography, useMediaQuery} from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CloseIcon from '@mui/icons-material/Close';

const ListenerCardInfoTitle = ({studentNumber, studentQuantity, removeStudent, addStudent}) => {
  const isTablet = useMediaQuery('(min-width:768px)');
  const isDesktop = useMediaQuery('(min-width:1000px)');

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={{md: 2.5, s: 2}}>
      <Stack direction="row" alignItems="center" justifyContent={{md: 'space-between', s: 'start'}}>
        <Typography variant="h4" mr={{md: 2.5, s: 1.5}}>
          Слушатель №{studentNumber}
        </Typography>
        {isDesktop ? (
          <Button startIcon={<AddCircleRoundedIcon fontSize="20px" sx={{color: '#0249FE'}} />} onClick={addStudent}>
            <Typography variant="caption" color="#0249FE" fontWeight={500}>
              Добавить слушателя
            </Typography>
          </Button>
        ) : (
          <IconButton aria-label="delete" onClick={addStudent} sx={{p: 0}}>
            <AddCircleRoundedIcon fontSize="20px" sx={{color: '#0249FE'}} />
          </IconButton>
        )}
      </Stack>
      {studentQuantity > 1 && !isTablet ? (
        <IconButton aria-label="delete" onClick={removeStudent} sx={{p: 0}}>
          <CloseIcon fontSize="20px" />
        </IconButton>
      ) : null}
    </Stack>
  );
};

export default ListenerCardInfoTitle;
