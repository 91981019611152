import {CSSTransition} from 'react-transition-group';
import {Box, Skeleton, Typography} from '@mui/material';
import {studentSlice} from '../../../api/slices/student';

const VideoMaterial = ({programId, videoId}) => {
  const {data, isSuccess, isError, isLoading} = studentSlice.endpoints.getVideo.useQueryState({programId, videoId});

  const getCorrectLink = (link = '') => {
    if (link.includes('/watch?v=')) {
      return link.replace('/watch?v=', '/embed/').slice(0, 41);
    }
    if (link.includes('https://youtu.be/')) {
      return link.replace('https://youtu.be/', 'https://www.youtube.com/embed/').slice(0, 41);
    }
  };

  const link = getCorrectLink(data?.link);

  if (isLoading) {
    return <Skeleton width="100%" height="50vh" />;
  }

  return data?.link ? (
    <>
      <CSSTransition in={isSuccess} classNames="video" timeout={300} unmountOnExit>
        <iframe title="video" src={link} style={{width: '100%', height: '50vh', position: 'sticky', top: '10px'}} />
      </CSSTransition>
      {isError && (
        <Typography variant="body1" color="error">
          Приносим свои извинения, произошла ошибка на сервере, перезагрузите страницу или зайдите позже
        </Typography>
      )}
    </>
  ) : (
    <Box />
  );
};

export default VideoMaterial;
