import {Box, Divider, Typography} from '@mui/material';

const CustomerInformationBlock = ({name, children}) => {
  return (
    <Box>
      <Divider color="#89AAFF" sx={{mt: {lg: 3.5, sm: 2.5, s: 2}}} />
      <Typography variant="h6" mt={{lg: 3.5, sm: 2.5, s: 2}} mb={{lg: 3, sm: 2.5, s: 2}}>
        {name}
      </Typography>
      {children}
    </Box>
  );
};

export default CustomerInformationBlock;
