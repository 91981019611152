import {useState} from 'react';
import {Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {FormProvider, useForm, useWatch, Controller} from 'react-hook-form';
import {CSSTransition} from 'react-transition-group';
import {yupResolver} from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import IndividualForm from './modules/individualForm';
import EntityForm from './modules/entityForm';
import IndividualEntrepreneurForm from './modules/individualEntrepreneurForm';

import {schema} from './schema';
import {useCreateNewCustomerMutation} from '../../../../api/slices/user';
import {BASIS_TYPES, CustomerType} from '../../../../constants';

import removeEmptyFields, {trimFields} from '../../../../utils/removeEmptyFields';

const ContractInfo = ({close}) => {
  const [customerType, setCustomerType] = useState(CustomerType.individual);
  const [createNewCustomer, {isLoading, isError}] = useCreateNewCustomerMutation();

  const methods = useForm({
    resolver: yupResolver(schema[customerType]),
    defaultValues: {customerTypeId: CustomerType.individual},
  });

  const {handleSubmit, unregister, setValue, setError, setFocus, reset, control, getValues} = methods;

  const basisType = useWatch({control, name: 'basisTypeId'});
  const isForeignCitizenship = useWatch({control, name: 'isForeignCitizenship'});

  const autoFillCompanyFields = (e, data) => {
    const fullName = data?.data.management ? data?.data.management.name.split(' ') : '';
    const profession = data?.data.management ? data?.data.management.post : '';
    const date = dayjs(data?.data.ogrn_date).format('DD.MM.YYYY');

    setValue('inn', data?.data.inn);
    switch (data?.data.type) {
      case 'LEGAL':
        setValue('kpp', data?.data.kpp);
        setValue('directorSurname', fullName[0]);
        setValue('directorName', fullName[1]);
        setValue('directorSecondName', fullName[2]);
        setValue('directorProfession', profession);
        // setValue('basisDate', date);
        setValue('legalAddress', data?.data.address.unrestricted_value);
        break;
      case 'INDIVIDUAL':
        setValue('postAddress', data?.data.address.unrestricted_value);
        setValue('ogrn', data?.data.ogrn);
        setValue('directorSurname', data?.data.fio.surname);
        setValue('directorName', data?.data.fio.name);
        setValue('directorSecondName', data?.data.fio?.patronymic);
        break;
      default:
        return null;
    }
  };

  const handleChange = (e) => {
    setCustomerType(e.target.value);
    reset();
    setValue('customerTypeId', e.target.value);
  };

  const onSubmit = (formData) => {
    formData.passportIssuedDate = formData?.passportIssuedDate
      ? dayjs(formData.passportIssuedDate).format('DD.MM.YYYY')
      : null;
    formData.basisDate = formData?.basisDate ? dayjs(formData.basisDate).format('DD.MM.YYYY') : null;

    if (customerType === CustomerType.entity) {
      if (formData?.basisTypeId === BASIS_TYPES[0].id) {
        delete formData?.basisDate;
      }
    } else if (!dayjs(formData.basisDate, 'DD.MM.YYYY').isValid()) {
      delete formData?.basisDate;
    }

    let data = removeEmptyFields(formData);
    data = trimFields(data);

    createNewCustomer(data)
      .unwrap()
      .then((res) => {
        close();
      })
      .catch((err) => {
        for (let key in err.data.errors) {
          if (err.data.errors.hasOwnProperty(key)) {
            setError(key, {message: err.data.errors[key][0]});
            setFocus(key);
          }
        }
      });
  };

  const autoFillBankFields = (e, data) => {
    if (!data) {
      setValue('bik', null);
      setValue('kAccount', '');
    } else {
      setValue('bik', data.data.bic, {shouldValidate: true});
      setValue('kAccount', data.data.correspondent_account ?? '', {shouldValidate: true});
    }
  };

  const autoFillBikFields = (e, data) => {
    if (!data) {
      setValue('bank', null);
      setValue('kAccount', '');
    } else {
      setValue('bank', data.value, {shouldValidate: true});
      setValue('kAccount', data.data.correspondent_account ?? '', {shouldValidate: true});
    }
  };

  const clearBudgetFields = () => {
    unregister('budgetTypeId');
    unregister('personalAccount');
    unregister('departmentTitle');
    unregister('treasuryAccount');
    unregister('mainAccount');
  };

  return (
    <FormProvider {...methods}>
      <Box component="form">
        <FormControl fullWidth>
          <Controller
            control={control}
            name="customerTypeId"
            render={({field: {name, value, onChange}}) => (
              <RadioGroup
                id="customerType"
                name={name}
                value={value ?? ''}
                onChange={(e) => {
                  onChange(e);
                  handleChange(e);
                }}
                sx={{flexWrap: 'nowrap', mb: 2, flexDirection: {xs: 'column', sm: 'row'}}}
                className="radio-group">
                <FormControlLabel
                  value={CustomerType.individual}
                  control={<Radio />}
                  label="Физическое лицо"
                  className="radio-label"
                />
                <FormControlLabel
                  value={CustomerType.entity}
                  control={<Radio />}
                  label="Юридическое лицо"
                  className="radio-label"
                />
                <FormControlLabel
                  value={CustomerType.individualEntrepreneur}
                  control={<Radio />}
                  label="Индивидуальный предприниматель"
                  className="radio-label"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
        <Box sx={{mb: {sm: 2, s: 1}}}>
          <CSSTransition
            in={customerType === CustomerType.individual}
            timeout={300}
            classNames="customer"
            unmountOnExit>
            <IndividualForm isForeignCitizenship={isForeignCitizenship} />
          </CSSTransition>
          <CSSTransition in={customerType === CustomerType.entity} timeout={300} classNames="customer" unmountOnExit>
            <EntityForm
              basisType={basisType}
              autoFillCompanyFields={autoFillCompanyFields}
              autoFillBankFields={autoFillBankFields}
              autoFillBikFields={autoFillBikFields}
              clearBudgetFields={clearBudgetFields}
            />
          </CSSTransition>
          <CSSTransition
            in={customerType === CustomerType.individualEntrepreneur}
            timeout={300}
            classNames="customer"
            unmountOnExit>
            <IndividualEntrepreneurForm
              autoFillCompanyFields={autoFillCompanyFields}
              autoFillBankFields={autoFillBankFields}
              autoFillBikFields={autoFillBikFields}
            />
          </CSSTransition>
        </Box>
        {isError && (
          <Typography variant="body1" color="error" sx={{mb: {sm: 2, s: 1}}}>
            Ошибка создания заказчика
          </Typography>
        )}
        <LoadingButton
          type="button"
          variant="contained"
          color="primary"
          size="large"
          loading={isLoading}
          onClick={handleSubmit(onSubmit)}
          fullWidth
          loadingIndicator="Загрузка...">
          Создать заказчика
        </LoadingButton>
      </Box>
    </FormProvider>
  );
};

export default ContractInfo;
