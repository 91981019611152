import {Divider, Stack, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import Phase from './Phase';

const ModuleMobile = ({
  moduleId,
  name,
  number,
  currentPhase,
  isPhaseFinished,
  needDiplomaForTests,
  isAvailable,
  attemptCount,
}) => {
  const isPhaseAvailable = (currentPhase && isPhaseFinished) || isAvailable;

  return (
    <Stack spacing={1.5} mb={1.5}>
      {isPhaseAvailable ? (
        <Stack>
          <Link to={`${moduleId}/${number}/${name}`}>
            <b>{name || `Моудль ${number}`}</b>
          </Link>
          <Typography variant="subtitle1">(Учебные материалы, тестирование)</Typography>
        </Stack>
      ) : (
        <Stack>
          <Typography variant="body2">{name || `Моудль ${number}`}</Typography>
          <Typography variant="body2">Для открытия сдайте экзамен в предыдущем модуле</Typography>
        </Stack>
      )}
      <Stack direction={{xs: 'row', s: 'column'}} alignItems={{xs: 'center', s: 'start'}} spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body1">Попытки</Typography>
          <Typography variant="body1">
            <b>{attemptCount} из 3</b>
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="body1">Результат обучения</Typography>
          <Phase
            moduleId={moduleId}
            isBlocked={!currentPhase && !isAvailable}
            isPhaseFinished={isPhaseFinished}
            needDiploma={needDiplomaForTests || !isAvailable}
          />
        </Stack>
      </Stack>
      <Divider sx={{height: '2px', backgroundColor: '#89AAFF'}} />
    </Stack>
  );
};

export default ModuleMobile;
