const DeleteIcon = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.74761 7.99998L14.7413 2.00634C15.0862 1.66135 15.0862 1.10373 14.7413 0.758742C14.3963 0.413753 13.8386 0.413753 13.4937 0.758742L7.50001 6.75238L1.50634 0.758742C1.16135 0.413753 0.603732 0.413753 0.258742 0.758742C-0.0862475 1.10373 -0.0862475 1.66135 0.258742 2.00634L6.25239 7.99998L0.258742 13.9936C-0.0862475 14.3386 -0.0862475 14.8962 0.258742 15.2412C0.430798 15.4133 0.65667 15.4997 0.882542 15.4997C1.10841 15.4997 1.33429 15.4133 1.50634 15.2412L7.49998 9.24758L13.4936 15.2412C13.6657 15.4133 13.8916 15.4997 14.1174 15.4997C14.3433 15.4997 14.5692 15.4133 14.7412 15.2412C15.0862 14.8962 15.0862 14.3386 14.7412 13.9936L8.74761 7.99998Z"
        fill="#626262"
      />
    </svg>
  );
};

export default DeleteIcon;
