import {Link as TypographyLink, Stack, Typography, useMediaQuery} from '@mui/material';
import {Link} from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const AccountInfo = ({email, shortName, courseNumber}) => {
  const isDesktop = useMediaQuery('(min-width:768px)');

  return (
    <Stack alignItems="end">
      {isDesktop ? (
        <Stack>
          <Typography component={Link} to="/" variant="caption" color="#fff">
            {courseNumber}
          </Typography>
          <Stack>
            <TypographyLink href={`mailto:${email}`} color="#fff" underline="none" className="header__info-link">
              {email}
            </TypographyLink>
            <Typography component={Link} to="/" variant="caption" color="#fff">
              {shortName}
            </Typography>
          </Stack>
        </Stack>
      ) : (
        <Typography component={Link} to="/" sx={{lineHeight: 1}}>
          <AccountCircleIcon fontSize="medium" sx={{color: '#FFF'}} />
        </Typography>
      )}
    </Stack>
  );
};

export default AccountInfo;
