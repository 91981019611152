import {useState} from 'react';
import {toast} from 'react-toastify';
import {useParams} from 'react-router';
import DownloadIcon from '@mui/icons-material/Download';
import {LoadingButton} from '@mui/lab';

import store from '../../../../app/store';
import {contractSlice} from '../../../../api/slices/user';

import openFile from '../../../../utils/openFile';

const Document = ({format, name, documentTypeId, children}) => {
  const {id} = useParams();
  const [isFetching, setIsFetching] = useState(false);

  const downloadDocument = () => {
    setIsFetching(true);
    store
      .dispatch(contractSlice.endpoints.getDocument.initiate({id, documentTypeId, format}))
      .unwrap()
      .then((data) => {
        openFile(data, name, format);
      })
      .catch((err) => {
        toast.error('Произошла ошибка при загрузке документа, попробуйте позже', {
          autoClose: 2000,
          hideProgressBar: true,
        });
      })
      .finally(() => setIsFetching(false));
  };

  return (
    <LoadingButton
      loading={isFetching}
      loadingPosition="end"
      endIcon={<DownloadIcon />}
      variant="text"
      size="small"
      onClick={downloadDocument}>
      {children}
    </LoadingButton>
  );
};

export default Document;
