import {Box, Stack, Typography} from '@mui/material';
import dayjs from 'dayjs';

const Item = ({label, value, editable, children, itemProps, isDate = false}) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3} {...itemProps}>
      <Typography variant="body2" sx={{flex: {sm: '1 0 150px', xs: '1 0 120px', s: '1 0 80px'}}}>
        {label}
      </Typography>
      {editable ? (
        <Box sx={{flex: '0 1 100%'}}>{children}</Box>
      ) : (
        <Typography variant="body1" sx={{flex: '100%'}}>
          {isDate ? dayjs(value, 'DD.MM.YYYY').format('DD.MM.YYYY') : value}
        </Typography>
      )}
    </Stack>
  );
};

export default Item;
