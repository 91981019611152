import {Box, Button, Divider, Grid, Stack, Typography} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link} from 'react-router-dom';
import dayjs from 'dayjs';

import {Status} from '../Status';

const CustomerMobile = ({
  id,
  customerId,
  customerTypeId,
  customerName,
  creationDate,
  number,
  cost,
  isPayed,
  contractStatusName,
}) => {
  return (
    <Box>
      <Grid
        display="grid"
        gridTemplateColumns="1fr auto"
        gridTemplateRows={{xs: 'auto auto', s: 'auto'}}
        justifyContent={{xs: 'space-between'}}
        mb={{s: 2}}>
        <Box gridRow={{xs: '1', s: '1'}} mt={{xs: 0, s: 1}}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2">Заказчик</Typography>
            <Typography variant="h5" component={Link} to={`user/${customerId}`} color="#000">
              {customerName}
            </Typography>
          </Stack>
        </Box>
        <Box gridRow={{xs: '2', s: '3'}}>
          <Stack direction="row" spacing={1} mt={{s: 1}}>
            <Typography variant="subtitle2">Дата</Typography>
            <Typography variant="h5">{dayjs(creationDate).format('DD.MM.YYYY')}</Typography>
          </Stack>
          <Stack direction="row" spacing={1} mt={{s: 1}}>
            <Typography variant="subtitle2">Договор</Typography>
            <Typography variant="h5" component={Link} to={`requests/${id}`} color="#0249FE">
              {number}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center" mt={{s: 1}}>
            <Typography variant="subtitle2">Оплата</Typography>
            <Typography variant="h5">{cost} ₽</Typography>
            <Status id={id} customerType={customerTypeId} isPayed={isPayed} contract={number} />
          </Stack>
        </Box>
        <Box gridRow={{xs: '1', s: '2'}} mt={{xs: 0, s: 1}}>
          <Stack direction={{xs: 'column', s: 'row'}} alignItems={'center'} spacing={{xs: 0, s: 1}}>
            <Typography variant="subtitle2">Статус договора</Typography>

            <Typography variant="body1">{contractStatusName}</Typography>
          </Stack>
        </Box>
        <Box gridRow={{xs: '2', s: '4'}} mt={{s: 0.5}} alignSelf={{xs: 'end'}}>
          <Stack direction={{xs: 'column', s: 'row'}} alignItems="center" spacing={{xs: 0.5, s: 1}}>
            <Typography variant="subtitle2">Данные по договору</Typography>
            <Button
              component={Link}
              to={`requests/${id}`}
              variant="contained"
              color="secondary"
              sx={{p: {xs: 1, s: 0.5}, lineHeight: 'normal'}}
              endIcon={<NavigateNextIcon />}>
              Посмотреть
            </Button>
          </Stack>
        </Box>
      </Grid>
      <Divider color="#57a5ff" />
    </Box>
  );
};

export default CustomerMobile;
