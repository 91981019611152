import {Outlet, useLocation} from 'react-router';
import {Link} from 'react-router-dom';
import {Box, Container, Stack, Skeleton} from '@mui/material';

import {useGetUserInfoQuery} from '../../api/slices/auth';
import {useGetCompanySettingsQuery} from '../../api/slices/public';
import {accountType} from '../../constants';

import BonusesHistory from '../../features/Bonuses';
import PhoneNumber from './modules/PhoneNumber';
import AccountInfo from './modules/AccountInfo';
import Logout from './modules/Logout';

import './styles.scss';
import {useGetStudentContractQuery} from '../../api/slices/student';
import {useEffect} from 'react';

const Root = () => {
  const {data: companySettings, isLoading: isCompanySettingsLoading} = useGetCompanySettingsQuery();
  const {data: userInfo, isLoading: isUserInfoLoading} = useGetUserInfoQuery();
  const {data: studentContract} = useGetStudentContractQuery();
  let location = useLocation();
  const accountId = localStorage.getItem('accountId');
  const isLoading = isCompanySettingsLoading || isUserInfoLoading;

  document.getElementsByTagName('title')[0].innerText = companySettings?.title;

  useEffect(() => {
    if (!companySettings) return;
    window.ym(companySettings.htmlCounterYaMetrika, 'hit', window.location.href);
  }, [location]);

  return isLoading ? (
    <>
      <div className="header">
        <Container maxWidth="xl">
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={{md: 2, s: 1}} alignItems="center">
              <Skeleton sx={{height: {md: 80, sm: 60, s: 40}, width: {md: 235, sm: 215, s: 150}}} />
              <Skeleton sx={{height: {md: 47, sm: 40, s: 30}, width: {md: 175, sm: 140, s: 20}}} />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={{xl: 7, lg: 5, md: 4, xs: 2, s: 1}}>
              {accountId === accountType.adminGroup && (
                <Skeleton sx={{height: {s: 40}, width: {md: 145, sm: 130, s: 30}}} />
              )}
              <Skeleton sx={{height: {md: 45, sm: 60, s: 40}, width: {md: 220, sm: 175, s: 30}}} />
              <Skeleton sx={{height: {s: 40}, width: {md: 90, s: 30}}} />
            </Stack>
          </Stack>
        </Container>
      </div>
      <div id="detail">
        <Container maxWidth="xl">
          <Outlet />
        </Container>
      </div>
    </>
  ) : (
    <>
      <div className="header">
        <Container maxWidth="xl">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack
              direction="row"
              alignItems="center"
              // mt={{s: 1, md: 2}}
              // mb={{s: 1, md: 2}}
              spacing={{s: 1, sm: 2.5, md: 3.5}}>
              <Box className="header__title">
                {!companySettings?.companyLogo ? (
                  <Link
                    href={companySettings?.site}
                    className="header__title-link"
                    variant="h6"
                    target="_blank"
                    rel="noreferrer">
                    {companySettings?.title}
                  </Link>
                ) : (
                  <a href={companySettings?.site} target="_blank" rel="noreferrer">
                    <img src={companySettings?.companyLogo} alt={companySettings?.title}></img>
                  </a>
                )}
                {/* <a href={companySettings?.site} className="header__title-link" target="_blank" rel="noreferrer">
                  {companySettings?.title}
                </a> */}
              </Box>
              <PhoneNumber phoneNumber={companySettings?.phone} />
            </Stack>
            <Stack direction="row" spacing={{md: 3.5, sm: 2.5, xs: 2, s: 1}} alignItems="center">
              {accountId === accountType.adminGroup && <BonusesHistory bonusesAmount={userInfo?.bonuses} />}
              <AccountInfo
                email={userInfo?.email}
                shortName={userInfo?.shortName}
                courseNumber={studentContract?.number}
              />
              <Logout />
            </Stack>
          </Stack>
          {/*{isLoginAsAdmin && <span onClick={backToAdmin}>Кабинет администратора</span>}*/}
          {/*{loginAsStudent && <span onClick={backToCustomer}>Личный кабинет</span>}*/}
        </Container>
      </div>
      <div id="detail">
        <Container maxWidth="xl">
          <Outlet />
        </Container>
      </div>
    </>
  );
};

export default Root;
