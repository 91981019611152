import {Box, Button, Link as TypographyLink, Stack, TableCell, TableRow, Typography} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link} from 'react-router-dom';

import {publicSlice} from '../../../api/slices/public';
import useToggle from '../../../hooks/useToggle';

import MUIModal from '../../../components/MUIModal';
import FileUploader from '../../../components/FileUploader';
import {useGetStudentContractQuery} from '../../../api/slices/student';

const StudentDesktopBody = ({
  id,
  trainingName,
  trainingFullName,
  trainingText,
  currentPhase,
  phaseCount,
  needDiplomaForAll = false,
  isDiplomaUploaded = false,
  isDiplomaAccepted = true,
  needPhotoSign = false,
  isPhotoSign = true,
}) => {
  const {data} = publicSlice.endpoints.getCompanySettings.useQueryState();
  const {data: contractInfo} = useGetStudentContractQuery();
  const [isDiplomaFormOpen, toggleDiplomaForm] = useToggle();

  const isNeedDiploma = needDiplomaForAll && !isDiplomaAccepted;
  const isNeedPhotoSign = needPhotoSign && !isPhotoSign;

  const programBlocked = isNeedDiploma || isNeedPhotoSign;

  console.log(contractInfo);

  return (
    <>
      <TableRow>
        <TableCell sx={{borderTop: '1px solid #0249FE', borderBottom: 'unset'}}>
          <Typography variant="body1" color={programBlocked ? '#727272' : 'initial'}>
            {trainingFullName}
          </Typography>
          <Typography variant="body2" color={programBlocked ? '#727272' : 'initial'}>
            {trainingText}
          </Typography>
        </TableCell>
        <TableCell sx={{borderTop: '1px solid #0249FE', borderBottom: 'unset'}}>
          {!contractInfo?.isArchival ? (
            programBlocked ? (
              isDiplomaUploaded ? (
                <Typography variant="body1">Ваш диплом уже загружен и ожидает проверки</Typography>
              ) : (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body1">Для разблокировки загрузите диплом</Typography>
                  <LockIcon />
                </Stack>
              )
            ) : (
              <Typography variant="body2" noWrap>
                Модуль {currentPhase ?? 1} из {phaseCount}
              </Typography>
            )
          ) : (
            <Typography variant="body1" textAlign="center">
              Договор в архиве
            </Typography>
          )}
        </TableCell>
        {!contractInfo?.isArchival && (
          <TableCell align="center" sx={{borderTop: '1px solid #0249FE', borderBottom: 'unset'}}>
            {programBlocked ? (
              <Button
                variant="contained"
                color="primary"
                size="small"
                disabled={isDiplomaUploaded}
                onClick={toggleDiplomaForm}>
                Загрузить
              </Button>
            ) : (
              <Button
                component={Link}
                to={`program/${id}`}
                variant="contained"
                color="secondary"
                sx={{p: {xs: 1, s: 0.5}, lineHeight: 'normal'}}
                endIcon={<NavigateNextIcon />}>
                Посмотреть
              </Button>
            )}
          </TableCell>
        )}
      </TableRow>
      <MUIModal isOpen={isDiplomaFormOpen} toggle={toggleDiplomaForm} small>
        {needPhotoSign ? (
          <Box sx={{minWidth: 280}}>
            <Typography>
              Внимание! Для доступа к экзамену по программе «{trainingName}» необходимо на почту
              <TypographyLink href={`mailto:${data?.email}`}> {data?.email} </TypographyLink>
              направить сканы фото слушателя размером 3x4 см и его подписи на белом фоне. Удостоверение изготавливается
              и ламинируется в учебном центре.
            </Typography>
          </Box>
        ) : (
          <FileUploader close={toggleDiplomaForm} email={data?.email} />
        )}
      </MUIModal>
    </>
  );
};

export default StudentDesktopBody;
