import {createPortal} from 'react-dom';
import {CSSTransition} from 'react-transition-group';

import CloseIcon from '@mui/icons-material/Close';

import classes from './styles.module.scss';
import {Typography} from '@mui/material';

const Drawer = ({onClose, title = '', children, show}) => {
  return createPortal(
    <CSSTransition in={show} timeout={300} unmountOnExit classNames="drawer">
      <div
        className={classes.drawer}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClose();
        }}>
        <div className={classes.content} onClick={(e) => e.stopPropagation()}>
          <div className={classes.header}>
            {title && <Typography variant="h2">{title}</Typography>}
            <button className={classes.close} onClick={onClose}>
              <CloseIcon />
            </button>
          </div>
          <div className={classes.body}>{children}</div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById('drawer-root')
  );
};

export default Drawer;
