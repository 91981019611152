import {useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {Button, Stack, Typography} from '@mui/material';

const TestAttention = ({close}) => {
  const {programId, moduleId} = useParams();

  return (
    <Stack spacing={1.5}>
      <Typography variant="h2">Пробное тестирование</Typography>
      <Typography variant="body1">
        Пробный тест <span style={{color: '#0249fe'}}>не является обязательным</span>
      </Typography>
      <Typography variant="body1">Вы можете его пройти, чтобы оценить свою готовность к экзамену</Typography>
      <Typography variant="body1">
        Количество попыток для пробного теста <b>не ограничено</b>
      </Typography>
      <Typography variant="body1">
        Рекомендуем переходить к экзамену только в том случае,
        <span color="primary">если в пробном тесте вы ответили верно на большинство вопросов</span>
      </Typography>
      <Stack spacing={1.5}>
        <Button variant="contained" color="secondary" onClick={close}>
          Я еще не готов
        </Button>
        <Button component={Link} to={`/test/${programId}/${moduleId}`} variant="contained" color="primary">
          Начать пробный тест
        </Button>
      </Stack>
    </Stack>
  );
};

export default TestAttention;
