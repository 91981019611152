import {useParams} from 'react-router';
import {toast} from 'react-toastify';
import {Box, Stack, Typography, Skeleton, Divider, Button} from '@mui/material';

import {
  useAddContractToRelationMutation,
  useDeleteContractFromRelationMutation,
  useDeleteRelationMutation,
  useGetContractsRelationsQuery,
  useGetAvailableContractsRelationsQuery,
  getDocumentList,
} from '../../../../api/slices/user';

const ContractRelations = () => {
  const {id} = useParams();

  const {data: customer} = getDocumentList.useQueryState(id);
  const {data, error, isError, isSuccess, isLoading} = useGetContractsRelationsQuery(id);
  // const {data: availableRelations} = useGetAvailableContractsRelationsQuery(customer?.customerId);
  // const [addContractToRelation] = useAddContractToRelationMutation();
  // const [deleteContractFromRelation] = useDeleteContractFromRelationMutation();
  // const [deleteRelation] = useDeleteRelationMutation();

  // const relatiansForAdd = availableRelations
  //   ? availableRelations.map((contract) => ({
  //       relationContractId: contract.id,
  //       number: Array.isArray(contract.contracts)
  //         ? contract.contracts
  //             .map((item) => item.number)
  //             .toString()
  //             .replaceAll(',', ', ')
  //         : contract.contracts.number,
  //     }))
  //   : [];

  // const addContractRelation = (relationContractId) => {
  //   addContractToRelation({contractId: id, relationContractId})
  //     .unwrap()
  //     .then(() => toast.success('Договор успешно привязан'))
  //     .catch((err) => {
  //       toast.error('Вначале отвяжите договор от текущей связки');
  //     });
  // };

  // const deleteContractRelation = () => {
  //   deleteContractFromRelation(id)
  //     .unwrap()
  //     .then((res) => toast.success('Договор из связки успешно удален'))
  //     .catch((err) => toast.error('Связка не найдена'));
  // };

  // const deleteFullRelation = () => {
  //   deleteRelation(data?.id)
  //     .unwrap()
  //     .then(() => toast.success('Связка успешно удалена'))
  //     .catch((err) => toast.error('Связка не найдена'));
  // };

  return (
    <Box>
      <Typography variant="h2">Связанные договора</Typography>
      {isLoading && <Skeleton variant="rectangular" height={40} />}
      {isError && (
        <Typography>Связанные договора отсутствуют</Typography>
        // <Stack spacing={2} mt={2}>
        //   <Typography>Доступные договора для связки:</Typography>
        //   {relatiansForAdd
        //     ? relatiansForAdd.map((relation) => (
        //         <Stack spacing={2} mt={2} key={relation.relationContractId}>
        //           <Typography>{relation.number}</Typography>
        //           <Button variant="contained" onClick={() => addContractRelation(relation.relationContractId)}>
        //             Добавить договор в связку
        //           </Button>
        //         </Stack>
        //       ))
        //     : null}
        // </Stack>
      )}

      {isSuccess && (
        <Stack spacing={2} mt={2}>
          <Stack spacing={1}>
            {data.contracts.map((contract) => (
              <Box key={contract.id}>
                <Divider sx={{marginBottom: 2, backgroundColor: '#89AAFF'}} />
                <Typography variant="body1" gutterBottom>
                  {contract.number}
                </Typography>
              </Box>
            ))}
          </Stack>
          {/* <Button variant="contained" color="error" onClick={deleteContractRelation} disabled={!isSuccess}>
            Удалить договор из связки
          </Button>
          <Button variant="outlined" color="error" onClick={deleteFullRelation} disabled={!isSuccess}>
            Удалить связку целиком
          </Button> */}
        </Stack>
      )}
    </Box>
  );
};

export default ContractRelations;
