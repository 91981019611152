import {Controller} from 'react-hook-form';
import {TextField} from '@mui/material';

const ControlledTextArea = ({control, name, label, maxLength}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange, onBlur, name, value, ref}}) => (
        <TextField
          multiline
          fullWidth
          size="small"
          inputProps={{maxLength}}
          name={name}
          value={value ?? ''}
          label={label}
          onChange={onChange}
          minRows={3}
          onBlur={onBlur}
          helperText={value ? `${value.length}/${maxLength} символов` : null}
          inputRef={ref}
        />
      )}
    />
  );
};

export default ControlledTextArea;
