import {useParams} from 'react-router';
import {Box, Button, Stack, Typography, Skeleton} from '@mui/material';
import {CustomerType} from '../../../../constants';
import store from '../../../../app/store';
import {contractSlice, useGetDocumentListQuery} from '../../../../api/slices/user';

import ContractRelations from '../ContractRelations';
import useToggle from '../../../../hooks/useToggle';
import MUIModal from '../../../../components/MUIModal';
import Documents from '../Documents';

const CustomersInformation = ({
  activeListeners,
  disabledListeners,
  isAvailableAddListeners,
  isLoading,
  toggleAddingForm,
}) => {
  const {id} = useParams();
  const {data: documentsData} = useGetDocumentListQuery(id);

  const [isRelationOpen, toggleRelation] = useToggle(false);
  const [isDocumentsOpen, toggleDocuments] = useToggle(false);

  const payContract = () => {
    store
      .dispatch(contractSlice.endpoints.payContract.initiate(id))
      .unwrap()
      .then(({redirectUrl}) => window.location.assign(redirectUrl));
  };

  return isLoading ? (
    <Stack direction={{sm: 'row'}} spacing={{sm: 2, s: 1}} mb={{lg: 3, s: 2}} justifyContent="space-between">
      <Stack
        direction={{sm: 'row'}}
        justifyContent={{sm: 'space-between'}}
        flexBasis="100%"
        flexWrap="wrap"
        spacing={{md: 2, s: 1}}
        useFlexGap>
        <Stack direction={{sm: 'row'}} spacing={{sm: 2, s: 1}}>
          <Skeleton height={35} sx={{width: {md: 240, s: 300}}} />
          <Skeleton height={35} sx={{width: {md: 240, s: 300}}} />
        </Stack>
        <Stack direction={{sm: 'row'}} spacing={{sm: 2, s: 1}}>
          <Skeleton height={35} sx={{width: {md: 240, s: 300}}} />
          <Skeleton height={35} sx={{width: {md: 240, s: 300}}} />
        </Stack>
      </Stack>
      <Stack spacing={{s: 1}} alignItems="space-between">
        {documentsData?.contractId === CustomerType.individual ? (
          <Skeleton height={35} sx={{width: {md: 240, s: 300}}} />
        ) : null}
        {isAvailableAddListeners ? null : <Skeleton height={35} sx={{width: {md: 240, s: 300}}} />}
      </Stack>
    </Stack>
  ) : (
    <Box>
      <Stack direction={{sm: 'row'}} spacing={{sm: 2, s: 1}} mb={{lg: 3, s: 2}} justifyContent="space-between">
        <Stack
          direction={{sm: 'row'}}
          justifyContent={{sm: 'space-between'}}
          flexBasis="100%"
          flexWrap="wrap"
          spacing={{md: 2, s: 1}}
          useFlexGap>
          <Stack direction={{sm: 'row'}} spacing={{sm: 2, s: 1}} alignItems={{md: 'center'}}>
            <Button variant="contained" color="secondary" onClick={toggleDocuments}>
              Скачать документы
            </Button>
            <Button variant="contained" color="secondary" onClick={toggleRelation}>
              Связанные договоры
            </Button>
          </Stack>
          <Stack direction={{sm: 'row'}} spacing={{sm: 2, s: 1}} alignItems="center">
            <Box>
              <Typography variant="subtitle2">
                Действующие слушатели <b>{activeListeners}</b>
              </Typography>
            </Box>
            <Box>
              <Typography variant="subtitle2">
                В процессе добавления <b>{disabledListeners}</b>
              </Typography>
            </Box>
          </Stack>
        </Stack>
        <Stack spacing={{s: 1}} alignItems="space-between" flexShrink={0}>
          {documentsData?.contractId === CustomerType.individual ? (
            <Button variant="contained" color="secondary" onClick={payContract}>
              Оплатить договор
            </Button>
          ) : null}
          {isAvailableAddListeners ? null : (
            <Button variant="contained" color="primary" onClick={toggleAddingForm}>
              Добавить слушателя
            </Button>
          )}
        </Stack>
      </Stack>
      <MUIModal isOpen={isRelationOpen} toggle={toggleRelation} small>
        <ContractRelations />
      </MUIModal>
      <MUIModal isOpen={isDocumentsOpen} toggle={toggleDocuments} small>
        <Documents />
      </MUIModal>
    </Box>
  );
};

export default CustomersInformation;
