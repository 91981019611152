import {useParams} from 'react-router';
import {Typography, Stack, Skeleton, Button} from '@mui/material';

import classes from '../styles.module.scss';
import {formatSNILS} from '../../../../utils/formatSNILS';
import {getContractInfo} from '../../../../api/slices/user';
import dayjs from 'dayjs';

const CustomerInfo = ({customer, edit, disabled}) => {
  const {id} = useParams();
  const snils = formatSNILS(customer.SNILS);

  const {data: contractInfo, isLoading: isContractInfoLoading} = getContractInfo.useQueryState(id);

  const birthdate = (customer) => {
    if (customer?.birthday) {
      if (dayjs(customer.birthday).isValid()) {
        return dayjs(customer.birthday).format('DD.MM.YYYY');
      } else return customer.birthday;
    }
    return '';
  };

  return (
    <div className={classes.drawer}>
      <div className={classes.content}>
        <p className={classes.full__name}>
          {customer.surname} {customer.firstname} {customer.secondname}
        </p>
        <div className={classes.item}>
          <span className={classes.label}>Дата рождения</span>
          {customer.birthday ? (
            <span className={classes.value}>{birthdate(customer)}</span>
          ) : (
            <span className={classes.label}>Отсутствует</span>
          )}
        </div>
        <div className={classes.item}>
          <span className={classes.label}>СНИЛС</span>
          {customer.SNILS ? (
            <span className={classes.value}>{snils}</span>
          ) : (
            <span className={classes.label}>Отсутствует</span>
          )}
        </div>
        <div className={classes.item}>
          <span className={classes.label}>Эл. почта</span>
          {customer.email ? (
            <span className={classes.value}>{customer.email}</span>
          ) : (
            <span className={classes.label}>Отсутствует</span>
          )}
        </div>
        <div className={classes.item}>
          <span className={classes.label}>Место работы</span>
          <span className={classes.value}>{customer.jobPlace}</span>
        </div>
        <div className={classes.item}>
          <span className={classes.label}>Должность</span>
          <span className={classes.value}>{customer.profession}</span>
        </div>
        <div className={classes.item}>
          <span className={classes.label}>Номер курса</span>
          <span className={classes.value}>{customer.courseNumber}</span>
        </div>
        <div className={classes.item}>
          <span className={classes.label}>Программы обучения</span>
          <div className={classes.programs}>
            {customer?.studentTrainings.map((elem, index) => (
              <Stack direction="row" spacing={2} justifyContent="space-between" key={index}>
                <Typography>{elem.trainingProgramName}</Typography>
                {elem.isExamFinished ? (
                  <Typography color="#4caf50">Cдан</Typography>
                ) : (
                  <Typography color="error">Не сдан</Typography>
                )}
              </Stack>
            ))}
          </div>
        </div>
      </div>
      {isContractInfoLoading ? (
        <Skeleton variant="rectangular" width={160} height={40} />
      ) : contractInfo?.data?.inArchiveDate ? null : (
        <Button disabled={disabled} variant="contained" color="primary" onClick={edit}>
          Редактировать данные
        </Button>
      )}
    </div>
  );
};

export default CustomerInfo;
