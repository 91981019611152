import React, {useEffect} from 'react';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import {skipToken} from '@reduxjs/toolkit/query';
import {accountType, AUTH_URL} from '../constants';

import CustomerInformation from '../pages/admin/CustomerInformation';
import ContractListeners from '../pages/admin/ContractListeners';
import CustomerPersonalArea from '../pages/admin/CustomerPersonalArea';
import CreateNewContract from '../pages/admin/CreateNewContract';
import StudentPersonalArea from '../pages/StudentPersonalArea';
import Education from '../pages/Education';
import Success from '../pages/Success';
import Loader from '../components/Loader';
import {Result} from '../pages/Result';
import KnowledgeControl from '../pages/KnowledgeControl';
import Materials from '../pages/educationMaterials';
import ErrorPage from '../pages/Error';
import Root from '../pages/Root';

import {getParameterByName} from '../utils/utils';
import {useGetUserInfoQuery} from '../api/slices/auth';
import {useGetCompanySettingsQuery} from '../api/slices/public';

export const routes = {
  admin: [
    {
      path: '/',
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {path: '/', element: <CustomerPersonalArea />},
        {path: 'user/:id', element: <CustomerInformation />},
        {path: 'requests/:id', element: <ContractListeners />},
        {path: 'create-contract', element: <CreateNewContract />},
        {path: 'create-contract/succeed/:customer', element: <Success />},
      ],
    },
  ],
  user: [
    {
      path: '/',
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {path: '/', element: <StudentPersonalArea />},
        {path: '/program/:programId', element: <Education />},
        {path: '/program/:programId/:moduleId/:moduleNumber/:moduleName', element: <Materials />},
        {path: '/:knowledgeType/:programId/:moduleId', element: <KnowledgeControl />},
        {path: '/result/:knowledgeType/:programId/:moduleId/:showEisot?', element: <Result />},
      ],
    },
  ],
};

const App = () => {
  const ACCESS_TOKEN = getParameterByName('accessToken');
  const ACCOUNT_ID = getParameterByName('accountId');
  const LOGIN_AS_ADMIN = getParameterByName('loginAsAdmin');

  if (ACCESS_TOKEN && ACCOUNT_ID) {
    localStorage.setItem('accessToken', ACCESS_TOKEN);
    localStorage.setItem('accountId', ACCOUNT_ID);
  }

  if (LOGIN_AS_ADMIN) {
    localStorage.setItem('loginAsAdmin', LOGIN_AS_ADMIN);
  }

  const accountId = localStorage.getItem('accountId');
  const accessToken = localStorage.getItem('accessToken');

  const {isError, isSuccess} = useGetUserInfoQuery(accountId || accessToken ? null : skipToken);
  const {data: companyInfo} = useGetCompanySettingsQuery();

  if (companyInfo?.htmlChat && companyInfo?.htmlCounterYaMetrika) {
    const chat = new DOMParser().parseFromString(companyInfo?.htmlChat, 'application/xml');

    const chatScript = document.createElement('script');
    chatScript.type = 'text/javascript';
    chatScript.innerText = chat.documentElement.innerHTML;
    document.body.appendChild(chatScript);
  }

  if (isError) {
    window.location.replace(AUTH_URL);
  }

  useEffect(() => {
    if (!ACCESS_TOKEN && !accessToken) {
      window.location.replace(AUTH_URL);
    }
    if (accountId === accountType.adminGroup) {
      localStorage.removeItem('loginAsStudent');
    }
    if (ACCESS_TOKEN) {
      window.history.replaceState({}, document.title, '/');
    }
  }, [ACCESS_TOKEN, accessToken, accountId]);

  const router = createBrowserRouter(accountId === accountType.adminGroup ? routes.admin : routes.user);

  return (
    <div className="page">
      {!isSuccess ? <Loader /> : <RouterProvider router={router} fallbackElement={<Loader />} />}
    </div>
  );
};

export default App;
