import {IconButton, Stack, Typography} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {useLocation, useNavigate} from 'react-router';

const PageTitle = ({children, onBackButtonClick}) => {
  const location = useLocation();

  const handleNavigateButton = () => {
    onBackButtonClick && onBackButtonClick();
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={{md: 3, sm: 2, s: 1}}
      mt={{lg: 3.5, md: 5, sm: 2.5, s: 3.5}}
      mb={{lg: 3.5, md: 5, sm: 3.5, s: 2.5}}>
      {location.pathname !== '/' && (
        <IconButton onClick={handleNavigateButton} sx={{color: '#000'}}>
          <ArrowBackIosNewIcon />
        </IconButton>
      )}
      <Typography variant="h1" textAlign="center">
        {children}
      </Typography>
    </Stack>
  );
};

export default PageTitle;
