function removeEmptyFields(data) {
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof Object) {
      removeEmptyFields(data[key]);
    }
    if (data[key] === '' || data[key] == null) {
      delete data[key];
    }
  });
  return data;
}

export function trimFields(data) {
  Object.keys(data).forEach((key) => {
    if (data[key] instanceof String) {
      data[key] = data[key].trim();
    }
  });
  return data;
}

export default removeEmptyFields;
