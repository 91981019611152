import {Button, Typography, Stack, Skeleton, Box, useMediaQuery} from '@mui/material';
import {useGetStudentContractQuery, useGetTrainingsQuery, useShowPaymentButtonQuery} from '../../api/slices/student';
import PersonalInfo from './PersonalInfo';

import useToggle from '../../hooks/useToggle';
import FileUploader from '../../components/FileUploader';
import {publicSlice} from '../../api/slices/public';

import PageTitle from '../../components/Title/PageTitle';
import {useGetUserInfoQuery} from '../../api/slices/auth';
import MUIModal from '../../components/MUIModal';

import StudentMobile from './modules/studentMobile';
import StudentDesktop from './modules/studentDesktop';
import Loader from '../../components/Loader';

const StudentPersonalArea = () => {
  const {data: paymentInfo, isSuccess: isShowPaymentSuccess} = useShowPaymentButtonQuery();
  const {data: contractInfo} = useGetStudentContractQuery();
  const {data: user} = useGetUserInfoQuery();
  const {data: companySettings} = publicSlice.endpoints.getCompanySettings.useQueryState();
  const {data: trainings = [], isSuccess, isError, error, isLoading} = useGetTrainingsQuery();

  const [isDiplomaFormOpen, toggleDiplomaForm] = useToggle();

  const isDesktop = useMediaQuery('(min-width:768px)');

  const getFullName = () => {
    if (user?.secondname) {
      return `${user?.surname} ${user?.firstname} ${user?.secondname}`;
    }
    return `${user?.surname} ${user?.firstname}`;
  };

  const fullName = getFullName();

  const isNeedDiploma = trainings.find((training) => training.needDiplomForAll && !training.isDiplomAccepted);
  const isDiplomUploaded = trainings.find((training) => training.isDiplomUploaded);

  return isLoading ? (
    <Loader />
  ) : (
    <Box>
      {isSuccess && (
        <Box>
          <PageTitle>{fullName}</PageTitle>
          <Stack direction={'row'} alignItems={'center'} mb={2}>
            <Typography variant="body2" sx={{flex: 1}} textAlign={'center'}>
              Номер договора <b>{contractInfo?.number}</b>
            </Typography>

            {isShowPaymentSuccess && paymentInfo?.result === true && (
              <a href={paymentInfo.link} target="_blank">
                <Button variant="contained" size="large" sx={{mr: 2}}>
                  Оплатить обучение
                </Button>
              </a>
            )}
          </Stack>
          {isDesktop ? (
            <StudentDesktop trainings={trainings} />
          ) : (
            trainings.map((training, index) => (
              <StudentMobile
                key={training.trainingId}
                id={training.trainingId}
                trainingName={training.trainingName}
                trainingFullName={training.trainingTypeFullName}
                trainingText={training.trainingText}
                currentPhase={training.currentPhase.number ?? 1}
                phaseCount={training.phaseCount}
                needDiplomaForAll={training.needDiplomForAll}
                isDiplomaUploaded={training.isDiplomUploaded}
                isDiplomaAccepted={training.isDiplomAccepted}
                needPhotoSign={training.needPhotoSign}
                isPhotoSign={training.isPhotoSign}
                theLastElement={index === trainings.length - 1}
              />
            ))
          )}

          {!contractInfo?.isArchival && isNeedDiploma?.needDiplomForAll && (
            <Stack spacing={2}>
              <Typography variant="body1" align="justify" color="error">
                <b>Внимание!</b> Для получения доступа к учебным материалам необходимо загрузить скан-копию документа,
                подтверждающего наличие у слушателя высшего или среднего профессионального образования (диплом) (В
                соответствии с п. 3 ст. 76 Федерального закона от 29.12.2012 N 273-ФЗ "Об образовании в Российской
                Федерации"). Проверка документа об образовании слушателя и открытие доступа к пробному тесту/экзамену
                производится в течение суток.
              </Typography>
              {isDiplomUploaded ? null : (
                <div>
                  <Button variant="contained" onClick={toggleDiplomaForm}>
                    Загрузить диплом
                  </Button>
                  <MUIModal isOpen={isDiplomaFormOpen} toggle={toggleDiplomaForm} small>
                    <FileUploader close={toggleDiplomaForm} email={companySettings?.email} />
                  </MUIModal>
                </div>
              )}
            </Stack>
          )}
        </Box>
      )}
      {isError && <PersonalInfo requiredFields={error?.data} />}
    </Box>
  );
};

export default StudentPersonalArea;
