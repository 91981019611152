import {useRef} from 'react';
import {skipToken} from '@reduxjs/toolkit/query';
import {Box, Button, Divider, Stack, Typography} from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {CSSTransition} from 'react-transition-group';

import store from '../../../app/store';
import {studentSlice} from '../../../api/slices/student';
import {EDUCATION_MATERIAL_TYPE} from '../../../constants';

const Lecture = ({name, programId, lectureId, educationType, isActive, setActive, isDesktop}) => {
  const ref = useRef();
  const openLecture = () => {
    setActive(lectureId);
    store.dispatch(studentSlice.endpoints.getLecture.initiate({programId, lectureId}, {forceRefetch: !isActive}));
  };

  const openAdLink = () => {
    store
      .dispatch(studentSlice.endpoints.getLecture.initiate({programId, lectureId}))
      .unwrap()
      .then((res) => {
        window.open(res.link, '_blank');
      })
      .catch((err) => console.log(err));
  };

  const {data, isSuccess} = studentSlice.endpoints.getLecture.useQueryState(
    !isDesktop ? {programId, lectureId} : skipToken
  );

  const parser = new DOMParser();
  name = parser.parseFromString(name, 'text/html').documentElement.textContent;

  const openFullScreen = () => {
    ref.current.webkitRequestFullscreen();
  };

  return (
    <div>
      {educationType === EDUCATION_MATERIAL_TYPE.adLink ? (
        <Stack spacing={1} mb={2} sx={{cursor: 'pointer'}} className="link">
          <Typography variant="body1" color="error" onClick={openAdLink}>
            {name}
          </Typography>
          <Divider color="#89AAFF" sx={{height: '2px'}} />
        </Stack>
      ) : (
        <Stack spacing={1} mb={2} sx={{cursor: 'pointer'}} color={isActive ? '#0249FE' : 'initial'} className="link">
          <Typography variant="body1" onClick={openLecture}>
            {name}
          </Typography>
          <Divider color="#89AAFF" sx={{height: '2px'}} />
        </Stack>
      )}
      {!isDesktop && (
        <CSSTransition in={isActive && isSuccess} classNames="training" timeout={300} unmountOnExit>
          <Box>
            <Stack alignItems="end" mb={2.5}>
              <Button endIcon={<OpenInFullIcon />} variant="text" color="primary" onClick={openFullScreen}>
                Развернуть на весь экран
              </Button>
            </Stack>
            <iframe title="lecture" src={data?.link} style={{width: '100%', height: '100vh'}} ref={ref} />
          </Box>
        </CSSTransition>
      )}
    </div>
  );
};

export default Lecture;
