const BmpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M7.68032 0H17.7207L26.2204 8.86323V26.0992C26.2204 28.2516 24.472 30 22.3139 30H7.68036C5.528 30 3.7796 28.2516 3.7796 26.0992V3.90076C3.77956 1.7484 5.52797 0 7.68032 0V0Z"
        fill="#B0B0B0"
      />
      <path
        opacity="0.302"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7092 0V8.79402H26.2204L17.7092 0Z"
        fill="white"
      />
      <path
        d="M18.9555 15.6086H11.0444C10.6809 15.6086 10.3808 15.3143 10.3808 14.9508C10.3808 14.5873 10.6809 14.293 11.0444 14.293H18.9555C19.3191 14.293 19.6133 14.5873 19.6133 14.9508C19.6133 15.3143 19.3191 15.6086 18.9555 15.6086ZM16.3185 23.5197H11.0444C10.6809 23.5197 10.3808 23.2254 10.3808 22.8619C10.3808 22.4984 10.6809 22.2041 11.0444 22.2041H16.3185C16.682 22.2041 16.9763 22.4984 16.9763 22.8619C16.9763 23.2254 16.682 23.5197 16.3185 23.5197ZM18.9555 20.8827H11.0444C10.6809 20.8827 10.3808 20.5884 10.3808 20.2249C10.3808 19.8614 10.6809 19.5671 11.0444 19.5671H18.9555C19.3191 19.5671 19.6133 19.8614 19.6133 20.2249C19.6133 20.5884 19.3191 20.8827 18.9555 20.8827ZM18.9555 18.2456H11.0444C10.6809 18.2456 10.3808 17.9513 10.3808 17.5878C10.3808 17.2243 10.6809 16.93 11.0444 16.93H18.9555C19.3191 16.93 19.6133 17.2243 19.6133 17.5878C19.6133 17.9513 19.3191 18.2456 18.9555 18.2456Z"
        fill="white"
      />
    </svg>
  );
};

export default BmpIcon;
