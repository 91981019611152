import {useEffect} from 'react';
import {useNavigate} from 'react-router';
import {useForm, useWatch} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {object, string, date, bool} from 'yup';
import {toast} from 'react-toastify';
import dayjs from 'dayjs';
import {Box, Button, Stack, Tooltip, Typography} from '@mui/material';
import removeEmptyFields from '../../../utils/removeEmptyFields';
import {useGetDativeFullNameQuery, useSendPersonalInfoMutation} from '../../../api/slices/student';
import {useGetUserInfoQuery} from '../../../api/slices/auth';
import {NATIONALITY_TYPE} from '../../../constants';

import ControlledSelect from '../../../components/FieldRegister/Select';
import ControlledDatePicker from '../../../components/FieldRegister/DatePicker';
import ControlledMaskedTextField from '../../../components/FieldRegister/MaskedTextField';
import ControlledTextField from '../../../components/FieldRegister/NestedTextField';
import {checkInsuranceNumber} from '../../../utils/checkInsuranceNumber';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import PersonalInfoItem from './Item/PersonalInfoItem';
import PageTitle from '../../../components/Title/PageTitle';

import './styles.scss';
import ControlledCheckbox from '../../../components/FieldRegister/Checkbox';
import extractPhone from '../../../utils/extractPhone';

const PersonalInfo = ({requiredFields}) => {
  const {data: user} = useGetUserInfoQuery();
  const [sendPersonalInfo, {error, isLoading}] = useSendPersonalInfoMutation();
  const name = `${user?.surname} ${user?.firstname} ${user?.secondname}`;
  const {data} = useGetDativeFullNameQuery(name);
  const navigate = useNavigate();

  const birthdayRequired = requiredFields?.birthdayRequired;
  const snilsRequired = requiredFields?.snilsRequired;
  const innRequired = requiredFields?.innRequired;
  const datNameRequired = requiredFields?.datNameRequired;
  const nationalityRequired = requiredFields?.nationalityRequired;
  const emailRequired = requiredFields?.emailRequired;
  const phoneRequired = requiredFields?.phoneRequired;
  const okvedRequired = requiredFields?.okvedRequired;

  const persDataConfirmRequired = requiredFields?.persDataConfirmRequired;
  const employeeInn = requiredFields?.canEmployerInnCustomerInn;

  const schema = object().shape({
    nationalityId: nationalityRequired
      ? string().required('Значение не может быть пустым').default(NATIONALITY_TYPE[0].id)
      : string().nullable(),
    dateOfBirth: birthdayRequired
      ? date()
          .required('Значение не может быть пустым')
          .typeError('Пожалуйста укажите дату')
          .test('dateOfBirth', 'Возраст не может быть меньше 18 и больше 100 лет', (val) => {
            const date = new Date().getFullYear();
            return date - val?.getFullYear() >= 18 && date - val?.getFullYear() <= 100;
          })
      : string().nullable(),
    SNILS: string().when('nationalityId', {
      is: (val) => val === NATIONALITY_TYPE[0].id && snilsRequired,
      then: string()
        .required('Значение не может быть пустым')
        .transform((curr) => curr.replaceAll(/[ _-]/g, ''))
        .length(11, 'СНИЛС не может быть меньше 11 символов')
        .default('')
        .test('SNILS', 'Введите корректный СНИЛС', checkInsuranceNumber),
      otherwise: string().nullable(),
    }),
    email: emailRequired ? string().required().email() : string().nullable(),
    phone: phoneRequired
      ? string()
          .min(11, 'Только абоненты РФ. Введите 10 символов без 8(+7)')
          .transform((value) => extractPhone(value))
          .required('Значение не может быть пустым')
          .default('')
      : string().nullable(),
    okved: okvedRequired ? string().required('Значение не может быть пустым').default('') : string().nullable(),
    INN: innRequired
      ? string()
          .required()
          .min(10, 'ИНН должен быть минимум 10 символов')
          .max(12, 'ИНН не должен превышать 12 символов')
          .matches(/^-?\d+\.?\d*$/, 'ИНН может содержать только числа')
          .trim()
      : string().nullable(),
    dativeFullName: datNameRequired
      ? string()
          .required('Значение не может быть пустым')
          .test('dativeFullName', 'Введенные данные значительно отличаются от данных пользователя', (val) =>
            val?.length > 3 ? val.slice(0, 2).toLowerCase() === user?.surname.slice(0, 2).toLowerCase() : true
          )
          .transform((curr) => curr.replaceAll(/null/gi, ''))
      : string().nullable(),
    isPersDataConfirmed: persDataConfirmRequired
      ? bool()
          .required('Подтвердите Ваше согласие на обработку персональных данных')
          .oneOf([true], 'Подтвердите Ваше согласие на обработку персональных данных')
      : string().nullable(),
  });

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    setFocus,
    reset,
    formState: {errors, isValid},
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {nationalityId: NATIONALITY_TYPE[0].id, INN: employeeInn || ''},
  });

  useEffect(() => {
    if (user?.birthday && birthdayRequired) {
      setValue('dateOfBirth', dayjs(user?.birthday).format('YYYY-DD-MM'));
    }
    if (data?.datName && datNameRequired) {
      setValue('dativeFullName', data?.datName.replaceAll(/null/gi, ''));
    }
  }, [data, setValue, user]);

  const nationality = useWatch({control, name: 'nationalityId'});

  const onSubmitHandler = (data) => {
    data.dateOfBirth = data.dateOfBirth ? dayjs(data.dateOfBirth).format('DD.MM.YYYY') : null;
    if (!nationalityRequired || nationalityRequired === undefined) {
      delete data['nationalityId'];
    }
    let formData = removeEmptyFields(data);
    sendPersonalInfo(formData)
      .unwrap()
      .then(() => {
        toast.success('Сведения успешно отправлены', {autoClose: 2000, hideProgressBar: true});
        setTimeout(() => {
          navigate(0);
        }, 2000);
      })
      .catch((err) => {
        for (const key in err.data.errors) {
          if (err.data.errors.hasOwnProperty(key)) {
            setError(key, {message: err.data.errors[key][0]});
            setFocus(key);
          }
        }
      });
  };

  const displaySNILS = nationality === NATIONALITY_TYPE[0].id && snilsRequired;

  return (
    <Box>
      <PageTitle>Сведения для обучения</PageTitle>

      <Stack direction="row" alignItems="center" mb={{sm: 3, s: 2}}>
        <Typography variant="body2" textAlign="center" sx={{verticalAlign: 'center'}}>
          Для начала обучения просим вас заполнить поля ниже. Указанные сведения мы обязаны передать в реестр документов
          об образовании (ФИС ФРДО) / реестр обученных лиц ФГИС СОУТ
          <Tooltip
            title='Федеральная информационная система "Федеральный реестр сведений о документах об образовании и (или) о
          квалификации, документах об обучении (ФИС ФРДО)"'>
            <HelpOutlineOutlinedIcon sx={{ml: 1, verticalAlign: 'bottom'}} />
          </Tooltip>
        </Typography>
      </Stack>
      <Box component={'form'} onSubmit={handleSubmit(onSubmitHandler)} onReset={reset} className="personal_info">
        <Box>
          <Typography variant="h6" mb={{md: 3, sm: 2, s: 1}}>
            Данные слушателя
          </Typography>
          <Stack spacing={{s: 2}}>
            <Stack direction="column" alignItems="flex-start" spacing={{s: 2}}>
              {nationalityRequired !== undefined && (
                <PersonalInfoItem title="Гражданство" itemProps={{width: {sm: '70%', s: '100%'}}}>
                  <ControlledSelect
                    control={control}
                    errors={errors}
                    name="nationalityId"
                    options={NATIONALITY_TYPE}
                    size="small"
                    onSelect={(e) => {
                      setValue('nationalityId', e.target.value);
                      setValue('SNILS', '');
                    }}
                    controlStyles={{width: '100%'}}
                    sx={{width: '100%'}}
                  />
                </PersonalInfoItem>
              )}
              {birthdayRequired && (
                <PersonalInfoItem title="Дата рождения" itemProps={{width: {sm: '70%', s: '100%'}}}>
                  <ControlledDatePicker
                    control={control}
                    errors={errors}
                    name="dateOfBirth"
                    fullWidth
                    sx={{width: '100%'}}
                  />
                </PersonalInfoItem>
              )}
            </Stack>
            <Stack direction="column" alignItems="flex-start" spacing={{s: 2}}>
              {displaySNILS && (
                <PersonalInfoItem title="СНИЛС" itemProps={{width: {sm: '70%', s: '100%'}}}>
                  <ControlledMaskedTextField
                    control={control}
                    errors={errors}
                    name="SNILS"
                    format="###-###-### ##"
                    mask="_"
                    placeholder="111-222-333-44"
                    fullWidth
                  />
                </PersonalInfoItem>
              )}
              {innRequired && (
                <>
                  {employeeInn ? (
                    <PersonalInfoItem title="Проверьте ИНН работодателя" itemProps={{width: {sm: '70%', s: '100%'}}}>
                      <ControlledTextField
                        control={control}
                        errors={errors}
                        name="INN"
                        placeholder="760724300757"
                        fullWidth
                      />
                    </PersonalInfoItem>
                  ) : (
                    <PersonalInfoItem title="ИНН работодателя" itemProps={{width: {sm: '70%', s: '100%'}}}>
                      <ControlledTextField
                        control={control}
                        errors={errors}
                        name="INN"
                        placeholder="760724300757"
                        fullWidth
                      />
                    </PersonalInfoItem>
                  )}
                </>
              )}
            </Stack>
            {datNameRequired && (
              <PersonalInfoItem title="ФИО в дательном падеже" itemProps={{width: {sm: '70%', s: '100%'}}}>
                <ControlledTextField
                  control={control}
                  errors={errors}
                  name="dativeFullName"
                  placeholder="Алексееву Алексею Алексеевичу"
                  size="small"
                  fullWidth
                />
              </PersonalInfoItem>
            )}

            {emailRequired && (
              <PersonalInfoItem title="Email" itemProps={{width: {sm: '70%', s: '100%'}}}>
                <ControlledTextField control={control} errors={errors} name="email" size="small" fullWidth />
              </PersonalInfoItem>
            )}

            {phoneRequired && (
              <PersonalInfoItem title="Телефон" itemProps={{width: {sm: '70%', s: '100%'}}}>
                <ControlledMaskedTextField
                  control={control}
                  errors={errors}
                  name="phone"
                  label="Контактный телефон"
                  format="+7-(###)-###-####"
                  fullWidth
                  mask="_"
                  sx={{width: {sm: '49%'}}}
                />
              </PersonalInfoItem>
            )}

            {okvedRequired && (
              <PersonalInfoItem title="ОКВЭД" itemProps={{width: {sm: '70%', s: '100%'}}}>
                <ControlledTextField control={control} errors={errors} name="okved" size="small" fullWidth />
              </PersonalInfoItem>
            )}

            {persDataConfirmRequired && (
              <ControlledCheckbox
                control={control}
                name="isPersDataConfirmed"
                label="Вы даёте своё согласие на обработку введённой персональной информации в соответствии с Федеральным
                  Законом № 152-ФЗ от 27.07.2006 «О персональных данных»"
                defaultValue={false}
              />
            )}
          </Stack>
        </Box>

        <Typography paragraph color="error">
          {errors.isPersDataConfirmed?.message}
        </Typography>
        <Typography paragraph color="error" textAlign="center">
          {error?.data?.error}
        </Typography>
        {error?.data?.message ? (
          <Typography paragraph color="error" textAlign="center">
            Произошла ошибка, проверьте правильность введенных данных или повторите попытку позже
          </Typography>
        ) : null}
        <Button variant="contained" type="submit" disabled={!isValid || isLoading}>
          Отправить данные
        </Button>
      </Box>
    </Box>
  );
};

export default PersonalInfo;
