import {Box} from '@mui/material';
import {toast} from 'react-toastify';
import {useParams} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {schema} from './schema';

import StudentFormFields from './studentFormFiels';
import {useAddNewStudentMutation} from '../../../../../api/slices/user';
import {defaultStudent} from '../../../../../constants';

const StudentForm = ({hideAddingForm}) => {
  const {id} = useParams();
  const [addNewStudent] = useAddNewStudentMutation();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...defaultStudent,
    },
  });

  const onSubmit = (data) => {
    addNewStudent({contractId: id, data})
      .unwrap()
      .then(() => {
        methods.reset();
        hideAddingForm();
        toast.success('Запрос на добавление нового слушателя успешно отправлен!', {
          autoClose: 1000,
          hideProgressBar: true,
        });
      })
      .catch((err) => console.log(err));
  };
  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <StudentFormFields hideAddingForm={hideAddingForm} />
      </Box>
    </FormProvider>
  );
};

export default StudentForm;
