import {useNavigate, useParams} from 'react-router';
import {Box} from '@mui/material';

import {useGetContractInfoQuery, useGetStudentListQuery} from '../../../api/slices/user';

import Loader from '../../../components/Loader';
import PageTitle from '../../../components/Title/PageTitle';
import CustomersInformation from './modules/customersInformation';
import Customers from './Customers';
import StudentForm from './modules/studentForm';
import useToggle from '../../../hooks/useToggle';

const ContractListeners = () => {
  const {id} = useParams();
  const {data} = useGetStudentListQuery({id});
  const navigate = useNavigate();
  const [showAddingForm, toggleAddingForm] = useToggle(false);

  const {data: contractInfo, isLoading: isContractInfoLoading} = useGetContractInfoQuery(id);

  const activeListeners = data?.filter((lis) => !lis.addQueryActive);
  const disabledListeners = data?.filter((lis) => lis.addQueryActive);

  const navigateToMain = () => {
    navigate('/');
  };

  return (
    <Box>
      <PageTitle onBackButtonClick={navigateToMain}>Слушатели по договору {contractInfo?.data?.number}</PageTitle>
      <CustomersInformation
        activeListeners={activeListeners?.length}
        disabledListeners={disabledListeners?.length}
        isAvailableAddListeners={contractInfo?.data?.isPayed || contractInfo?.data?.inArchiveDate}
        isLoading={isContractInfoLoading}
        toggleAddingForm={toggleAddingForm}
      />
      {showAddingForm ? <StudentForm hideAddingForm={toggleAddingForm} /> : null}
      {isContractInfoLoading ? (
        <Loader />
      ) : (
        <Customers
          isPayed={contractInfo?.data?.isPayed || false}
          contracts={activeListeners}
          contractName={contractInfo?.data?.number}
        />
      )}
    </Box>
  );
};

export default ContractListeners;
