import {Typography} from '@mui/material';
import {useEffect, useState} from 'react';

const Timer = ({initialValue, direction = true, ...textProps}) => {
  const [time, setTime] = useState(initialValue);

  useEffect(() => {
    const timer = setInterval(
      () =>
        setTime((time) => {
          if (direction) return time + 1;
          else if (time - 1 <= 0) return 0;
          else return time - 1;
        }),
      1000
    );
    return () => {
      clearInterval(timer);
    };
  }, [direction]);

  const formatTime = () => {
    const seconds = formatValue(time % 60) || 0;
    const minutes = formatValue((time % 3600) / 60) || 0;
    const hours = formatValue(time / 3600);

    return `${hours}:${minutes}:${seconds}` || 0;

    function formatValue(value) {
      value = parseInt(value);
      if (value < 10) {
        value = '0' + value;
      }
      return value;
    }
  };
  const formattedTime = formatTime();
  return (
    <Typography variant="body1" sx={{fontWeight: 'bold'}}>
      {formattedTime}
    </Typography>
  );
};

export default Timer;
