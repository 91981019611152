import {Box, Stack} from '@mui/material';
import {useFormContext} from 'react-hook-form';

import InformationBlock from './informationBlock';
import ControlledAutocomplete from '../../../../../components/FieldRegister/nestedAutocomplete';
import ControlledTextField from '../../../../../components/FieldRegister/NestedTextField';
import ControlledMaskedTextField from '../../../../../components/FieldRegister/MaskedTextField';
import {AutocompleteBIK} from '../../../../../components/FieldRegister/AutocompleteBIK';

const IndividualEntrepreneurForm = ({autoFillCompanyFields, autoFillBankFields, autoFillBikFields}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  return (
    <Box>
      <InformationBlock title="Введите данные ИП">
        <Stack
          spacing={{s: 1}}
          direction={{sm: 'row'}}
          flexWrap={{sm: 'wrap'}}
          justifyContent="space-between"
          useFlexGap={true}>
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="organizationName"
            label="Название ИП*"
            type="party"
            fullWidth
            autoFill={autoFillCompanyFields}
          />
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="legalAddress"
            label="Адрес*"
            type="address"
            fullWidth
          />
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="postAddress"
            label="Почтовый адрес*"
            type="address"
            fullWidth
          />
          <ControlledMaskedTextField
            format="##########"
            control={control}
            errors={errors}
            name="inn"
            label="ИНН*"
            sx={{width: {sm: '32%'}}}
          />
          <ControlledTextField control={control} errors={errors} name="ogrn" label="ОГРН" sx={{width: {sm: '32%'}}} />
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="bank"
            label="Банк"
            type="bank"
            autoFill={autoFillBankFields}
            sx={{width: {sm: '32%'}}}
          />
          <AutocompleteBIK
            control={control}
            errors={errors}
            name="bik"
            label="БИК банка"
            type="bank"
            autoFill={autoFillBikFields}
            sx={{width: {sm: '32%'}}}
          />
          <ControlledMaskedTextField
            control={control}
            errors={errors}
            name="rAccount"
            label="Расч. счет"
            format="####################"
            sx={{width: {sm: '32%'}}}
          />
          <ControlledMaskedTextField
            control={control}
            errors={errors}
            name="kAccount"
            label="Корр. счет"
            format="####################"
            sx={{width: {sm: '32%'}}}
          />
          <ControlledMaskedTextField
            control={control}
            errors={errors}
            name="phone"
            label="Телефон ИП"
            format="+7-(###)-###-####"
            mask="_"
            sx={{width: {sm: '49%'}}}
          />
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="email"
            label="Email ИП"
            type="email"
            sx={{width: {sm: '49%'}}}
          />
        </Stack>
      </InformationBlock>
      <InformationBlock title="Введите данные руководителя">
        <Stack
          spacing={{s: 1}}
          direction={{sm: 'row'}}
          flexWrap={{sm: 'wrap'}}
          justifyContent="space-between"
          useFlexGap={true}>
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="directorSurname"
            label="Фамилия*"
            type="fio"
            sx={{width: {sm: '49%'}}}
          />
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="directorName"
            label="Имя*"
            type="fio"
            sx={{width: {sm: '49%'}}}
          />
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="directorSecondName"
            label="Отчество"
            type="fio"
            sx={{width: {sm: '49%'}}}
          />
        </Stack>
      </InformationBlock>
    </Box>
  );
};

export default IndividualEntrepreneurForm;
