import {skipToken} from '@reduxjs/toolkit/query';
import {Controller, useWatch, get} from 'react-hook-form';
import {IconButton, Stack, Typography} from '@mui/material';
import Select from 'react-select';
import DeleteIcon from '@mui/icons-material/Delete';
import {useGetProgramsByTypeQuery, useGetProgramTypesQuery} from '../../../../api/slices/public';

const EditablePrograms = ({control, resetField, errors, index, removeProgram, studentTraining}) => {
  const trainingProgramTypeName = `studentTrainings[${index}].trainingProgramType`;
  const trainingProgramName = `studentTrainings[${index}].trainingProgram`;

  const selectedTypeProgram = useWatch({control, name: trainingProgramTypeName});
  const programsPerStudent = useWatch({control, name: `studentTrainings`});

  const {data: programTypes} = useGetProgramTypesQuery();
  const {data: programs, isLoading} = useGetProgramsByTypeQuery(
    selectedTypeProgram ? {id: selectedTypeProgram?.id} : skipToken
  );
  const programTypesPerStudentList = programsPerStudent.map((program) => program?.trainingProgramType?.id);
  const programsPerStudentList = programsPerStudent.map((elem) => elem?.trainingProgram?.id);

  const programsTypesOption = programTypes?.data.filter((programType) => programType.canUse);
  const programOptions = programs?.data.filter((program) => program.canUse);

  const trainingProgramTypeError = get(errors, trainingProgramTypeName);
  const trainingProgramError = get(errors, trainingProgramName);

  return (
    <Stack spacing={1} maxWidth={380}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography>Программа обучения №{index + 1}</Typography>
        {programsPerStudent?.length !== 1 && (
          <IconButton aria-label="delete" size="large" onClick={removeProgram} color="error">
            <DeleteIcon />
          </IconButton>
        )}
      </Stack>
      <Controller
        control={control}
        name={trainingProgramTypeName}
        render={({field: {onChange, value, onBlur, name, ref}}) => (
          <>
            <Select
              placeholder="Выберите тип программы обучения"
              name={name}
              value={value}
              options={programsTypesOption}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              onChange={(props) => {
                onChange(props);
                resetField(trainingProgramName, {defaultValue: ''});
              }}
              onBlur={onBlur}
              isDisabled={value?.isDisabled}
              isOptionDisabled={(option) => !option.canMultiSelect && programTypesPerStudentList.includes(option.id)}
              noOptionsMessage={() => 'нет доступных программ'}
              components={{
                IndicatorSeparator: () => null,
              }}
              ref={ref}
            />
            <Typography color="error" align="center" gutterBottom>
              {trainingProgramTypeError?.message}
            </Typography>
          </>
        )}
      />
      <Controller
        control={control}
        name={trainingProgramName}
        render={({field: {onChange, value, onBlur, name, ref}}) => (
          <>
            <Select
              placeholder="Выберите программу обучения"
              name={name}
              value={value}
              options={programOptions}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.text}
              onChange={onChange}
              onBlur={onBlur}
              isOptionDisabled={(option) => programsPerStudentList.includes(option.id)}
              noOptionsMessage={() => 'нет доступных программ'}
              loadingMessage={() => 'Загрузка...'}
              isLoading={isLoading}
              components={{
                IndicatorSeparator: () => null,
              }}
              ref={ref}
            />
            <Typography color="error" align="center" gutterBottom>
              {trainingProgramError?.message}
            </Typography>
          </>
        )}
      />
    </Stack>
  );
};

export default EditablePrograms;
