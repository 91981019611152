import {useEffect} from 'react';
import {useForm, useFieldArray} from 'react-hook-form';
import {object, array, string, date, setLocale} from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {Button, Stack, Typography} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {toast} from 'react-toastify';
import dayjs from 'dayjs';

import {useUpdateStudentInfoMutation} from '../../../../api/slices/user';

import EditablePrograms from './editablePrograms';
import ControlledTextField from '../../../../components/FieldRegister/NestedTextField';
import ControlledDatePicker from '../../../../components/FieldRegister/DatePicker';
import ControlledMaskedTextField from '../../../../components/FieldRegister/MaskedTextField';
import {checkInsuranceNumber} from '../../../../utils/checkInsuranceNumber';
import {getChangedFields} from '../../../../utils/getChangedFields';

import classes from '../styles.module.scss';
import tipText from '../../../../utils/tipText';

const CustomerEditForm = ({initialValues, onClose, back}) => {
  const [updateStudentInfo, {isLoading}] = useUpdateStudentInfoMutation();

  const symbolTytles = ['символ', 'символа', 'символов'];
  setLocale({
    string: {
      min: ({min}) => `минимум ${tipText(min, symbolTytles)}`,
    },
  });

  const schema = object({
    surname: string().required('Значение не может быть пустым'),
    firstname: string().required('Значение не может быть пустым'),
    secondname: string().nullable().optional(),
    birthday: date()
      .nullable()
      .typeError('Пожалуйста укажите дату')
      .test('birthday', 'Возраст не может быть меньше 18 и больше 100 лет', (val) => {
        if (!val) return true;
        const date = new Date().getFullYear();
        return date - val?.getFullYear() >= 18 && date - val?.getFullYear() <= 100;
      }),
    SNILS: string()
      .transform((curr) => curr?.replaceAll(/[ _-]/g, ''))
      // .length(11, 'СНИЛС не может быть меньше 11 символов')
      .default('')
      .test('SNILS', 'Контрольное число не совпадает', checkInsuranceNumber),
    jobPlace: string().min(3).required('Значение не может быть пустым'),
    profession: string().min(3).required('Значение не может быть пустым'),
    studentTrainings: array().of(
      object({
        trainingProgramType: string()
          .required('Выберите тип программы обучения')
          .transform((val) => val.id)
          .default(''),
        trainingProgram: string()
          .required('Выберите программу обучения')
          .transform((val) => val.id)
          .default(''),
      })
    ),
  });

  const studentTrainings = initialValues.studentTrainings.map((studentTraining) => ({
    studentTrainingId: studentTraining.id,
    trainingProgramType: {
      id: studentTraining.trainingProgramTypeId,
      name: studentTraining.trainingProgramTypeName,
      isDisabled: true,
    },
    trainingProgram: {
      id: studentTraining.trainingProgramId,
      text: studentTraining.trainingProgramText,
      name: studentTraining.trainingProgramName,
    },
  }));

  const defaultProgram = {
    trainingProgramType: '',
    trainingProgram: '',
  };

  const {
    handleSubmit,
    control,
    setValue,
    resetField,
    getValues,
    formState: {errors, dirtyFields},
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {fields, append, remove} = useFieldArray({control, name: 'studentTrainings'});

  useEffect(() => {
    setValue('surname', initialValues.surname);
    setValue('firstname', initialValues.firstname);
    setValue('secondname', initialValues.secondname);
    setValue('email', initialValues.email);
    setValue('birthday', initialValues.birthday);
    setValue('SNILS', initialValues.SNILS);
    setValue('jobPlace', initialValues.jobPlace);
    setValue('profession', initialValues.profession);

    setValue('studentTrainings', studentTrainings);
  }, [setValue, initialValues]);

  const onSubmitHandler = (data) => {
    data.birthday = data.birthday ? dayjs(data.birthday).format('DD.MM.YYYY') : null;

    const oldProgramsIdList = data.studentTrainings.map((studentTraining) => studentTraining?.studentTrainingId);
    const oldTrainingProgramsList = studentTrainings.map((studentTraining) => studentTraining?.trainingProgram.id);

    const addTrainingPrograms = data.studentTrainings
      .filter((studentTraining) => !studentTraining.studentTrainingId)
      .map((program) => ({trainingProgramId: program.trainingProgram}));

    const deleteStudentTrainings = studentTrainings.reduce((acc, curr) => {
      return oldProgramsIdList.includes(curr.studentTrainingId)
        ? acc
        : [...acc, {studentTrainingId: curr.studentTrainingId}];
    }, []);

    const changeStudentTrainings = data.studentTrainings
      .filter(
        (studentTraining) =>
          studentTraining.studentTrainingId && !oldTrainingProgramsList.includes(studentTraining.trainingProgram)
      )
      .map((program) => ({
        studentTrainingId: program.studentTrainingId,
        newTrainingProgramId: program.trainingProgram,
      }));

    delete data.studentTrainings;

    const newData = getChangedFields(dirtyFields, initialValues, data);
    if (addTrainingPrograms.length) {
      newData.addTrainingPrograms = addTrainingPrograms;
    }
    if (deleteStudentTrainings.length) {
      newData.deleteStudentTrainings = deleteStudentTrainings;
    }
    if (changeStudentTrainings.length) {
      newData.changeStudentTrainings = changeStudentTrainings;
    }

    if (Object.keys(newData).length) {
      updateStudentInfo({id: initialValues.id, data: newData})
        .unwrap()
        .then(() => {
          onClose();
          toast.success(`Запрос на изменение данных от ${dayjs(new Date()).format('DD.MM.YYYY')} ожидает обработки`);
        })
        .catch(() => {
          toast.error(`Возникла ошибка, попробуйте внести изменения позже`);
        });
    }
  };

  console.log(getValues('birthday'));

  return (
    <form className={classes.drawer} onSubmit={handleSubmit(onSubmitHandler)}>
      <div className={classes.content}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" m={2}>
          <Typography variant="body1" color="grey" flex="0 1 50%">
            Фамилия
          </Typography>
          <ControlledTextField control={control} errors={errors} name="surname" placeholder="Алексеев" fullWidth />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" m={2}>
          <Typography variant="body1" color="grey" flex="0 1 50%">
            Имя
          </Typography>
          <ControlledTextField control={control} errors={errors} name="firstname" placeholder="Алексей" fullWidth />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" m={2}>
          <Typography variant="body1" color="grey" flex="0 1 50%">
            Отчество
          </Typography>
          <ControlledTextField control={control} errors={errors} name="secondname" placeholder="Алексеевич" fullWidth />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" m={2}>
          <Typography variant="body1" color="grey" flex="0 1 50%">
            Электронная почта
          </Typography>
          <ControlledTextField control={control} errors={errors} name="email" placeholder="test@mail.ru" fullWidth />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" m={2}>
          <Typography variant="body1" color="grey" flex="0 1 50%">
            Дата рождения
          </Typography>
          <ControlledDatePicker control={control} errors={errors} name="birthday" sx={{width: '100%'}} />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" m={2}>
          <Typography variant="body1" color="grey" flex="0 1 50%">
            СНИЛС
          </Typography>
          <ControlledMaskedTextField
            control={control}
            errors={errors}
            name="SNILS"
            format="###-###-### ##"
            mask="_"
            placeholder="111 222 333 44"
            fullWidth
          />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" m={2}>
          <Typography variant="body1" color="grey" flex="0 1 50%">
            Место работы
          </Typography>
          <ControlledTextField control={control} errors={errors} name="jobPlace" placeholder="ТГК-2" fullWidth />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" m={2}>
          <Typography variant="body1" color="grey" flex="0 1 50%">
            Должность
          </Typography>
          <ControlledTextField control={control} errors={errors} name="profession" placeholder="Директор" fullWidth />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="top" m={2}>
          <Typography variant="body1" color="grey" flex="0 1 50%">
            Программы обучения
          </Typography>
          <Stack spacing={2} flex="0 1 100%">
            {fields.map((studentTraining, index) => (
              <EditablePrograms
                key={studentTraining.id}
                control={control}
                resetField={resetField}
                errors={errors}
                index={index}
                removeProgram={() => remove(index)}
                studentTraining={studentTraining}
              />
            ))}
            {fields.length < 5 && (
              <Button onClick={() => append(defaultProgram)} startIcon={<AddCircleOutlineIcon />}>
                Добавить программу обучения
              </Button>
            )}
          </Stack>
        </Stack>
      </div>
      <Stack direction="row" spacing={2}>
        <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
          Сохранить изменения
        </Button>
        <Button variant="contained" color="secondary" onClick={back} disabled={isLoading}>
          Отмена
        </Button>
      </Stack>
    </form>
  );
};

export default CustomerEditForm;
