import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
// import * as serviceWorker from './serviceWorker';
import {ToastContainer} from 'react-toastify';
import {ThemeProvider} from '@mui/material';
import {theme} from './theme';

import App from './app/App';
import store from './app/store';

import './index.scss';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
    <ToastContainer position="top-center" autoClose={3000} closeOnClick hideProgressBar={true} />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
