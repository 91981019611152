export const CUSTOMER_TYPES = [
  {
    id: "9e55dbc1-6195-42c5-96ce-6a636df1970d",
    name: "Юридическое лицо",
  },
  {
    id: "7bb29770-df89-44bb-a8cb-2e60b2d3a378",
    name: "Физическое лицо",
  },
  {
    id: "ecf30c09-82f4-4e90-99d0-52002e737942",
    name: "ИП",
  },
];

export const CustomerType = {
  individual: "7bb29770-df89-44bb-a8cb-2e60b2d3a378",
  entity: "9e55dbc1-6195-42c5-96ce-6a636df1970d",
  individualEntrepreneur: "ecf30c09-82f4-4e90-99d0-52002e737942",
};
