export const BUDGET_TYPE = [
  { id: "2e903c6e-89a7-45c8-be64-b79bb86cd57a", label: "Региональный" },
  { id: "4ad428e0-8751-49ce-a413-b24fc33d2551", label: "Федеральный" },
  { id: "75d6fd45-7398-4b6f-a04d-b0dfed1b34ac", label: "Местный" },
];

export const BudgetType = {
  regional: "2e903c6e-89a7-45c8-be64-b79bb86cd57a",
  federal: "4ad428e0-8751-49ce-a413-b24fc33d2551",
  local: "75d6fd45-7398-4b6f-a04d-b0dfed1b34ac",
};
