import {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useNavigate} from 'react-router';
import {toast} from 'react-toastify';
import {LoadingButton} from '@mui/lab';
import {Box, Stack, Typography} from '@mui/material';
import {useCreateNewContractMutation} from '../../../api/slices/user';
import {useCalcContractCostMutation, useGetCompanySettingsQuery} from '../../../api/slices/public';

import DeliveryInfo from './deliveryInfo';
import NewStudent from './newStudent';
import CustomerInfo from './customerInfo';

import PageTitle from '../../../components/Title/PageTitle';

import removeEmptyFields from '../../../utils/removeEmptyFields';
import {CustomerType, defaultStudent, DeliveryType} from '../../../constants';
import {schema} from './schema';
import {SmartCaptcha} from '@yandex/smart-captcha';

const CreateNewContract = () => {
  const [customerType, setCustomerType] = useState(CustomerType.individual);
  const [createNewContract, {isError, isLoading}] = useCreateNewContractMutation();
  const {data: companySettings} = useGetCompanySettingsQuery();
  const [resetCaptcha, setResetCaptcha] = useState(0);

  const [calcContractCost, {data: cost}] = useCalcContractCostMutation({
    fixedCacheKey: 'contract-price',
  });
  const navigate = useNavigate();
  const [captchaToken, setCaptchaToken] = useState(null);

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      students: [{...defaultStudent}],
      deliveryTypeId: DeliveryType.postOfRussia,
      isWriteOffBonuses: false,
    },
  });
  const {
    setError,
    setFocus,
    formState: {errors},
  } = methods;
  const handleReset = () => setResetCaptcha((prev) => prev + 1);

  console.log(errors);

  const onSubmit = (formData) => {
    let data = removeEmptyFields(formData);

    const customer = {
      [CustomerType.individual]: 'individual',
      [CustomerType.entity]: 'entity',
      [CustomerType.individualEntrepreneur]: 'entrepreneur',
    };
    if (companySettings?.isCaptchaEnabled) {
      data['captchaSmartToken'] = captchaToken;
    }
    createNewContract({data})
      .unwrap()
      .then(() => {
        methods.reset();
        toast.success('новая заявка на обучение успешно создана');
        if (companySettings) {
          window.ym(companySettings.htmlCounterYaMetrika, 'hit', `/create-contract/succeed/${customer[customerType]}`);
          window.ym(companySettings.htmlCounterYaMetrika, 'reachGoal', 'zayavka-lk', {
            order_price: cost?.fullPrice,
            currency: 'RUB',
          });
        }
        navigate(`/create-contract/succeed/${customer[customerType]}`);
      })
      .catch((err) => {
        setCaptchaToken(null);
        handleReset();
        for (let key in err.data.errors) {
          setError(key, {message: err.data.errors[key][0]});
          setFocus(key);
        }
      });
  };

  const getContractCost = (routeId = null, isWriteOffBonuses = false) => {
    const deliveryTypeId = methods.getValues('deliveryTypeId');
    const deliveryRouteId = methods.getValues('deliveryRouteId');
    const relationContractId = methods.getValues('relationContractId');
    const students = methods.getValues('students');
    const promocode = methods.getValues('promocode');

    const programs = students.reduce((acc, student) => {
      const programs = student.programs.map((program) => program?.trainingProgramId?.value);
      return [...acc, ...programs];
    }, []);
    const elementCount = (arr, element) => arr.filter((currentElement) => currentElement === element).length;
    const removeDuplicates = (arr) => arr.filter((item, index) => arr.indexOf(item) === index);

    const trainingPrograms = removeDuplicates(programs).map((program) => {
      return {trainingProgramId: program, studentCount: elementCount(programs, program)};
    });

    const pricingParameters = {trainingPrograms, deliveryTypeId, isWriteOffBonuses};

    if (DeliveryType.expressDelivery === deliveryTypeId) {
      pricingParameters.deliveryRouteId = deliveryRouteId ? deliveryRouteId : routeId;
    }

    if (promocode) {
      pricingParameters.promocode = promocode;
    }
    if (relationContractId) {
      pricingParameters.relationContractId = relationContractId;
    }

    calcContractCost(pricingParameters);
  };

  const navigateToMain = () => {
    navigate('/');
  };

  const isCaptchaEnabled = companySettings?.isCaptchaEnabled === true;

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <PageTitle onBackButtonClick={navigateToMain}>Новая заявка на обучение</PageTitle>
        <NewStudent getContractCost={getContractCost} />
        <CustomerInfo setCustomer={setCustomerType} getContractCost={getContractCost} />
        <DeliveryInfo getContractCost={getContractCost} />
        {isError && (
          <Typography variant="body1" color="error" textAlign="center" mt={{md: 2, s: 1}} mb={{md: 2, s: 1}}>
            Ошибка создания заявки
          </Typography>
        )}
        <Stack my={2}>
          <Box sx={{alignSelf: 'center'}}>
            {isCaptchaEnabled && (
              <SmartCaptcha
                key={resetCaptcha}
                sitekey={companySettings?.captchaClientKey}
                onSuccess={setCaptchaToken}
              />
            )}
          </Box>
        </Stack>
        <LoadingButton
          type="submit"
          disabled={isCaptchaEnabled && captchaToken === null}
          variant="contained"
          size="large"
          loading={isLoading}
          fullWidth
          loadingIndicator="Загрузка...">
          Отправить
        </LoadingButton>
      </Box>
    </FormProvider>
  );
};

export default CreateNewContract;
