import {Box, Typography} from '@mui/material';

import '../styles.scss';

const RegistrationStep = ({step, title, subtitle, notification, warning, children}) => {
  return (
    <Box className="registration-step" mb={{lg: 5, md: 3.5, s: 2.5}}>
      <Typography variant="h2" mb={{sm: 1, s: 0.5}}>
        {step}
      </Typography>
      <Typography variant="h3" mb={{sm: 1, s: 0.5}}>
        {title}
      </Typography>
      <Typography variant="subtitle2" mb={{sm: 1, s: 0.5}}>
        {subtitle}
      </Typography>
      {warning}
      {children}
      <Typography variant="subtitle2">{notification}</Typography>
    </Box>
  );
};

export default RegistrationStep;
