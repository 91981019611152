import {Box, Button, Stack, Typography, useMediaQuery} from '@mui/material';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import {defaultStudent} from '../../../constants';

import EducationPrograms from '../../../features/EducationPrograms';
import ControlledTextField from '../../../components/FieldRegister/NestedTextField';
import ControlledAutocomplete from '../../../components/FieldRegister/nestedAutocomplete';

import RegistrationStep from './modules/RegistrationStep';
import ListenerCardInfoTitle from './modules/ListenerCardInfoTitle';

const NewStudent = ({getContractCost}) => {
  const {fields, append, remove} = useFieldArray({name: 'students'});
  const isDesktop = useMediaQuery('(min-width:768px)');
  const {
    control,
    formState: {errors, dirtyFields},
  } = useFormContext();
  const students = useWatch({control, name: 'students'});

  const removeStudent = (index) => () => {
    remove(index);
    getContractCost();
  };

  return (
    <RegistrationStep
      step="Шаг 1 из 3"
      title="Заполнение информации о слушателях и выбор программы"
      subtitle="Введите данные о слушателях и программы обучения для каждого из них"
      warning={
        <Stack direction="row" alignItems="center" mt={{md: 1, s: 0.5}}>
          <PriorityHighIcon color="warning" sx={{fontSize: {lg: '24px', s: '16px'}}} />
          <Typography variant="subtitle1">
            Будьте внимательны! Указанные данные используются для подготовки оригиналов документов!
          </Typography>
        </Stack>
      }>
      {fields.map((field, studentIndex) => (
        <Box key={field.id} className="student__card" mt={{lg: 4, sm: 2.5, s: 2}} mb={{lg: 4, sm: 2.5, s: 2}}>
          <Stack direction={{sm: 'row'}} spacing={2}>
            <Box width={{sm: '45%'}}>
              <ListenerCardInfoTitle
                studentNumber={studentIndex + 1}
                studentQuantity={fields.length}
                removeStudent={removeStudent(studentIndex)}
                addStudent={() => append(defaultStudent)}
              />

              <Stack spacing={1}>
                <ControlledAutocomplete
                  control={control}
                  errors={errors}
                  name={`students[${studentIndex}].surname`}
                  label="Фамилия*"
                  type="fio"
                  fullWidth
                  pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
                />
                <ControlledAutocomplete
                  control={control}
                  errors={errors}
                  name={`students[${studentIndex}].firstname`}
                  label="Имя*"
                  type="fio"
                  fullWidth
                  pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
                />
                <ControlledAutocomplete
                  control={control}
                  errors={errors}
                  name={`students[${studentIndex}].secondname`}
                  label="Отчество"
                  type="fio"
                  fullWidth
                  pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
                />
                <ControlledTextField
                  control={control}
                  errors={errors}
                  name={`students[${studentIndex}].jobPlace`}
                  label="Место работы*"
                  fullWidth
                />
                <ControlledTextField
                  control={control}
                  errors={errors}
                  name={`students[${studentIndex}].profession`}
                  label="Должность*"
                  fullWidth
                />
              </Stack>
              {isDesktop ? (
                <Typography variant="subtitle2" mt={{lg: 3.5, xs: 2.5, s: 2}}>
                  * — поля, обязательные для заполнения
                </Typography>
              ) : null}
            </Box>
            <EducationPrograms
              studentIndex={studentIndex}
              getContractCost={getContractCost}
              studentQuantity={fields.length}
              removeStudent={removeStudent(studentIndex)}
              previousStudentPrograms={students[studentIndex - 1]?.programs}
            />
          </Stack>
        </Box>
      ))}
      <Button variant="contained" size="large" fullWidth onClick={() => append(defaultStudent)}>
        Добавить слушателя
      </Button>
    </RegistrationStep>
  );
};

export default NewStudent;
