import {useParams} from 'react-router';
import {Link} from 'react-router-dom';

import {publicSlice} from '../../api/slices/public';
import {useGetTrainingQuery} from '../../api/slices/student';

import Loader from '../../components/Loader';
import {Button, Stack, Typography} from '@mui/material';

const ExamAttention = ({close}) => {
  const {programId, moduleId} = useParams();

  const {data} = publicSlice.endpoints.getCompanySettings.useQueryState();
  const {data: trainings, isLoading} = useGetTrainingQuery({programId});

  const percentageCorectness =
    (trainings?.trainingProgram?.examCorrectQuestionCount / trainings?.trainingProgram?.testQuestionCount) * 100;



  return isLoading ? (
    <Loader />
  ) : (
    <Stack spacing={1.5}>
      <Typography variant="h2">Внимательно прочтите правила сдачи экзамена</Typography>
      <Typography variant="body1">
        Экзамен состоит из <b>{trainings?.trainingProgram?.testQuestionCount} вопросов</b>
      </Typography>
      <Typography variant="body1">
        Для модульных курсов предусматривается
        <b> ступенчатая (поэтапная) сдача экзамена</b> после завершения каждого модуля (этапа)
      </Typography>
      <Typography variant="body1">
        Время для сдачи экзамена <b>не ограничено</b> (таймер используется для самоконтроля)!
      </Typography>
      <Typography variant="body1">
        Экзамен считается успешно сданным, если Вы ответили верно на
        <b> {percentageCorectness}% вопросов</b> и более
      </Typography>
      <Typography variant="body1">
        Для сдачи экзамена предоставляется <b>не более 3 попыток</b>.
      </Typography>
      <Typography variant="body1">
        <b>Результаты экзамена</b> хранятся в личном кабинете
      </Typography>
      <Typography variant="body1">
        Если Вам <b>не удалось сдать экзамен после 3 попыток</b>, сообщите о проблеме куратору курса или свяжитесь с
        оператором Call-центра по телефону <a href={`tel:${data.phone}`}>{data.phone}</a>
      </Typography>
      <Stack spacing={1.5}>
        <Button variant="contained" color="secondary" onClick={close}>
          Я еще не готов
        </Button>
        <Button
          disabled={trainings.examAttemptCount === 3 || isLoading}
          component={Link}
          to={`/exam/${programId}/${moduleId}`}
          variant="contained"
          color="primary">
          {trainings.examAttemptCount === 3 ? 'Попытки закончились' : 'Сдать экзамен'}
        </Button>
      </Stack>
    </Stack>
  );
};

export default ExamAttention;
