import {useGetProgramsByTypeQuery, useGetProgramTypesQuery} from '../../../../../api/slices/public';
import useToggle from '../../../../../hooks/useToggle';
import {Button, Stack, Typography, useMediaQuery} from '@mui/material';
import {Controller, get, useFormContext, useWatch} from 'react-hook-form';
import {skipToken} from '@reduxjs/toolkit/query';
import Select from 'react-select';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import {CSSTransition} from 'react-transition-group';

const StudentCardProgramsController = ({programIndex}) => {
  const {data: programTypes} = useGetProgramTypesQuery();
  const [isOpen, toggle] = useToggle();

  const isMobile = useMediaQuery('(min-width:480px)');

  const trainingProgramTypeName = `programs[${programIndex}].trainingProgramTypeId`;
  const trainingProgramName = `programs[${programIndex}].trainingProgramId`;

  const {
    control,
    resetField,
    formState: {errors},
  } = useFormContext();

  const selectedTypeProgram = useWatch({name: trainingProgramTypeName});
  const programsPerStudent = useWatch({name: `programs`});

  const programType = programTypes?.data.find((program) => program.id === selectedTypeProgram?.value);

  const {data, isLoading} = useGetProgramsByTypeQuery(
    selectedTypeProgram.value ? {id: selectedTypeProgram.value} : skipToken
  );

  const programTypeOptions = programTypes?.data.map((elem) => ({
    value: elem.id,
    label: elem.fullName,
    canMultiSelect: elem.canMultiSelect,
  }));

  const programOptions = data?.data.map((elem) => ({
    value: elem.id,
    label: `${elem.name} ${elem.text}`,
  }));

  const trainingProgramTypeError = get(errors, trainingProgramTypeName);
  const trainingProgramError = get(errors, trainingProgramName);

  return (
    <Stack spacing={0.5} mb={1.5}>
      <Controller
        control={control}
        name={trainingProgramTypeName}
        render={({field: {value, name, onChange, onBlur, ref}}) => (
          <>
            <Select
              name={name}
              value={value}
              placeholder="Выберите тип программы обучения"
              options={programTypeOptions}
              isOptionDisabled={(option) =>
                !option.canMultiSelect &&
                programsPerStudent.map((elem) => elem?.trainingProgramTypeId?.value).includes(option.value)
              }
              onChange={(props) => {
                onChange(props);
                if (selectedTypeProgram !== '') {
                  resetField(trainingProgramName, {defaultValue: ''});
                }
              }}
              onBlur={onBlur}
              components={{IndicatorSeparator: () => null}}
              classNamePrefix="select"
              ref={ref}
            />
            <Typography color="error" align="center" gutterBottom>
              {trainingProgramTypeError?.message}
            </Typography>
          </>
        )}
      />
      <Controller
        control={control}
        name={trainingProgramName}
        render={({field: {name, value, onChange, onBlur, ref}}) => (
          <Stack m={1}>
            <Select
              name={name}
              value={value}
              placeholder="Выберите программу обучения"
              options={programOptions}
              onChange={onChange}
              onBlur={onBlur}
              isOptionDisabled={(option) =>
                programsPerStudent.map((elem) => elem?.trainingProgramId?.value).includes(option.value)
              }
              isLoading={isLoading}
              noOptionsMessage={() => 'нет доступных программ'}
              loadingMessage={() => 'Загрузка...'}
              components={{IndicatorSeparator: () => null}}
              blurInputOnSelect
              classNamePrefix="select"
              ref={ref}
            />
            <Typography color="error" align="center" gutterBottom>
              {trainingProgramError?.message}
            </Typography>
          </Stack>
        )}
      />
      {programType?.needDiplom && (
        <Stack mt={{lg: 3.5, md: 2.5, s: 2}} mb={{lg: 3.5, md: 2.5, s: 2}} alignItems="start">
          <Button
            variant="outlined"
            color="error"
            size="small"
            onClick={toggle}
            fullWidth={!isMobile}
            style={{backgroundColor: '#fff'}}
            startIcon={<PriorityHighIcon />}>
            <Typography variant="caption" color="#1a1a1a">
              Посмотреть предупреждение
            </Typography>
          </Button>
          <CSSTransition in={isOpen} timeout={300} classNames="alert" unmountOnExit>
            <Typography variant="body1">
              ВНИМАНИЕ! В соответствии с п. 3 ст. 76 Федерального закона от 29.12.2012 N 273-ФЗ "Об образовании в
              Российской Федерации" требуется загрузить в личном кабинете слушателя (после подачи данной заявки)
              <b> скан-копию документа,</b> подтверждающего наличие у слушателя высшего или среднего профессионального
              образования (диплом).
            </Typography>
          </CSSTransition>
        </Stack>
      )}
    </Stack>
  );
};

export default StudentCardProgramsController;
