import {TableCell, TableRow, Skeleton} from '@mui/material';

const CustomersLoading = () => {
  return (
    <>
      <TableRow>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
        <TableCell>
          <Skeleton height={40} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default CustomersLoading;
