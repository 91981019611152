import {useNavigate, useParams} from 'react-router';
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Loader from '../../components/Loader';
import PageTitle from '../../components/Title/PageTitle';

// import useToggle from '../../hooks/useToggle';

import Module from './Module';
import {useGetTrainingQuery} from '../../api/slices/student';

import './styles.scss';
import ModuleMobile from './Module/moduleMobile';

const Education = () => {
  const {programId} = useParams();
  const {data = {}, isSuccess} = useGetTrainingQuery({programId});
  const {trainingProgram, phases, needDiplomForTests} = data;
  const navigate = useNavigate();

  // const [isSupervisorOpen, toggle] = useToggle();

  const isDesktop = useMediaQuery('(min-width:768px)');

  const getCurrentPhaseNumber = () => {
    if (!Array.isArray(phases) || phases?.length === 0) {
      return 0;
    }
    return phases?.find((phase) => phase?.currentPhase === true)?.number ?? phases.length;
  };

  const navigateToMain = () => {
    navigate('/');
  };

  return !isSuccess ? (
    <Loader />
  ) : (
    <Box>
      <PageTitle onBackButtonClick={navigateToMain}>{trainingProgram.name}</PageTitle>
      <Typography textAlign="center" variant="body1">
        {trainingProgram.text}
      </Typography>
      <Stack direction={{sm: 'row'}} justifyContent="center" alignItems="center" spacing={{s: 1.5}} mt={{s: 2}}>
        <Typography variant="body1">
          Объем программы <b>{trainingProgram.hoursCount} ак.ч.</b>
        </Typography>
        <Typography variant="body2">
          Модуль {getCurrentPhaseNumber()} из {phases.length}
        </Typography>
      </Stack>
      <Box className="module__list">
        {isDesktop ? (
          <TableContainer>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Модули программы</TableCell>
                  <TableCell align="center">Результат обучения</TableCell>
                  <TableCell align="center">Попытки</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {phases.map((module) => (
                  <Module
                    key={module.id}
                    moduleId={module.id}
                    name={module.name}
                    number={module.number}
                    currentPhase={module.currentPhase}
                    isPhaseFinished={module.isPhaseFinished}
                    needDiplomaForTests={needDiplomForTests}
                    isAvailable={module.isAvailable}
                    attemptCount={module.examAttemptCount ?? 0}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          phases.map((module) => (
            <ModuleMobile
              key={module.id}
              moduleId={module.id}
              name={module.name}
              number={module.number}
              currentPhase={module.currentPhase}
              isPhaseFinished={module.isPhaseFinished}
              needDiplomaForTests={needDiplomForTests}
              isAvailable={module.isAvailable}
              attemptCount={module.examAttemptCount ?? 0}
            />
          ))
        )}
      </Box>

      {/* <div className={classes.supervisor}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Link to="/" className="button outline">
            Назад
          </Link>
          <button className="button secondary" onClick={toggle}>
            Написать куратору
          </button>
        </Stack>
        <Modal show={isSupervisorOpen} onClose={toggle} title="Отправка сообщения куратору">
          <Supervisor close={toggle} emailLabel="email для получения ответа" textLabel="Сообщение куратору" />
        </Modal>
      </div> */}
    </Box>
  );
};

export default Education;
