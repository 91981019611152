import dayjs from 'dayjs';
import {Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material';
import {useGetBonusesHistoryQuery} from '../../api/slices/user';
import useToggle from '../../hooks/useToggle';
import {bonusesType} from '../../constants';

import Share from './ShareForm';
import MUIModal from '../../components/MUIModal';

const BonusesList = ({bonusesAmount}) => {
  const {data: bonuses} = useGetBonusesHistoryQuery();
  const [isShareBonuses, toggleShareBonuses] = useToggle(false);

  // const accrual = [bonusesType.ForFeedback, bonusesType.TestCharge, bonusesType.ExtraAgreedCharge];
  // const writeOff = [bonusesType.Expiration, bonusesType.OTBMarketWriteOff, bonusesType.TransferToOtherEmail];

  const bonusColor = (bonus) => {
    if (bonus.activateDateTime === null) return '#7C7C7C';

    if (bonus.amount < 0) {
      return 'error';
    } else if (bonus.amount > 0) {
      return 'green';
    } else {
      return '#7C7C7C';
    }
    // if (accrual.includes(bonus.bonusTypeId)) {
    //   return 'green';
    // } else if (writeOff.includes(bonus.bonusTypeId)) {
    //   return 'error';
    // } else {
    //   return '#7C7C7C';
    // }
  };

  return (
    <Box sx={{maxHeight: 640, minWidth: {sm: '360px', s: '200px'}}}>
      <Typography variant="h2" mb={1}>
        История зачислений
      </Typography>
      <Typography variant="body1" mb={2}>
        1 бонус = 1 ₽
      </Typography>
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell sx={{padding: 1.5}}>
                <Typography variant="body2">Дата</Typography>
              </TableCell>
              <TableCell align="center" sx={{padding: 1.5}}>
                <Typography variant="body2">Бонусы</Typography>
              </TableCell>
              <TableCell align="center" width={160} sx={{padding: 1.5}}>
                <Typography whiteSpace={'wrap'} variant="body2">
                  Кол-во бонусов на момент операции
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bonuses?.data.map((bonus) => (
              <TableRow key={bonus.id} sx={{verticalAlign: 'bottom'}}>
                <TableCell sx={{pt: 2, pb: 2}}>
                  <Typography variant="subtitle2">
                    {dayjs(bonus.created_at).locale('ru').format('DD MMMM YYYY')}
                  </Typography>
                  <Typography variant="body1">
                    {bonus.bonusTypeId === bonusesType.PersonalAccount ? bonus.contractNumber : bonus.bonusTypeName}
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{pb: 2}}>
                  <Typography variant="body2" color={bonusColor(bonus)}>
                    {bonus.amount}
                  </Typography>
                </TableCell>
                <TableCell align="center" sx={{pb: 2}}>
                  <Typography variant="body2">{bonus.sum}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {bonusesAmount ? (
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={toggleShareBonuses}
          sx={{mb: '20px', mt: '20px'}}>
          Подарить бонусы
        </Button>
      ) : null}
      <MUIModal isOpen={isShareBonuses} toggle={toggleShareBonuses} small>
        <Share close={toggleShareBonuses} />
      </MUIModal>
    </Box>
  );
};

export default BonusesList;
