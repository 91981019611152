import PropTypes from 'prop-types';
import {QUESTION_TYPES} from '../../../constants';

import MultiAnswer from '../MultiAnswer.js';

import classes from './styles.module.scss';
import {Button, Stack, useMediaQuery} from '@mui/material';

const AnswerReview = ({test = {}, onClick, answers, comment}) => {
  const parser = new DOMParser();
  comment = parser.parseFromString(comment, 'text/html').documentElement.textContent;
  const showComment = comment !== 'null' && comment !== 'undefined' && comment;
  const isSmallMobile = useMediaQuery('(max-width:425px)');
  const isMulti = test.question?.type === QUESTION_TYPES.severalAnswer;

  const isLastQuestion = test?.currentQuestionNumber === test?.questionCount;
  return (
    <div>
      <MultiAnswer
        isMulti={isMulti}
        disabled={true}
        options={answers}
        studentAnswers={isMulti ? test.currentStudentAnswers : [test.currentStudentAnswer]}
        rightAnswers={test.rightAnswerId}
        review={true}
      />
      {showComment && (
        <div className={classes.comment}>
          Комментарий
          <p className={classes.description}>{comment}</p>
        </div>
      )}
      <Button variant="contained" onClick={onClick} sx={{mt: 4, px: 12, py: 2}} fullWidth={isSmallMobile}>
        {!isLastQuestion ? 'Следующий вопрос' : 'Посмотреть результаты'}
      </Button>
    </div>
  );
};

AnswerReview.propTypes = {
  test: PropTypes.object,
  onClick: PropTypes.func,
  answers: PropTypes.array,
  comment: PropTypes.string,
};

export default AnswerReview;
