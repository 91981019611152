import {Typography, Button, Stack} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import {toast} from 'react-toastify';

import store from '../../../app/store';
import {contractSlice} from '../../../api/slices/user';
import {CustomerType} from '../../../constants';

export const Status = ({id, customerType, isPayed}) => {
  const handleClick = () => {
    store
      .dispatch(contractSlice.endpoints.payContract.initiate(id))
      .unwrap()
      .then(({redirectUrl}) => window.location.assign(redirectUrl))
      .catch((err) => {
        toast.error(err.data.error, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: true,
        });
      });
  };

  return (
    <Stack direction={{sm: 'column', s: 'row'}} alignItems="center" spacing={{md: 1, xs: 2, s: 1}}>
      {isPayed ? (
        <Stack direction="row" alignItems="center" spacing={1} sx={{color: '#17BE30'}}>
          <Typography variant="h5" sx={{color: '#17BE30'}}>
            Оплачено
          </Typography>
          <DoneIcon fontSize="18px" />
        </Stack>
      ) : (
        <Typography variant="h5" color="error">
          Не оплачено
        </Typography>
      )}
      {customerType === CustomerType.individual ? (
        !isPayed ? (
          <Button
            variant="contained"
            size="small"
            onClick={handleClick}
            sx={{p: {xs: 1, s: 0.5}, lineHeight: 'normal'}}>
            Оплатить
          </Button>
        ) : null
      ) : null}
    </Stack>
  );
};
