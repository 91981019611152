import {useRef} from 'react';
import {Box, Button, Skeleton, Stack} from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import {studentSlice} from '../../../api/slices/student';

const LectureMaterial = ({programId, lectureId}) => {
  const ref = useRef();
  const {data, isLoading} = studentSlice.endpoints.getLecture.useQueryState({
    programId,
    lectureId,
  });

  const openFullScreen = () => {
    ref.current.webkitRequestFullscreen();
  };
  return data?.link ? (
    <Box sx={{position: 'sticky', top: '10px'}}>
      <Stack alignItems="end" mb={2.5}>
        <Button endIcon={<OpenInFullIcon />} variant="text" color="primary" onClick={openFullScreen}>
          Развернуть на весь экран
        </Button>
      </Stack>
      {isLoading ? (
        <Skeleton width="100%" height="800px" />
      ) : (
        <iframe
          title="lecture"
          src={data?.link}
          allow="fullscreen"
          ref={ref}
          style={{width: '100%', height: '800px'}}
        />
      )}
    </Box>
  ) : (
    <Box />
  );
};

export default LectureMaterial;
