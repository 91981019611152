import {FormControl, FormControlLabel, Radio, RadioGroup, Skeleton} from '@mui/material';
import {TRAININGS} from '../../../constants';

const TrainingTypeRadioGroup = ({
  isLectureExist,
  isPresentationExist,
  isVideoExist,
  isLecturesLoading,
  isPresentationsLoading,
  isVideosLoading,
  activeTraining,
  setActiveTraining,
}) => {
  return (
    <FormControl fullWidth>
      <RadioGroup
        className="radio-group"
        value={activeTraining}
        onChange={(e) => setActiveTraining(e.target.value)}
        sx={{flexWrap: {lg: 'nowrap', s: 'wrap'}, mb: 2, flexDirection: {xs: 'row'}}}>
        {isLectureExist ? (
          <FormControlLabel
            className="radio-label"
            value={TRAININGS.LECTURE}
            control={<Radio />}
            label="Лекции"
            sx={{width: {xs: '45% ', s: '100%'}}}
          />
        ) : isLecturesLoading ? (
          <Skeleton height={70} sx={{width: {xs: '45% ', s: '100%'}}} />
        ) : null}
        {isPresentationExist ? (
          <FormControlLabel
            className="radio-label"
            value={TRAININGS.PRESENTATION}
            control={<Radio />}
            label="Презентации"
            sx={{width: {xs: '45% ', s: '100%'}}}
          />
        ) : isPresentationsLoading ? (
          <Skeleton height={70} sx={{width: {xs: '45% ', s: '100%'}}} />
        ) : null}
        {isVideoExist ? (
          <FormControlLabel
            className="radio-label"
            value={TRAININGS.VIDEO}
            control={<Radio />}
            label="Видео"
            sx={{width: {xs: '45% ', s: '100%'}}}
          />
        ) : isVideosLoading ? (
          <Skeleton height={70} sx={{width: {xs: '45% ', s: '100%'}}} />
        ) : null}
      </RadioGroup>
    </FormControl>
  );
};

export default TrainingTypeRadioGroup;
