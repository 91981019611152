import {useNavigate, useParams} from 'react-router';
import {Box, Stack, Skeleton} from '@mui/material';

import CustomerForm from './CustomerForm';

import PageTitle from '../../../components/Title/PageTitle';
import Loader from '../../../components/Loader';

import {useGetCustomerQuery} from '../../../api/slices/user';
import {CustomerType} from '../../../constants';

const CustomerInformation = () => {
  const {id} = useParams();
  const {data: customer, isLoading} = useGetCustomerQuery({id});
  const navigate = useNavigate();

  const pageTitle =
    customer?.customerTypeId === CustomerType.individual ? (
      <>
        {customer.surname} {customer.name} {customer.secondName}
      </>
    ) : (
      <>{customer?.complexName}</>
    );

  const navigateToMain = () => {
    navigate('/');
  };

  return (
    <Box>
      {isLoading ? (
        <PageTitle onBackButtonClick={navigateToMain}>
          <Stack direction="row" spacing={2}>
            <Skeleton sx={{width: {lg: 600, sm: 400, s: 250}, height: {lg: 70, s: 50}}} />
          </Stack>
        </PageTitle>
      ) : (
        <PageTitle onBackButtonClick={navigateToMain}>{pageTitle}</PageTitle>
      )}

      {isLoading ? <Loader /> : <CustomerForm initialValues={customer} />}
    </Box>
  );
};

export default CustomerInformation;
