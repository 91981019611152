import {Box, Stack, Typography} from '@mui/material';

import Item from '../Item';
import CustomerInformationBlock from './customerInformationBlock';

import ControlledAutocomplete from '../../../../components/FieldRegister/nestedAutocomplete';
import ControlledTextField from '../../../../components/FieldRegister/NestedTextField';
import ControlledMaskedTextField from '../../../../components/FieldRegister/MaskedTextField';
import {AutocompleteBIK} from '../../../../components/FieldRegister/AutocompleteBIK';
// import BonusesInfo from '../BonusesInfo';

const IndividualEntrepreneurForm = ({
  initialValues,
  isEditing,
  control,
  errors,
  autoFillBankFields,
  autoFillBikFields,
}) => {
  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={{s: 4.5}} mb={{lg: 3.5, sm: 2.5, s: 2}}>
        <Typography variant="body2">Тип заказчика</Typography>
        <Typography variant="body1">Индивидуальный предприниматель</Typography>
      </Stack>
      <CustomerInformationBlock name="Данные индивидуального предпринимателя">
        <Stack spacing={{sm: 3.5, s: 2}}>
          <Item label="Название организации" value={initialValues.organizationName} editable={isEditing}>
            <ControlledAutocomplete control={control} errors={errors} name="organizationName" type="party" />
          </Item>
          <Item label="Адрес доставки" value={initialValues.postAddress} editable={isEditing}>
            <ControlledAutocomplete control={control} errors={errors} name="postAddress" type="address" />
          </Item>
          <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
            <Item
              label="ИНН"
              value={initialValues.inn}
              editable={isEditing}
              itemProps={{width: {sm: '33%', s: '100%'}}}>
              <ControlledMaskedTextField format="##########" control={control} errors={errors} name="inn" fullWidth />
            </Item>
            <Item
              label="БИК"
              value={initialValues.bik}
              editable={isEditing}
              itemProps={{width: {sm: '33%', s: '100%'}}}>
              <AutocompleteBIK
                control={control}
                errors={errors}
                name="bik"
                label="БИК банка"
                type="bank"
                autoFill={autoFillBikFields}
                fullWidth
              />
            </Item>
            <Item
              label="Телефон"
              value={initialValues.phone}
              editable={isEditing}
              itemProps={{width: {sm: '33%', s: '100%'}}}>
              <ControlledTextField
                control={control}
                errors={errors}
                name="phone"
                // format="+7-(###)-###-####"
                // mask="_"
                fullWidth
              />
            </Item>
          </Stack>
          <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
            <Item
              label="ОГРН"
              value={initialValues.ogrn}
              editable={isEditing}
              itemProps={{width: {sm: '33%', s: '100%'}}}>
              <ControlledTextField control={control} errors={errors} name="ogrn" fullWidth />
            </Item>
            <Item
              label="Расч. счет"
              value={initialValues.rAccount}
              editable={isEditing}
              itemProps={{width: {sm: '33%', s: '100%'}}}>
              <ControlledMaskedTextField
                control={control}
                errors={errors}
                name="rAccount"
                format="####################"
                fullWidth
              />
            </Item>
            <Item
              label="Email"
              value={initialValues.email}
              editable={isEditing}
              itemProps={{width: {sm: '33%', s: '100%'}}}>
              <ControlledAutocomplete control={control} errors={errors} name="email" type="email" />
            </Item>
          </Stack>
          <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
            <Item
              label="Банк"
              value={initialValues.bank}
              editable={isEditing}
              itemProps={{width: {sm: '33%', s: '100%'}}}>
              <ControlledAutocomplete
                control={control}
                errors={errors}
                name="bank"
                type="bank"
                fullWidth
                autoFill={autoFillBankFields}
              />
            </Item>
            <Item
              label="Корр. счет"
              value={initialValues.kAccount}
              editable={isEditing}
              itemProps={{width: {sm: '33%', s: '100%'}}}>
              <ControlledMaskedTextField
                control={control}
                errors={errors}
                name="kAccount"
                format="####################"
                fullWidth
              />
            </Item>
          </Stack>
        </Stack>
      </CustomerInformationBlock>
      <CustomerInformationBlock name="Данные руководителя организации">
        <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
          <Item
            label="Фамилия"
            value={initialValues.directorSurname}
            editable={isEditing}
            itemProps={{width: {sm: '33%', s: '100%'}}}>
            <ControlledTextField control={control} errors={errors} name="directorSurname" fullWidth />
          </Item>
          <Item
            label="Имя"
            value={initialValues.directorName}
            editable={isEditing}
            itemProps={{width: {sm: '33%', s: '100%'}}}>
            <ControlledTextField control={control} errors={errors} name="directorName" fullWidth />
          </Item>
          <Item
            label="Отчество"
            value={initialValues.directorSecondName}
            editable={isEditing}
            itemProps={{width: {sm: '33%', s: '100%'}}}>
            <ControlledTextField control={control} errors={errors} name="directorSecondName" fullWidth />
          </Item>
        </Stack>
        {/*<BonusesInfo />*/}
      </CustomerInformationBlock>
    </Box>
  );
};

export default IndividualEntrepreneurForm;
