import {Box, Button, Divider, Grid, Stack, Typography, Link as TypographyLink} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import {Link} from 'react-router-dom';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import useToggle from '../../../hooks/useToggle';
import MUIModal from '../../../components/MUIModal';
import FileUploader from '../../../components/FileUploader';
import {publicSlice} from '../../../api/slices/public';
import {useGetStudentContractQuery} from '../../../api/slices/student';

const StudentMobile = ({
  id,
  trainingName,
  trainingFullName,
  trainingText,
  currentPhase,
  phaseCount,
  needDiplomaForAll = false,
  isDiplomaUploaded = false,
  isDiplomaAccepted = true,
  needPhotoSign = false,
  isPhotoSign = true,
  theLastElement,
}) => {
  const {data} = publicSlice.endpoints.getCompanySettings.useQueryState();
  const {data: contractInfo} = useGetStudentContractQuery();
  const [isDiplomaFormOpen, toggleDiplomaForm] = useToggle();

  const isNeedDiploma = needDiplomaForAll && !isDiplomaAccepted;
  const isNeedPhotoSign = needPhotoSign && !isPhotoSign;

  const programBlocked = isNeedDiploma || isNeedPhotoSign;

  return (
    <Box>
      <Grid
        display="grid"
        gridTemplateColumns="1fr auto"
        gridTemplateRows={{xs: 'auto auto', s: 'auto'}}
        justifyContent={{xs: 'space-between'}}
        alignItems="center"
        mb={{s: 2}}>
        <Box gridRow={{xs: '1/3', s: '1'}} mt={{xs: 0, s: 1}}>
          <Stack spacing={0.5}>
            <Typography variant="subtitle2" color={programBlocked ? '#727272' : 'initial'}>
              Программа
            </Typography>
            <Typography variant="body1" color={programBlocked ? '#727272' : 'initial'}>
              {trainingFullName}
            </Typography>
            <Typography variant="body2" color={programBlocked ? '#727272' : 'initial'}>
              {trainingText}
            </Typography>
          </Stack>
        </Box>
        <Box gridRow={{xs: '1', s: '2'}} mt={{xs: 0, s: 1}}>
          {contractInfo?.isArchival ? (
            <Typography variant="body1" textAlign="center">
              Договор в архиве
            </Typography>
          ) : programBlocked ? (
            isDiplomaUploaded ? (
              <Typography variant="body1">Ваш диплом уже загружен и ожидает проверки</Typography>
            ) : (
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body1">Для разблокировки загрузите диплом</Typography>
                <LockIcon />
              </Stack>
            )
          ) : (
            <Stack alignItems={{xs: 'end', s: 'left'}} spacing={{xs: 0, s: 0.5}}>
              <Typography variant="subtitle2">Прогресс</Typography>
              <Typography variant="body2">
                Модуль {currentPhase} из {phaseCount}
              </Typography>
            </Stack>
          )}
        </Box>
        <Box gridRow={{xs: '2', s: '2'}} mt={{s: 0.5}}>
          {!contractInfo?.isArchival && (
            <Stack alignItems={{xs: 'end', s: 'center'}} spacing={{xs: 0.5, s: 0.5}}>
              {programBlocked ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={isDiplomaUploaded}
                  onClick={toggleDiplomaForm}>
                  Загрузить
                </Button>
              ) : (
                <>
                  <Button
                    component={Link}
                    to={`program/${id}`}
                    variant="contained"
                    color="secondary"
                    sx={{p: {xs: 1, s: 0.5}, lineHeight: 'normal'}}
                    endIcon={<NavigateNextIcon />}>
                    Посмотреть
                  </Button>
                </>
              )}
            </Stack>
          )}
        </Box>
      </Grid>
      {theLastElement ? null : <Divider color="#57a5ff" />}
      <MUIModal isOpen={isDiplomaFormOpen} toggle={toggleDiplomaForm} small>
        {needPhotoSign ? (
          <Box sx={{minWidth: 280}}>
            <Typography>
              Внимание! Для доступа к экзамену по программе «{trainingName}» необходимо на почту
              <TypographyLink href={`mailto:${data?.email}`}> {data?.email} </TypographyLink>
              направить сканы фото слушателя размером 3x4 см и его подписи на белом фоне. Удостоверение изготавливается
              и ламинируется в учебном центре.
            </Typography>
          </Box>
        ) : (
          <FileUploader close={toggleDiplomaForm} email={data?.email} />
        )}
      </MUIModal>
    </Box>
  );
};

export default StudentMobile;
