import {Box, Stack, Typography} from '@mui/material';
import {CSSTransition} from 'react-transition-group';

import Item from '../Item';
import CustomerInformationBlock from './customerInformationBlock';

import ControlledTextField from '../../../../components/FieldRegister/NestedTextField';
import ControlledMaskedTextField from '../../../../components/FieldRegister/MaskedTextField';
import ControlledAutocomplete from '../../../../components/FieldRegister/nestedAutocomplete';
import ControlledDatePicker from '../../../../components/FieldRegister/DatePicker';
import ControlledSelect from '../../../../components/FieldRegister/Select';
import Checkbox from '../../../../components/checkbox';

import {BASIS_TYPES, BUDGET_TYPE} from '../../../../constants';
import {AutocompleteBIK} from '../../../../components/FieldRegister/AutocompleteBIK';

// import BonusesInfo from '../BonusesInfo';

const EntityForm = ({
  initialValues,
  isBudget,
  budgetTypeId,
  basisType,
  basisDate,
  isEditing,
  control,
  errors,
  register,
  unregister,
  autoFillBankFields,
  autoFillBikFields,
}) => {
  const isOnOrderBasisType = () => {
    if (basisType === BASIS_TYPES[0].id) {
      return true;
    } else if (basisType?.value === BASIS_TYPES[0].id) {
      return true;
    } else return false;
  };

  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={{s: 4.5}} mb={{lg: 3.5, sm: 2.5, s: 2}}>
        <Typography variant="body2">Тип заказчика</Typography>
        <Typography variant="body1">Юридическое лицо</Typography>
      </Stack>
      <CustomerInformationBlock name="Данные бюджета">
        <Stack spacing={{sm: 3.5, s: 2}}>
          <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
            <Item
              label="Бюджетная организация"
              value={initialValues.isBudget ? 'Да' : 'Нет'}
              editable={isEditing}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <Checkbox
                id="isBudget"
                name="isBudget"
                {...register('isBudget', {
                  onChange: () => unregister('budgetTypeId'),
                })}
                // label={isBudget ? 'Да' : 'Нет'}
              />
            </Item>
            <Item
              label="Казначейский счет"
              value={initialValues?.treasuryAccount}
              editable={isEditing}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <ControlledTextField control={control} errors={errors} name="treasuryAccount" fullWidth />
            </Item>
          </Stack>
          <CSSTransition in={isBudget} timeout={200} classNames="fade" unmountOnExit>
            <>
              <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
                <Item
                  label="Тип бюджета"
                  value={budgetTypeId?.label}
                  editable={isEditing}
                  itemProps={{width: {sm: '50%', s: '100%'}}}>
                  <ControlledSelect control={control} errors={errors} name="budgetTypeId" options={BUDGET_TYPE} />
                </Item>
                <Item
                  label="Лицевой счет"
                  value={initialValues?.personalAccount}
                  editable={isEditing}
                  itemProps={{width: {sm: '50%', s: '100%'}}}>
                  <ControlledTextField control={control} errors={errors} name="personalAccount" fullWidth />
                </Item>
              </Stack>
              <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
                <Item
                  label="ТО Федерального казначейства"
                  value={initialValues?.departmentTitle}
                  editable={isEditing}
                  itemProps={{width: {sm: '50%', s: '100%'}}}>
                  <ControlledTextField control={control} errors={errors} name="departmentTitle" fullWidth />
                </Item>
                <Item
                  label="Единый казначейский счет (ЕКС)"
                  value={initialValues?.mainAccount}
                  editable={isEditing}
                  itemProps={{width: {sm: '50%', s: '100%'}}}>
                  <ControlledTextField control={control} errors={errors} name="mainAccount" fullWidth />
                </Item>
              </Stack>
            </>
          </CSSTransition>
        </Stack>
      </CustomerInformationBlock>
      <CustomerInformationBlock name="Данные организации">
        <Stack spacing={{sm: 3.5, s: 2}}>
          <Item label="Название организации" value={initialValues.organizationName} editable={isEditing}>
            <ControlledAutocomplete control={control} errors={errors} name="organizationName" type="party" />
          </Item>
          <Item label="Юридический адрес" value={initialValues.legalAddress} editable={isEditing}>
            <ControlledAutocomplete control={control} errors={errors} name="legalAddress" type="address" />
          </Item>
          <Item label="Почтовый адрес" value={initialValues.postAddress} editable={isEditing}>
            <ControlledAutocomplete control={control} errors={errors} name="postAddress" type="address" />
          </Item>
          <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
            <Item
              label="ИНН"
              value={initialValues.inn}
              editable={isEditing}
              itemProps={{width: {sm: '33%', s: '100%'}}}>
              <ControlledMaskedTextField format="##########" control={control} errors={errors} name="inn" fullWidth />
            </Item>
            <Item
              label="БИК"
              value={initialValues.bik}
              editable={isEditing}
              itemProps={{width: {sm: '33%', s: '100%'}}}>
              <AutocompleteBIK
                control={control}
                errors={errors}
                name="bik"
                label="БИК банка"
                type="bank"
                autoFill={autoFillBikFields}
                fullWidth
              />
            </Item>
            <Item
              label="Телефон"
              value={initialValues.phone}
              editable={isEditing}
              itemProps={{width: {sm: '33%', s: '100%'}}}>
              <ControlledTextField
                control={control}
                errors={errors}
                name="phone"
                // format="+7-(###)-###-####"
                // mask="_"
                fullWidth
              />
            </Item>
          </Stack>
          <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
            <Item
              label="КПП"
              value={initialValues.kpp}
              editable={isEditing}
              itemProps={{width: {sm: '33%', s: '100%'}}}>
              <ControlledMaskedTextField control={control} errors={errors} name="kpp" format="#########" fullWidth />
            </Item>
            <Item
              label="Расч. счет"
              value={initialValues.rAccount}
              editable={isEditing}
              itemProps={{width: {sm: '33%', s: '100%'}}}>
              <ControlledMaskedTextField
                control={control}
                errors={errors}
                name="rAccount"
                format="####################"
                fullWidth
              />
            </Item>
            <Item
              label="Email"
              value={initialValues.email}
              editable={isEditing}
              itemProps={{width: {sm: '33%', s: '100%'}}}>
              <ControlledAutocomplete control={control} errors={errors} name="email" type="email" />
            </Item>
          </Stack>
          <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
            <Item
              label="Банк"
              value={initialValues.bank}
              editable={isEditing}
              itemProps={{width: {sm: '35%', s: '100%'}}}>
              <ControlledAutocomplete
                control={control}
                errors={errors}
                name="bank"
                type="bank"
                fullWidth
                autoFill={autoFillBankFields}
              />
            </Item>
            <Item
              label="Корр. счет"
              value={initialValues.kAccount}
              editable={isEditing}
              itemProps={{width: {sm: '35%', s: '100%'}}}>
              <ControlledMaskedTextField
                control={control}
                errors={errors}
                name="kAccount"
                format="####################"
                fullWidth
              />
            </Item>
          </Stack>
        </Stack>
      </CustomerInformationBlock>

      <CustomerInformationBlock name="Данные руководителя организации">
        <Stack spacing={{sm: 3.5, s: 2}}>
          <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
            <Item
              label="Фамилия"
              value={initialValues.directorSurname}
              editable={isEditing}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <ControlledTextField control={control} errors={errors} name="directorSurname" fullWidth />
            </Item>
            <Item
              label="Имя"
              value={initialValues.directorName}
              editable={isEditing}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <ControlledTextField control={control} errors={errors} name="directorName" fullWidth />
            </Item>
          </Stack>
          <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
            <Item
              label="Отчество"
              value={initialValues.directorSecondName}
              editable={isEditing}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <ControlledTextField control={control} errors={errors} name="directorSecondName" fullWidth />
            </Item>
            <Item
              label="Должность"
              value={initialValues.directorProfession}
              editable={isEditing}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <ControlledTextField control={control} errors={errors} name="directorProfession" fullWidth />
            </Item>
          </Stack>
          <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
            {!isOnOrderBasisType() && (
              <Item
                label="Номер основания"
                value={initialValues.basisNumber}
                editable={isEditing}
                itemProps={{width: {sm: '50%', s: '100%'}}}>
                <ControlledTextField control={control} errors={errors} name="basisNumber" fullWidth />
              </Item>
            )}
          </Stack>
          {!isOnOrderBasisType() && (
            <Item
              label="Дата основания"
              value={basisDate}
              editable={isEditing}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <ControlledDatePicker control={control} errors={errors} name="basisDate" />
            </Item>
          )}
        </Stack>
      </CustomerInformationBlock>
    </Box>
  );
};

export default EntityForm;
