import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import classNames from 'classnames/bind';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import {useFinishTestQuery, useGetExamResultQuery, useGetTrainingQuery} from '../../api/slices/student';

import ProgressBar from '../../features/ProgressBar';
import Loader from '../../components/Loader';

import {Box, Button, Skeleton, Stack, Typography, useMediaQuery} from '@mui/material';
import MUIModal from '../../components/MUIModal';
import useToggle from '../../hooks/useToggle';
import {EisotAttempt} from '../../features/Overlays/EisotAttempt';
import classes from './styles.module.scss';
import {useGetCompanySettingsQuery} from '../../api/slices/public';

const cx = classNames.bind(classes);

export function Result() {
  const {knowledgeType, programId, moduleId, showEisot} = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:480px)');
  const {data: companyInfo} = useGetCompanySettingsQuery();
  const [shouldShowEisot, setShouldShowEisot] = useState(knowledgeType === 'exam' && showEisot === 'true');
  const [eisotOpen, toggleEisot] = useToggle(false);
  const {data} = useGetTrainingQuery({programId}, {skip: !programId});

  const knowledge = {
    exam: {title: 'Экзамен', result: 'Результаты экзамена', query: useGetExamResultQuery},
    test: {title: 'Тест', result: 'Результаты тестирования', query: useFinishTestQuery},
  };

  const {
    data: result,
    isLoading,
    isSuccess,
    isError,
    error,
  } = knowledge[knowledgeType].query(knowledgeType === 'exam' ? moduleId : programId, {
    refetchOnMountOrArgChange: knowledgeType === 'exam',
  });

  useEffect(() => {
    if (isError && !result) {
      if (error?.status !== 404) {
        navigate('/', {replace: true});
      }
    }
  }, [isError, navigate, result]);

  const answerCorrectness = result?.answers.map((answer) => answer.answerCorrectness);

  useEffect(() => {
    const isEisotProgram = data?.trainingProgram?.trainingProgramType?.isEisot === true;

    if (shouldShowEisot === true && result?.isSuccessful && isEisotProgram) {
      toggleEisot();
      setShouldShowEisot(false);
    }
  }, [result, shouldShowEisot, data]);

  if (error?.status === 404) {
    return (
      <Stack sx={{maxWidth: '60%', mx: 'auto'}} justifyContent={'center'} gap={2}>
        <Typography variant="h1" textAlign={'center'} mt={'30%'}>
          Результаты не найдены.
        </Typography>
        <Typography variant="h1" textAlign={'center'} justifySelf={'center'}>
          Для получения результатов обратитесь по номеру телефона:{' '}
          {!companyInfo?.phone ? <Skeleton /> : companyInfo?.phone}
        </Typography>
        <Button onClick={() => navigate('/', {replace: true})} variant="contained" size="large" sx={{mx: 'auto'}}>
          На главную
        </Button>
      </Stack>
    );
  }

  return isLoading || !isSuccess ? (
    <Loader />
  ) : (
    <Stack gap={3}>
      <MUIModal isOpen={eisotOpen} showCloseIcon={false}>
        <EisotAttempt program={data} trainingId={programId} close={toggleEisot} />
      </MUIModal>
      <Typography variant="h1" textAlign="center" mt={2}>
        {knowledge[knowledgeType].result}
      </Typography>
      <Box className={classes.container}>
        <Stack gap={2}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{color: result.isSuccessful ? '#17BE30' : '#FE0202'}}>
            <Typography variant="h2">
              {result.isSuccessful
                ? `${knowledge[knowledgeType].title} пройден `
                : `${knowledge[knowledgeType].title} провален `}
            </Typography>
            {result.isSuccessful ? <CheckIcon /> : null}
            <Typography variant="h2" className={cx({correct: result.isSuccessful, incorrect: !result.isSuccessful})}>
              {`${result.percentageOfCorrectAnswers}%`}
            </Typography>
          </Stack>

          {isMobile && (
            <Stack gap={2} width={'100%'} justifyContent={'center'}>
              <Link to={`/program/${programId}`} replace>
                <Button fullWidth variant="contained" size="large">
                  Вернуться к обучению
                </Button>
              </Link>
            </Stack>
          )}

          <ProgressBar answers={answerCorrectness} totalQuestions={result?.questionCount} />

          <Stack gap={4}>
            {result.answers.map(({question, answerCorrectness, studentAnswer, comment, rightAnswer}, index) => (
              <Answers
                key={index}
                number={index}
                question={question}
                answerCorrectness={answerCorrectness}
                studentAnswer={studentAnswer}
                comment={comment}
                rightAnswer={rightAnswer}
              />
            ))}
          </Stack>
        </Stack>

        {!isMobile && (
          <Stack pt={2} gap={2} width={'100%'} justifyContent={'center'}>
            <Link to={`/program/${programId}`} replace>
              <Button variant="contained" size="large" sx={{px: 4, py: 2, fontWeight: 'bold'}}>
                Вернуться к обучению
              </Button>
            </Link>
          </Stack>
        )}
      </Box>
    </Stack>
  );
}

const Answers = ({question, answerCorrectness, studentAnswer, comment, rightAnswer, number}) => {
  const parseElement = (element) => {
    const elemText = new DOMParser().parseFromString(element, 'text/html');
    const img = elemText.getElementsByTagName('img');
    const Image = () =>
      React.createElement(img[0].tagName.toLowerCase(), {
        src: img[0].src,
        style: {maxHeight: '150px', maxWidth: '150px'},
      });

    return img.length ? <Image /> : elemText.documentElement.textContent;
  };

  const elemQuestionText = new DOMParser().parseFromString(question, 'text/html');
  const questionImg = elemQuestionText.getElementsByTagName('img');
  const Image = () =>
    questionImg?.length !== 0 ? (
      React.createElement(questionImg[0].tagName.toLowerCase(), {
        src: questionImg[0].src,
      })
    ) : (
      <></>
    );

  const parsedComment = parseElement(comment);
  const parsedStudentAnswer = Array.isArray(studentAnswer) ? studentAnswer.join(', ') : parseElement(studentAnswer);
  const parsedRightAnswer = Array.isArray(rightAnswer)
    ? rightAnswer.map((elem) => parseElement(elem))
    : parseElement(rightAnswer);

  const showComment = parsedComment !== 'null' && parsedComment !== 'undefined' && parsedComment;

  return (
    <Stack gap={1}>
      <Typography variant="body2">Вопроc {number + 1}</Typography>
      <Stack>
        <Image />
        <Typography variant="h2" sx={{fontWeight: 'bold'}}>
          {elemQuestionText?.body?.textContent || elemQuestionText?.textContent}
        </Typography>
      </Stack>
      <Stack
        pt={1}
        direction="row"
        spacing={1}
        sx={{color: answerCorrectness ? '#17BE30' : '#FE0202'}}
        alignItems="center">
        <Typography variant="h2" sx={{fontWeight: 'bold'}}>
          {answerCorrectness ? 'Ответ верный' : 'Ответ неверный'}
        </Typography>
        {answerCorrectness ? <CheckIcon /> : <CloseIcon />}
      </Stack>
      <Stack gap={1.5}>
        <Box className={classes.answer}>
          <Typography variant="body1" sx={{color: answerCorrectness ? '#17BE30' : '#FE0202'}}>
            Ваш ответ
          </Typography>
          <Typography variant="body1">
            {parsedStudentAnswer !== 'null' ? parsedStudentAnswer : 'Ответ не был дан'}
          </Typography>
        </Box>

        {!answerCorrectness && (
          <Box className={classes.answer}>
            <Typography variant="body1" sx={{color: '#17BE30'}}>
              Правильный ответ
            </Typography>
            <Typography variant="body1" sx={{color: '#17BE30', fontWeight: 'bold'}}>
              {parsedRightAnswer.length > 1 ? parsedRightAnswer.join(', ') : parsedRightAnswer.pop()}
            </Typography>
          </Box>
        )}
      </Stack>

      {showComment ? (
        <div className={classes.row}>
          <p className={classes.left}>Комментарий</p>
          <p>{parsedComment}</p>
        </div>
      ) : null}
    </Stack>
  );
};
