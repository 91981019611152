import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

import classes from './styles.module.scss';

let cx = classNames.bind(classes);

const ProgressBar = ({answers = [], currentQuestion, totalQuestions}) => {
  const totalQuestionsList = new Array(totalQuestions).fill(null);

  return (
    <div className={classes.container}>
      {totalQuestionsList.map((elem, index) => (
        <div
          key={index}
          className={cx({
            block: true,
            block_current: currentQuestion === index + 1,
            block_correct: answers[index],
            block_incorrect: !answers[index] && answers[index] !== undefined,
          })}>
          {index + 1}
        </div>
      ))}
    </div>
  );
};

ProgressBar.propsType = {
  answers: PropTypes.array,
  currentQuestion: PropTypes.number,
  totalQuestions: PropTypes.number,
};

export default ProgressBar;
