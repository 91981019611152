import {Box, Dialog, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MUIModal = ({isOpen, toggle, showCloseIcon = true, small, children}) => {
  return (
    <Dialog open={isOpen} onClose={toggle} className="MUIModal">
      <Box sx={{p: {xs: '30px 50px', s: '20px'}, borderRadius: '10px', position: 'relative'}}>
        {showCloseIcon && (
          <IconButton sx={{position: 'absolute', top: '0px', right: '0px'}} onClick={toggle}>
            <CloseIcon />
          </IconButton>
        )}
        {children}
      </Box>
    </Dialog>
  );
};

export default MUIModal;
