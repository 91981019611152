import {useState} from 'react';
import classNames from 'classnames/bind';
import {CSSTransition} from 'react-transition-group';
import {Divider, IconButton, Stack, Typography} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import store from '../../../app/store';
import {studentSlice} from '../../../api/slices/student';
import {EDUCATION_MATERIAL_TYPE} from '../../../constants';

import './styles.scss';
import {skipToken} from '@reduxjs/toolkit/query';

const Presentation = ({name, programId, presentationId, educationType, isActive, setActive, isDesktop}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const openPresentation = () => {
    setActive(presentationId);
    store.dispatch(
      studentSlice.endpoints.getPresentation.initiate({programId, presentationId}, {forceRefetch: !isActive})
    );
  };

  const openAdLink = () => {
    store
      .dispatch(studentSlice.endpoints.getPresentation.initiate({programId, presentationId}))
      .unwrap()
      .then((res) => {
        window.open(res.link, '_blank');
      })
      .catch((err) => console.log(err));
  };

  const {data, isSuccess, isError} = studentSlice.endpoints.getPresentation.useQueryState(
    !isDesktop
      ? {
          programId,
          presentationId,
        }
      : skipToken
  );

  const updateIndex = (index) => {
    if (index < 0) {
      index = data?.slides.length - 1;
    } else if (index >= data?.slides.length) {
      index = 0;
    }
    setCurrentSlide(index);
  };

  const parser = new DOMParser();
  name = parser.parseFromString(name, 'text/html').documentElement.textContent;

  return (
    <div>
      {educationType === EDUCATION_MATERIAL_TYPE.adLink ? (
        <Stack spacing={1} mb={2} sx={{cursor: 'pointer'}} className="link">
          <Typography variant="body1" color="error" onClick={openAdLink}>
            {name}
          </Typography>
          <Divider color="#89AAFF" sx={{height: '2px'}} />
        </Stack>
      ) : (
        <Stack spacing={1} mb={2} sx={{cursor: 'pointer'}} color={isActive ? '#0249FE' : 'initial'} className="link">
          <Typography variant="body1" onClick={openPresentation}>
            {name}
          </Typography>
          <Divider color="#89AAFF" sx={{height: '2px'}} />
        </Stack>
      )}
      {!isDesktop && (
        <>
          <CSSTransition in={isActive && isSuccess} classNames="presentation" timeout={300} unmountOnExit>
            <div className="carousel-wrapper">
              <div className="carousel">
                {data?.slides.map((elem, index) => (
                  <img
                    key={elem.id}
                    className={classNames({
                      photo: true,
                      active: index === currentSlide,
                      prev: index + 1 === currentSlide,
                      next: index - 1 === currentSlide,
                    })}
                    alt=""
                    src={elem.link}
                  />
                ))}
                <IconButton
                  onClick={() => updateIndex(currentSlide - 1)}
                  sx={{position: 'absolute', left: 0, top: 0, bottom: 0, zIndex: 3}}>
                  <ArrowBackIosNewIcon sx={{color: '#000'}} />
                </IconButton>
                <IconButton
                  onClick={() => updateIndex(currentSlide + 1)}
                  sx={{position: 'absolute', right: 0, top: 0, bottom: 0, zIndex: 3}}>
                  <ArrowForwardIosIcon sx={{color: '#000'}} />
                </IconButton>
              </div>
              <span className="text">{`слайд ${currentSlide + 1} из ${data?.slides.length}`}</span>
            </div>
          </CSSTransition>
          {isError && (
            <Typography variant="body1" color="error">
              Приносим свои извинения, произошла ошибка на сервере, перезагрузите страницу или зайдите позже
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default Presentation;
