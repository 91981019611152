import {useParams} from 'react-router';
import {Link} from 'react-router-dom';

import {publicSlice} from '../../api/slices/public';
import {useGetTrainingQuery} from '../../api/slices/student';

import Loader from '../../components/Loader';
import {Button, Stack, Typography} from '@mui/material';

const EisotExamAttention = ({close}) => {
  const {programId, moduleId} = useParams();
  const {data: trainings, isLoading} = useGetTrainingQuery({programId});

  const {data} = publicSlice.endpoints.getCompanySettings.useQueryState();

  return isLoading ? (
    <Loader />
  ) : (
    <Stack spacing={1.5}>
      <Typography variant="h2">Внутренний экзамен</Typography>
      <Typography variant="body1">
        Данный внутренний экзамен является подготовкой к проверке знания требований охраны труда в единой общероссийской
        справочно-информационной системы по охране труда в информационно-телекоммуникационной сети «Интернет»
        (тестирование в ЕИСОТ).
      </Typography>
      <Typography variant="body1">Внутренний экзамен состоит из 30 тестовых вопросов.</Typography>
      <Typography variant="body1">
        Время для сдачи внутреннего экзамена не ограничено (таймер используется для самоконтроля)!
      </Typography>
      <Typography variant="body1">
        Внутренний экзамен считается пройденным, если правильно отвечено на 80 % вопросов (24 вопроса).
      </Typography>
      <Typography variant="body1">Для сдачи внутреннего экзамена предоставляется не более 3 попыток.</Typography>
      <Typography variant="body1">Результаты внутреннего экзамена хранятся в личном кабинете.</Typography>
      <Typography variant="body1">
        Если Вам <b>не удалось сдать экзамен после 3 попыток</b>, сообщите о проблеме куратору курса или свяжитесь с
        оператором Call-центра по телефону <a href={`tel:${data.phone}`}>{data.phone}</a>
      </Typography>
      <Typography variant="body1">После успешной СДАЧИ внутреннего ЭКЗАМЕНА доступ к ТРЕНАЖЕРУ будет ЗАКРЫТ</Typography>
      <Stack spacing={1.5}>
        <Button variant="contained" color="secondary" onClick={close}>
          Я еще не готов
        </Button>
        <Button
          disabled={trainings.examAttemptCount === 3 || isLoading}
          component={Link}
          to={`/exam/${programId}/${moduleId}`}
          variant="contained"
          color="primary">
          {trainings.examAttemptCount === 3 ? 'Попытки закончились' : 'Начать внутренний экзамен'}
        </Button>
      </Stack>
    </Stack>
  );
};

export default EisotExamAttention;
