import {Link} from 'react-router-dom';
import {Box, Button, Stack, Link as TypographyLink, Typography} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DoneIcon from '@mui/icons-material/Done';
import {authSlice} from '../../../api/slices/auth';

import {useGetContractListQuery} from '../../../api/slices/user';
import {publicSlice, useGetCompanySettingsQuery} from '../../../api/slices/public';
import PageTitle from '../../../components/Title/PageTitle';
import BonusesSoon from './modules/BonusesSoon';
import Customers from './modules/Customers';
import useToggle from '../../../hooks/useToggle';

import {CONTRACT_STATUS} from '../../../constants';
import ReplayIcon from '@mui/icons-material/Replay';

const CustomerPersonalArea = () => {
  const {data: user} = authSlice.endpoints.getUserInfo.useQueryState();
  const {data: company} = useGetCompanySettingsQuery();
  const {data: customers = []} = useGetContractListQuery(null, {refetchOnMountOrArgChange: true});

  const [isFinishedContractShown, toggle] = useToggle();

  const notFinishedContracts = customers.filter(
    (customer) => customer.contractStatusId !== CONTRACT_STATUS.inPlace && !customer?.inArchiveDate
  );
  const finishedContracts = customers.filter(
    (customer) => customer.contractStatusId === CONTRACT_STATUS.inPlace || customer?.inArchiveDate !== null
  );

  const sendMetric = () => {
    window.ym(company.htmlCounterYaMetrika, 'hit', '/create-contract');
  };

  return (
    <Box>
      <PageTitle>Список договоров</PageTitle>
      <Stack
        direction={{sm: 'row'}}
        justifyContent={{sm: 'space-between', s: 'center'}}
        alignItems={{sm: 'end'}}
        spacing={{sm: 2, s: 1}}>
        <Stack direction="row" spacing={{sm: 2, s: 1}} sx={{width: {sm: '65%'}}}>
          <Button
            component={Link}
            onClick={sendMetric}
            to="create-contract"
            size="large"
            variant="contained"
            sx={{p: {md: '15px 30px', sm: '10px 20px', s: '5px 10px'}}}
            fullWidth>
            Новая заявка на обучение
          </Button>
          <Button
            component={TypographyLink}
            href={company?.paymentInfoPage}
            size="large"
            target="_blank"
            rel="noreferrer"
            variant="contained"
            color="secondary"
            sx={{p: {md: '15px 30px', sm: '10px 20px', s: '5px 10px'}}}
            fullWidth>
            Способы оплаты
          </Button>
        </Stack>
        <BonusesSoon notActiveBonuses={user?.bonusesNotActivated} />
      </Stack>
      <Customers
        title="Текущие договоры"
        contracts={notFinishedContracts}
        contractStatus={
          <Stack justifyContent="center" alignItems="center" direction="row" spacing={1}>
            <Typography variant="body1">В процессе</Typography>
            <ReplayIcon sx={{fontSize: {s: '14px'}}} />
          </Stack>
        }
      />

      {finishedContracts?.length > 0 ? (
        <Stack justifyContent="center" mt={2}>
          <Button
            variant="text"
            endIcon={isFinishedContractShown ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            onClick={toggle}>
            {isFinishedContractShown ? 'Скрыть' : 'Показать'} архивные договоры
          </Button>
        </Stack>
      ) : null}

      {isFinishedContractShown ? (
        <Customers
          title="Архивные договоры"
          contracts={finishedContracts}
          contractStatus={
            <Stack justifyContent="center" alignItems="center" direction="row" sx={{color: '#0249FE'}}>
              <Typography variant="body1" color="#0249FE">
                Завершён
              </Typography>
              <DoneIcon />
            </Stack>
          }
        />
      ) : null}
    </Box>
  );
};

export default CustomerPersonalArea;
