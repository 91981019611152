import {useFormContext} from 'react-hook-form';
import {Box, Button, Stack, Typography, useMediaQuery} from '@mui/material';

import ControlledAutocomplete from '../../../../../components/FieldRegister/nestedAutocomplete';
import ControlledTextField from '../../../../../components/FieldRegister/NestedTextField';

import StudentCardInfoTitle from './studentCardInfoTitle';
import StudentCardEducationPrograms from './studentCardEducationPrograms';

const StudentFormFields = ({hideAddingForm}) => {
  const isDesktop = useMediaQuery('(min-width:768px)');

  const {
    control,
    formState: {errors},
  } = useFormContext();

  return (
    <Box className="student__card" mt={{lg: 4, sm: 2.5, s: 2}} mb={{lg: 4, sm: 2.5, s: 2}}>
      <Stack direction={{sm: 'row'}} spacing={2}>
        <Box width={{sm: '45%'}}>
          <StudentCardInfoTitle studentNumber={1} removeStudent={hideAddingForm} />
          <Stack spacing={1}>
            <ControlledAutocomplete
              control={control}
              errors={errors}
              name="surname"
              label="Фамилия*"
              type="fio"
              fullWidth
              pattern={/^(^[-а-яА-ЯёЁ]+$[^0-9]*)$/}
            />
            <ControlledAutocomplete
              control={control}
              errors={errors}
              name="firstname"
              label="Имя*"
              type="fio"
              fullWidth
              pattern={/^(^[-а-яА-ЯёЁ]+$[^0-9]*)$/}
            />
            <ControlledAutocomplete
              control={control}
              errors={errors}
              name="secondname"
              label="Отчество"
              type="fio"
              fullWidth
              pattern={/^(^[-а-яА-ЯёЁ]+$[^0-9]*)$/}
            />
            <ControlledTextField control={control} errors={errors} name="jobPlace" label="Место работы*" fullWidth />
            <ControlledTextField control={control} errors={errors} name="profession" label="Должность*" fullWidth />
          </Stack>
          {isDesktop ? (
            <Typography variant="subtitle2" mt={{lg: 3.5, xs: 2.5, s: 2}}>
              * — поля, обязательные для заполнения
            </Typography>
          ) : null}
        </Box>
        <StudentCardEducationPrograms removeStudent={hideAddingForm} />
      </Stack>
      <Button type="submit" variant="contained" size="large" fullWidth>
        Добавить слушателя
      </Button>
    </Box>
  );
};

export default StudentFormFields;
