import {IconButton, Stack, Typography, useMediaQuery} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StudentCardInfoTitle = ({studentNumber, removeStudent}) => {
  const isTablet = useMediaQuery('(min-width:768px)');

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={{md: 2.5, s: 2}}>
      <Stack direction="row" alignItems="center" justifyContent={{md: 'space-between', s: 'start'}}>
        <Typography variant="h4" mr={{md: 2.5, s: 1.5}}>
          Слушатель №{studentNumber}
        </Typography>
      </Stack>
      {!isTablet ? (
        <IconButton aria-label="delete" onClick={removeStudent} sx={{p: 0}}>
          <CloseIcon fontSize="20px" />
        </IconButton>
      ) : null}
    </Stack>
  );
};

export default StudentCardInfoTitle;
