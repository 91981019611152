import {Stack} from '@mui/material';
import {useFormContext} from 'react-hook-form';
import InformationBlock from './informationBlock';

import ControlledAutocomplete from '../../../../../components/FieldRegister/nestedAutocomplete';
import ControlledMaskedTextField from '../../../../../components/FieldRegister/MaskedTextField';
import ControlledCheckbox from '../../../../../components/FieldRegister/Checkbox';
import ControlledDatePicker from '../../../../../components/FieldRegister/DatePicker';
import ControlledTextField from '../../../../../components/FieldRegister/NestedTextField';

const IndividualForm = ({isForeignCitizenship}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();

  return (
    <InformationBlock title="Введите данные о заказчике">
      <Stack
        spacing={{s: 1}}
        direction={{sm: 'row'}}
        flexWrap={{sm: 'wrap'}}
        justifyContent="space-between"
        useFlexGap={true}>
        <ControlledAutocomplete
          control={control}
          errors={errors}
          name="surname"
          label="Фамилия*"
          type="fio"
          sx={{width: {sm: '49%'}}}
          pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
        />
        <ControlledAutocomplete
          control={control}
          errors={errors}
          name="name"
          label="Имя*"
          type="fio"
          sx={{width: {sm: '49%'}}}
          pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
        />
        <ControlledAutocomplete
          control={control}
          errors={errors}
          name="secondName"
          label="Отчество"
          type="fio"
          sx={{width: {sm: '49%'}}}
          pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
        />
        <ControlledAutocomplete
          control={control}
          errors={errors}
          name="email"
          label="Email*"
          type="email"
          sx={{width: {sm: '49%'}}}
        />
        <ControlledMaskedTextField
          control={control}
          errors={errors}
          name="phone"
          label="Телефон*"
          format="+7-(###)-###-####"
          mask="_"
          sx={{width: {sm: '49%'}}}
        />
        <ControlledAutocomplete
          control={control}
          errors={errors}
          name="postAddress"
          label="Почтовый адрес*"
          type="address"
          sx={{width: {sm: '49%'}}}
        />
        <ControlledAutocomplete
          control={control}
          errors={errors}
          name="legalAddress"
          label="Адрес*"
          type="address"
          sx={{width: '100%'}}
        />
      </Stack>
      <InformationBlock
        title="Паспорт"
        extra={
          <ControlledCheckbox
            name="isForeignCitizenship"
            label="Паспорт иностранного гражданина"
            defaultValue={false}
          />
        }>
        <Stack
          spacing={{s: 1}}
          direction={{sm: 'row'}}
          flexWrap={{sm: 'wrap'}}
          justifyContent="space-between"
          useFlexGap={true}>
          {isForeignCitizenship ? (
            <ControlledTextField
              control={control}
              errors={errors}
              name="passportSerial"
              label="Серия*"
              sx={{width: {sm: '32%'}}}
            />
          ) : (
            <ControlledMaskedTextField
              control={control}
              errors={errors}
              name="passportSerial"
              label="Серия*"
              format="####"
              sx={{width: {sm: '32%'}}}
            />
          )}
          {isForeignCitizenship ? (
            <ControlledTextField
              control={control}
              errors={errors}
              name="passportNumber"
              label="Номер*"
              sx={{width: {sm: '32%'}}}
            />
          ) : (
            <ControlledMaskedTextField
              control={control}
              errors={errors}
              name="passportNumber"
              label="Номер*"
              format="######"
              sx={{width: {sm: '32%'}}}
            />
          )}
          <ControlledDatePicker
            control={control}
            errors={errors}
            disableFuture
            name="passportIssuedDate"
            label="Дата выдачи*"
            sx={{width: {sm: '32%'}}}
          />
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="passportIssued"
            label="Кем выдан*"
            type="fms_unit"
            sx={{width: {sm: '100%'}}}
          />
        </Stack>
      </InformationBlock>
    </InformationBlock>
  );
};

export default IndividualForm;
