import {Stack, TableCell, TableRow, Typography} from '@mui/material';
import {Link} from 'react-router-dom';

import Phase from './Phase';

const Module = ({
  moduleId,
  name,
  number,
  currentPhase,
  isPhaseFinished,
  needDiplomaForTests,
  isAvailable,
  attemptCount,
}) => {
  const isPhaseAvailable = (currentPhase && isPhaseFinished) || isAvailable;

  return (
    <TableRow>
      <TableCell>
        {isPhaseAvailable ? (
          <Stack flexDirection={'column'} gap={1}>
            <Link to={`${moduleId}/${number}/${name}`}>
              <b>{name ?? 'Модуль'}</b>
            </Link>
            <Typography variant="body2">(Учебные материалы, тестирование)</Typography>
          </Stack>
        ) : (
          <Stack>
            <Typography variant="body2">{name}</Typography>
            <Typography variant="body2">Для открытия сдайте экзамен в предыдущем модуле</Typography>
          </Stack>
        )}
      </TableCell>
      <TableCell align="center">
        <Phase
          moduleId={moduleId}
          isBlocked={!currentPhase && !isAvailable}
          isPhaseFinished={isPhaseFinished}
          needDiploma={needDiplomaForTests || !isAvailable}
        />
      </TableCell>
      <TableCell align="center">{attemptCount} из 3</TableCell>
    </TableRow>
  );
};

export default Module;
