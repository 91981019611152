import {Box, Stack, Typography} from '@mui/material';

const BonusesSoon = ({notActiveBonuses}) => {
  return (
    <Box sx={{border: '2px solid #D0DDFF', borderRadius: '5px', maxWidth: {sm: '330px'}}}>
      <Stack direction="row" alignItems="center" sx={{p: {sm: '15px 20px', s: '11px 10px'}}} spacing={{sm: 2, s: 1}}>
        <Typography variant="caption">Бонусы, которые будут начислены после оплаты</Typography>
        <Typography variant="h2" color="#89AAFF">
          {notActiveBonuses}
        </Typography>
      </Stack>
    </Box>
  );
};

export default BonusesSoon;
