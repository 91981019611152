import PropTypes from 'prop-types';
import {QUESTION_TYPES} from '../../../constants';

import MultiAnswer from '../MultiAnswer.js';
import {Button, useMediaQuery} from '@mui/material';

const AnswerSelection = ({
  test,
  handleMultiAnswer,
  handleOneAnswer,
  buttonHandler,
  selectedAnswer,
  answers,
  loading,
}) => {
  const isMulti = test?.question?.type === QUESTION_TYPES.severalAnswer;
  const isSmallMobile = useMediaQuery('(max-width:425px)');
  const disabled = selectedAnswer === null || !selectedAnswer.length;

  return (
    <div>
      <MultiAnswer
        isMulti={isMulti}
        options={answers}
        onChange={isMulti ? handleMultiAnswer : handleOneAnswer}
        selectedAnswer={selectedAnswer}
      />
      <Button
        onClick={buttonHandler}
        disabled={disabled}
        variant="contained"
        sx={{paddingX: 12, paddingY: 2, mt: 4, fontSize: 16}}
        fullWidth={isSmallMobile}>
        {loading ? 'Проверка...' : 'Ответить'}
      </Button>
    </div>
  );
};

AnswerSelection.propTypes = {
  test: PropTypes.object,
  handleMultiAnswer: PropTypes.func,
  handleOneAnswer: PropTypes.func,
  buttonHandler: PropTypes.func,
  selectedAnswer: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  answers: PropTypes.array,
  loading: PropTypes.bool,
};

export default AnswerSelection;
