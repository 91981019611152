import {Box, Divider, Grid, Stack, Typography, Skeleton} from '@mui/material';

const CustomerMobileLoading = () => {
  const loadingCustomers = [1, 2];
  return loadingCustomers.map((loader) => {
    return (
      <Box key={loader}>
        <Grid
          display="grid"
          gridTemplateColumns="1fr auto"
          gridTemplateRows={{xs: 'auto auto', s: 'auto'}}
          justifyContent={{xs: 'space-between'}}
          mb={{s: 2}}>
          <Box gridRow={{xs: '1', s: '1'}} mt={{xs: 0, s: 1}}>
            <Stack spacing={0.5}>
              <Typography variant="subtitle2">Заказчик</Typography>
              <Skeleton height={30} width={200} />
            </Stack>
          </Box>
          <Box gridRow={{xs: '2', s: '3'}}>
            <Stack direction="row" spacing={1} mt={{s: 1}}>
              <Typography variant="subtitle2">Дата</Typography>
              <Skeleton height={30} width={150} />
            </Stack>
            <Stack direction="row" spacing={1} mt={{s: 1}}>
              <Typography variant="subtitle2">Договор</Typography>
              <Skeleton height={30} width={150} />
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center" mt={{s: 1}}>
              <Typography variant="subtitle2">Оплата</Typography>
              <Skeleton height={30} width={100} />
              <Skeleton height={30} width={100} />
            </Stack>
          </Box>
          <Box gridRow={{xs: '1', s: '2'}} mt={{xs: 0, s: 1}}>
            <Stack direction={{xs: 'column', s: 'row'}} spacing={{xs: 0, s: 1}}>
              <Typography variant="subtitle2">Статус договора</Typography>
              <Skeleton height={30} width={150} />
            </Stack>
          </Box>
          <Box gridRow={{xs: '2', s: '4'}} mt={{s: 0.5}} alignSelf={{xs: 'end'}}>
            <Stack direction={{xs: 'column', s: 'row'}} alignItems="center" spacing={{xs: 0.5, s: 1}}>
              <Typography variant="subtitle2">Данные по договору</Typography>
              <Skeleton height={30} width={150} />
            </Stack>
          </Box>
        </Grid>
        <Divider color="#57a5ff" />
      </Box>
    );
  });
};
export default CustomerMobileLoading;
