const ImageIcon = () => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1817_458)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.68713 0.5H17.7209L26.2199 9.36679V26.593C26.2199 28.7495 24.4693 30.5 22.3129 30.5H7.68713C5.53068 30.5 3.78015 28.7495 3.78015 26.593V4.40697C3.78015 2.25052 5.53068 0.5 7.68713 0.5V0.5Z"
          fill="#1A9F37"
        />
        <path
          opacity="0.302"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.7083 0.5V9.29068H26.2199L17.7083 0.5Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.8119 15.4937H11.1882C10.6174 15.4937 10.148 15.963 10.148 16.5338V21.3795C10.148 21.9503 10.6173 22.4197 11.1882 22.4197H18.8119C19.3827 22.4197 19.8393 21.9503 19.8393 21.3795V16.5338C19.8393 15.963 19.3827 15.4937 18.8119 15.4937ZM13.2685 16.8002C13.8901 16.8002 14.3848 17.3076 14.3848 17.9165C14.3848 18.538 13.8901 19.0454 13.2685 19.0454C12.647 19.0454 12.1396 18.538 12.1396 17.9165C12.1396 17.3076 12.647 16.8002 13.2685 16.8002ZM19.1544 21.3795C19.1544 21.5698 19.0021 21.7347 18.8119 21.7347H11.1882C10.9979 21.7347 10.8457 21.5698 10.8457 21.3795V21.1765L12.2283 19.7939L13.37 20.9355C13.5095 21.0751 13.7252 21.0751 13.8647 20.9355L16.7315 18.0687L19.1544 20.4916V21.3795Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1817_458">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ImageIcon;
