import {Link} from 'react-router-dom';
import {useParams} from 'react-router';
import PropTypes from 'prop-types';
import {Button, Container, Stack, Typography} from '@mui/material';
import store from '../../../app/store';
import {publicSlice, useGetCompanySettingsQuery} from '../../../api/slices/public';
import {studentSlice} from '../../../api/slices/student';
import useToggle from '../../../hooks/useToggle';

import FileUploader from '../../../components/FileUploader';
import Modal from '../../../components/Modal';

const Phase = ({moduleId, isBlocked, isPhaseFinished, needDiploma}) => {
  const {programId} = useParams();
  const {data: trainingData} = studentSlice.endpoints.getTraining.useQueryState({programId});
  const {data} = useGetCompanySettingsQuery();
  const [isOpen, toggle] = useToggle();

  const showExamResult = () => {
    store.dispatch(studentSlice.endpoints.getExamResult.initiate(moduleId));
  };

  if (isPhaseFinished) {
    return (
      <Stack direction={'row'} gap={1} sx={{justifyContent: 'center', alignItems: 'center'}}>
        <Typography textAlign={'center'} variant="body2" sx={{color: '#4caf50', fontWeight: 'bold'}}>
          Cдан
        </Typography>
        <Typography>
          (
          <Link
            to={`/result/exam/${programId}/${moduleId}`}
            className="text primary pointer"
            replace={true}
            onClick={showExamResult}>
            показать результаты
          </Link>
          )
        </Typography>
      </Stack>
    );
  }

  if (isBlocked) {
    return (
      <Typography textAlign={'center'} variant="body2" sx={{color: '#727272', fontWeight: 'bold'}}>
        Не сдан
      </Typography>
    );
  }

  if (needDiploma) {
    return (
      <>
        <Button variant="contained" onClick={toggle}>
          Загрузить диплом
        </Button>
        <Modal show={isOpen} onClose={toggle}>
          <FileUploader close={toggle} />
        </Modal>
      </>
    );
  }

  if (trainingData?.examAttemptCount === trainingData?.maxExamAttemptCount) {
    return (
      <>
        <Typography onClick={toggle} textAlign={'center'} variant="body2" sx={{color: '#FE0202', fontWeight: 'bold'}}>
          Не сдан
        </Typography>
        <Modal show={isOpen} onClose={toggle}>
          <Container maxWidth={'sm'}>
            Вы исчерпали количество попыток. Обратитесь к администратору по номеру телефона
            <a href={`tel:${data?.phone}`}> {data?.phone}</a>
          </Container>
        </Modal>
      </>
    );
  }

  return (
    <Typography textAlign={'center'} variant="body2" sx={{color: '#727272', fontWeight: 'bold'}}>
      Не сдан
    </Typography>
  );
};

Phase.propTypes = {
  id: PropTypes.string,
  isBlocked: PropTypes.bool,
  isPhaseFinished: PropTypes.bool,
  needDiploma: PropTypes.bool,
};

export default Phase;
