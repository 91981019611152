import {useState} from 'react';
import {useMediaQuery} from '@mui/material';
import {toast} from 'react-toastify';

import CustomerInfo from './info';
import CustomerEditForm from './edit';

import CustomerMobile from '../modules/customerMobile';
import CustomerDesktop from '../modules/customerDesktop';

import Drawer from '../../../../components/drawer';
import useToggle from '../../../../hooks/useToggle';
import {useLoginAsStudentMutation} from '../../../../api/slices/auth';
import {CLIENT_URL} from '../../../../constants';

const Customers = ({contracts = [], contractName, isPayed}) => {
  const [loginAsStudent] = useLoginAsStudentMutation();

  const isDesktop = useMediaQuery('(min-width:768px)');

  const [initialValue, setInitialValue] = useState({});
  const [active, setActive] = useState(null);
  const [editing, toggle] = useToggle();

  const openContract = (index, contract) => () => {
    setInitialValue(contract);
    setActive(++index);
  };

  const closeContract = () => {
    setActive(null);
    if (editing) {
      setTimeout(() => {
        toggle();
      }, 300);
    }
  };

  const login = (courseNumber) => (e) => {
    e.stopPropagation();
    loginAsStudent({login: courseNumber})
      .unwrap()
      .then(({accessToken, accountTypeId}) => {
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('accountId', accountTypeId);
        localStorage.removeItem('loginAsAdmin');
        localStorage.setItem('loginAsStudent', 'true');
        document.location.href = CLIENT_URL;
      })
      .catch((err) => toast.error('Произошла ошибка, повторите попытку позже'));
  };

  return (
    <>
      {isDesktop ? (
        <CustomerDesktop contracts={contracts} loginAsStudent={login} openContract={openContract} />
      ) : (
        contracts.map((contract, index) => (
          <CustomerMobile
            key={contract.id}
            fullName={` ${contract.surname} ${contract.firstname} ${contract.secondname ?? ''}`}
            courseNumber={contract?.courseNumber}
            isExamFinished={contract?.isExamFinished}
            studentTrainings={contract?.studentTrainings}
            loginAsStudent={login}
            openContract={openContract(index, contract)}
          />
        ))
      )}

      <Drawer onClose={closeContract} show={!!active} title={`Слушатели по договору ${contractName}`}>
        {editing ? (
          <CustomerEditForm initialValues={initialValue} onClose={closeContract} back={toggle} />
        ) : (
          <CustomerInfo disabled={isPayed} customer={initialValue} edit={toggle} />
        )}
      </Drawer>
    </>
  );
};

export default Customers;
