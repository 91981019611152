import {string, date, boolean, object} from 'yup';
import {setLocale} from 'yup';
import {BASIS_TYPES, CustomerType} from '../../../constants';
import extractPhone from '../../../utils/extractPhone';

setLocale({
  mixed: {
    default: '',
    required: 'Значение не может быть пустым',
  },
});

export const schema = {
  [CustomerType.individual]: object({
    surname: string().required().trim(),
    name: string().required().trim(),
    secondName: string().nullable().trim().default(''),
    phone: string()
      .required()
      .transform((value) => extractPhone(value)),
    // .min(11, 'введите номер телефона полностью')
    email: string().email().required().trim(),
    passportSerial: string()
      .required()
      // .length(4, 'Серия паспорта не может быть больше или меньше 4 символов')
      // .matches(/^-?\d+\.?\d*$/, 'Серия паспорта может содержать только числа')
      .trim(),
    passportNumber: string()
      .required()
      // .length(6, 'Номер паспорта не может быть больше или меньше 6 символов')
      // .matches(/^-?\d+\.?\d*$/, 'Номер паспорта может содержать только числа')
      .trim(),
    passportIssuedDate: date('Обязательным значением должна быть дата').typeError('Пожалуйста укажите дату').required(),
    passportIssued: string().required().trim(),
  }),
  [CustomerType.entity]: object({
    organizationName: string().required().trim(),
    isBudget: boolean(),
    budgetTypeId: string().when('isBudget', {
      is: true,
      then: string()
        .required()
        .transform((value) => (value?.value ? value?.value : value))
        .typeError('Пожалуйста укажите тип бюджета')
        .default(''),
      otherwise: string().nullable().default(''),
    }),
    personalAccount: string().when('isBudget', {
      is: true,
      then: string()
        .nullable()
        .transform((value) => (!!value ? value : null))
        .length(11, 'Лицевой счет должен быть 11 символов')
        .default(null),
      otherwise: string().nullable(),
    }),
    legalAddress: string().required().trim(),
    inn: string()
      .required()
      .min(10, 'ИНН не может быть меньше 10 символов')
      .max(12, 'ИНН не может быть больше 12 символов')
      .matches(/^-?\d+\.?\d*$/, 'ИНН может содержать только числа')
      .nullable()
      .trim(),
    kpp: string()
      .required()
      .max(9, 'КПП не может быть больше или меньше 9 символов')
      .matches(/^-?\d+\.?\d*$/, 'КПП может содержать только числа')
      .nullable()
      .trim(),
    bank: string().trim().default('').nullable(),
    bik: string()
      .transform((value) => (!!value ? value : null))
      .nullable()
      .max(9, 'БИК должен быть 9 символов')
      .matches(/^-?\d+\.?\d*$/, 'БИК может содержать только числа')
      .trim()
      .default(null),
    rAccount: string()
      // .required()
      .transform((value) => (!!value ? value : null))
      .default(null)
      .max(20, 'Расчетный счет не может превышать 20 символов')
      .matches(/^-?\d+\.?\d*$/, 'Расчетный счет может содержать только числа')
      .nullable()
      .trim(),
    kAccount: string().max(20, 'Корр. счет должен быть 20 символов').nullable().trim(),
    phone: string()
      .nullable()
      .default('')
      // .min(11)
      .transform((value) => extractPhone(value)),
    email: string().email().nullable().trim(),
    directorProfession: string().required().trim(),
    basisTypeId: string()
      .required()
      .transform((value) => (value?.value ? value.value : value)),
    basisNumber: string()
      .trim()
      .when('basisTypeId', {
        is: (value) => value === BASIS_TYPES[0].id,
        then: string().nullable().default(''),
        otherwise: string().required().default(''),
      }),
    basisDate: string().when('basisTypeId', {
      is: (value) => value === BASIS_TYPES[0].id,
      then: string().nullable().default(''),
      otherwise: string().required().default(''),
    }),
    directorSurname: string().required().trim(),
    directorName: string().required().trim(),
    directorSecondName: string().nullable().trim().default(''),
    postAddress: string().required().trim(),
  }),
  [CustomerType.individualEntrepreneur]: object({
    organizationName: string().required().trim(),
    inn: string()
      .required()
      .min(10, 'ИНН должен быть минимум 10 символов')
      .max(12, 'ИНН не должен превышать 12 символов')
      .matches(/^-?\d+\.?\d*$/, 'ИНН может содержать только числа')
      .nullable()
      .trim(),
    ogrn: string()
      .default(null)
      .nullable()
      .transform((value) => (!!value ? value : null))
      .min(13, 'ОГРН не должен быть меньше 13 символов')
      .max(15, 'ОГРН не должен быть больше 15 символов')
      .matches(/^-?\d+\.?\d*$/, 'ОГРН может содержать только числа')
      .trim(),
    bank: string().nullable().trim().default(''),
    bik: string()
      .transform((value) => (!!value ? value : null))
      .nullable()
      .max(9, 'БИК должен быть 9 символов')
      .matches(/^-?\d+\.?\d*$/, 'БИК может содержать только числа')
      .trim()
      .default(null),
    rAccount: string()
      .max(20, 'Расчетный счет не может превышать 20 символов')
      .matches(/^-?\d+\.?\d*$/, 'Расчетный счет может содержать только числа')
      .nullable()
      .trim(),
    kAccount: string().length(20, 'Корр. счет должен быть 20 символов').nullable().trim(),
    phone: string().transform((value) => extractPhone(value)),
    email: string().email().nullable().trim(),
    directorSurname: string().required().trim(),
    directorName: string().required().trim(),
    directorSecondName: string().trim().nullable(),
    postAddress: string().required().trim(),
  }),
};
