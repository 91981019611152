import {Fragment} from 'react';
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import '../styles.scss';

import ExamStatus from './examStatus';

const CustomerDesktop = ({contracts, loginAsStudent, openContract}) => {
  return (
    <Box className="listeners">
      <TableContainer>
        <Table size="medium">
          <TableHead>
            <TableRow>
              <TableCell sx={{border: 'unset'}}>ФИО</TableCell>
              <TableCell align="center" sx={{border: 'unset'}}>
                № курса
              </TableCell>
              <TableCell align="center" sx={{border: 'unset'}}>
                Экзамен
              </TableCell>
              <TableCell align="center" sx={{border: 'unset'}} />
            </TableRow>
          </TableHead>
          <TableBody>
            {contracts.map(({id, firstname, secondname, surname, courseNumber, studentTrainings}, index) => {
              const isAllExamsFinished = studentTrainings.map(({isExamFinished}) => isExamFinished).includes(false);
              return (
                <Fragment key={id}>
                  <TableRow>
                    <TableCell sx={{borderTop: '1px solid #0249FE', borderBottom: 'unset'}}>
                      <Stack>
                        <Typography
                          variant="h5"
                          className="link"
                          sx={{cursor: 'pointer'}}
                          onClick={openContract(index, contracts[index])}>
                          {surname} {firstname} {secondname}
                        </Typography>
                        <Typography variant="subtitle2">Программы обучения</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell align="center" sx={{borderTop: '1px solid #0249FE', borderBottom: 'unset'}}>
                      <Typography variant="h5">{courseNumber}</Typography>
                    </TableCell>
                    <TableCell align="center" sx={{borderTop: '1px solid #0249FE', borderBottom: 'unset'}}>
                      <Stack alignItems="center">
                        <ExamStatus isExamFinished={!isAllExamsFinished} />
                      </Stack>
                    </TableCell>
                    <TableCell align="center" sx={{borderTop: '1px solid #0249FE', borderBottom: 'unset'}}>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{whiteSpace: 'nowrap'}}
                        endIcon={<KeyboardArrowRightIcon />}
                        onClick={loginAsStudent(courseNumber)}>
                        К обучению
                      </Button>
                    </TableCell>
                  </TableRow>
                  {studentTrainings.map(({id, trainingProgramText, isExamFinished}) => {
                    return (
                      <TableRow key={id}>
                        <TableCell sx={{border: 'unset'}}>
                          <Typography variant="h5">{trainingProgramText}</Typography>
                        </TableCell>
                        <TableCell sx={{border: 'unset'}} />
                        <TableCell align="center" sx={{border: 'unset'}}>
                          <Stack alignItems="center">
                            <ExamStatus isExamFinished={isExamFinished} />
                          </Stack>
                        </TableCell>
                        <TableCell sx={{border: 'unset'}} />
                      </TableRow>
                    );
                  })}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CustomerDesktop;
