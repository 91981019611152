import {useFieldArray} from 'react-hook-form';
import {Box, Button, Typography} from '@mui/material';

import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import {defaultProgram} from '../../../../../constants';
import tipText from '../../../../../utils/tipText';
import StudentCardProgramsTitle from './StudentCardProgramsTitle';
import StudentCardProgramsController from './studentCardProgramsController';

const StudentCardEducationPrograms = ({removeStudent}) => {
  const {fields, append, remove} = useFieldArray({name: 'programs'});

  const removeProgram = (index) => () => {
    remove(index);
  };

  const addProgram = (program) => () => append(program);

  const programsTips = ['программу', 'программы', 'программ'];

  return (
    <Box width={{sm: '55%'}}>
      {fields.map((field, index) => (
        <Box key={field.id}>
          <StudentCardProgramsTitle
            educationProgram={index + 1}
            educationProgramQuantity={fields.length}
            removeProgram={removeProgram(index)}
            removeStudent={removeStudent}
          />
          <StudentCardProgramsController programIndex={index} />
        </Box>
      ))}
      {fields.length < 5 ? (
        <>
          <Button
            startIcon={<AddCircleRoundedIcon fontSize="20px" sx={{color: '#0249FE'}} />}
            onClick={addProgram(defaultProgram)}>
            <Typography variant="caption" color="#0249FE" fontWeight={500}>
              Добавить программу обучения
            </Typography>
          </Button>
          <Typography variant="caption" display="block" mb={{lg: 3.5, md: 2.5, s: 2}}>
            Можно добавить еще {tipText(5 - fields.length, programsTips)}
          </Typography>
        </>
      ) : (
        <Typography variant="caption" display="block" mb={{lg: 3.5, md: 2.5, s: 2}}>
          Добавлено максимальное количество программ
        </Typography>
      )}
    </Box>
  );
};

export default StudentCardEducationPrograms;
