import {array, object, setLocale, string, boolean} from 'yup';
import tipText from '../../../utils/tipText';
import {DeliveryType} from '../../../constants';

const symbolTytles = ['символ', 'символа', 'символов'];

setLocale({
  mixed: {
    default: '',
    required: 'Это обязательное поле',
  },
  string: {
    email: 'Неверный формат',
    min: ({min}) => `минимум ${tipText(min, symbolTytles)}`,
  },
});

export const schema = object({
  students: array().of(
    object({
      surname: string().required().trim(),
      firstname: string().required().trim(),
      secondname: string().nullable().trim(),
      jobPlace: string().required().min(3).trim(),
      profession: string().required().min(3).trim(),
      programs: array().of(
        object({
          trainingProgramTypeId: string()
            .required('Выберите тип программы обучения')
            .transform((val) => val?.value),
          trainingProgramId: string()
            .required('Выберите программу обучения')
            .transform((val) => val?.value),
        })
      ),
    })
  ),
  customerId: string().required(),
  deliveryTypeId: string().required(),
  promocode: string().nullable().trim(),
  wishes: string().nullable().trim(),
  postAddress: string().when('deliveryTypeId', {
    is: (val) => val === DeliveryType.withoutDelivery,
    then: string().nullable().default(null),
    otherwise: string().required(),
  }),
  deliveryRegionId: string().nullable().trim(),
  isWriteOffBonuses: boolean().default(false),
});
