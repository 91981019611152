import { defaultProgram } from "./defaultProgram";

export const defaultStudent = {
  surname: "",
  firstname: "",
  secondname: "",
  jobPlace: "",
  profession: "",
  programs: [defaultProgram],
};
