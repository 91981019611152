import {useState} from 'react';
import {Button, Stack} from '@mui/material';
import {useWatch} from 'react-hook-form';
import ControlledTextField from '../../../../../components/FieldRegister/NestedTextField';

const Promocode = ({getContractCost}) => {
  const [showPromocodeForm, setShowPromocodeForm] = useState(false);

  const isWriteOffBonuses = useWatch({name: 'isWriteOffBonuses'});
  return (
    <Stack sx={{width: {sm: '49%'}}} spacing={{sm: 2, s: 1}}>
      <Button variant="contained" color="secondary" onClick={() => setShowPromocodeForm((state) => !state)}>
        У меня есть промокод
      </Button>
      {showPromocodeForm ? (
        <>
          <ControlledTextField name="promocode" label="Промокод" />
          <Button variant="contained" onClick={() => getContractCost(null, isWriteOffBonuses)}>
            Применить
          </Button>
        </>
      ) : null}
    </Stack>
  );
};

export default Promocode;
