import {useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {CSSTransition} from 'react-transition-group';
import {Box, Button, Skeleton, Stack, useMediaQuery} from '@mui/material';

import PageTitle from '../../components/Title/PageTitle';
import {
  useGetLectureListQuery,
  useGetPresentationListQuery,
  useGetTrainingQuery,
  useGetVideoListQuery,
} from '../../api/slices/student';
import {TRAININGS} from '../../constants';

import Lecture from './Trainings/Lecture';
import Presentation from './Trainings/Presentation';
import Video from './Trainings/Video';

import TrainingTypeRadioGroup from './modules/trainingTypeRadioGroup';
import LectureMaterial from './modules/lectureMaterial';
import PresentationMaterial from './modules/presentationMaterial';
import VideoMaterial from './modules/videoMaterial';

import './styles.scss';
import {Link} from 'react-router-dom';
import MUIModal from '../../components/MUIModal';
import ExamAttention from '../../features/Overlays/ExamAttention';
import useToggle from '../../hooks/useToggle';
import EisotTestAttention from '../../features/Overlays/EisotTextAttention';
import EisotExamAttention from '../../features/Overlays/EisotExamAttention';
import TestAttention from '../../features/Overlays/TestAttention';

const Materials = () => {
  const {programId, moduleId, moduleNumber, moduleName} = useParams();
  const {data: trainings, isLoading} = useGetTrainingQuery({programId});

  const {data: lectures, isLoading: isLecturesLoading} = useGetLectureListQuery(programId);
  const {data: presentations, isLoading: isPresentationsLoading} = useGetPresentationListQuery(programId);
  const {data: videos, isLoading: isVideosLoading} = useGetVideoListQuery(programId);
  const [activeTraining, setActiveTraining] = useState(TRAININGS.LECTURE);
  const [currentMaterial, setCurrentMaterial] = useState(null);
  const navigate = useNavigate();

  const [isExamOpen, toggle] = useToggle();
  const [isTestOpen, toggleTest] = useToggle();

  const isDesktop = useMediaQuery('(min-width:768px)');

  const openMaterial = (id) => setCurrentMaterial(currentMaterial === id ? null : id);

  const currentPhaseMaterials = (arr) =>
    arr ? arr.filter((elem) => elem.phaseNumbers.includes(Number(moduleNumber))) : [];

  const isPhaseFinished = () => {
    if (!trainings) return;
    const phase = trainings?.phases?.filter((phase) => phase?.id === moduleId)[0];
    if (!phase) return false;
    else return phase.isPhaseFinished || false;
  };

  const getPhaseName = () => {
    if (!trainings) return 'Модуль';
    const phase = trainings?.phases?.filter((phase) => phase?.id === moduleId)[0];
    if (!phase) return 'Модуль';
    else return phase.name ?? 'Модуль';
  };

  const isNeedExam = () => {
    if (!trainings) return true;
    const phase = trainings?.phases?.filter((phase) => phase?.id === moduleId)[0];
    if (!phase) return true;
    else return phase.needExam ?? true;
  };

  const isEisot = trainings?.trainingProgram?.trainingProgramType?.isEisot;

  const getExamButtonTitle = () => {
    if (isPhaseFinished()) {
      return 'Экзамен сдан';
    } else {
      if (isEisot) {
        return 'Внутренний экзамен';
      } else return 'Экзамен';
    }
  };

  const navigateToProgram = () => {
    if (programId) {
      navigate(`/program/${programId}`);
    } else {
      navigate('/');
    }
  };

  // const navigateToTest = () => {
  //   navigate(`/test/${programId}/${moduleId}`);
  // };

  return (
    <Box>
      <PageTitle onBackButtonClick={navigateToProgram}>{getPhaseName()}</PageTitle>
      <Box className="education-materials">
        <Stack direction="row" spacing={2}>
          <Box sx={{flex: {sm: '0 1 33%', s: '0 1 100%'}}}>
            {isLoading
              ? null
              : isNeedExam() && (
                  <Stack direction="row" spacing={2} justifyContent="space-between" mb={2}>
                    <Button onClick={toggleTest} variant="contained" sx={{textAlign: 'center'}} fullWidth>
                      Пробный тест
                    </Button>
                    <Button disabled={isPhaseFinished()} variant="contained" fullWidth onClick={toggle}>
                      {getExamButtonTitle()}
                    </Button>
                  </Stack>
                )}
            <TrainingTypeRadioGroup
              isLectureExist={lectures?.data.length}
              isPresentationExist={presentations?.data.length}
              isVideoExist={videos?.data.length}
              isLecturesLoading={isLecturesLoading}
              isPresentationsLoading={isPresentationsLoading}
              isVideosLoading={isVideosLoading}
              activeTraining={activeTraining}
              setActiveTraining={setActiveTraining}
            />
            <CSSTransition in={activeTraining === TRAININGS.LECTURE} timeout={300} unmountOnExit classNames="training">
              {lectures?.data.length ? (
                <>
                  {currentPhaseMaterials(lectures?.data).map((lecture) => (
                    <Lecture
                      key={lecture.id}
                      name={lecture.eduName}
                      programId={programId}
                      lectureId={lecture.id}
                      educationType={lecture.educationMaterialTypeId}
                      isActive={currentMaterial === lecture.id}
                      isDesktop={isDesktop}
                      setActive={openMaterial}
                    />
                  ))}
                </>
              ) : (
                <>
                  <Skeleton height={40} width="100%" />
                  <Skeleton height={40} width="100%" />
                  <Skeleton height={40} width="100%" />
                  <Skeleton height={40} width="100%" />
                </>
              )}
            </CSSTransition>
            <CSSTransition
              in={activeTraining === TRAININGS.PRESENTATION}
              timeout={300}
              unmountOnExit
              classNames="training">
              {presentations?.data.length ? (
                <>
                  {currentPhaseMaterials(presentations?.data, module.number).map((presentation) => (
                    <Presentation
                      key={presentation.id}
                      name={presentation.eduName}
                      programId={programId}
                      presentationId={presentation.id}
                      educationType={presentation.educationMaterialTypeId}
                      isActive={currentMaterial === presentation.id}
                      isDesktop={isDesktop}
                      setActive={openMaterial}
                    />
                  ))}
                </>
              ) : (
                <>
                  <Skeleton height={40} width="100%" />
                  <Skeleton height={40} width="100%" />
                  <Skeleton height={40} width="100%" />
                  <Skeleton height={40} width="100%" />
                </>
              )}
            </CSSTransition>
            <CSSTransition in={activeTraining === TRAININGS.VIDEO} timeout={300} unmountOnExit classNames="training">
              {videos?.data.length ? (
                <>
                  {currentPhaseMaterials(videos?.data, module.number).map((video) => (
                    <Video
                      key={video.id}
                      name={video.eduName}
                      programId={programId}
                      videoId={video.id}
                      educationType={video.educationMaterialTypeId}
                      isActive={currentMaterial === video.id}
                      isDesktop={isDesktop}
                      setActive={openMaterial}
                    />
                  ))}
                </>
              ) : (
                <>
                  <Skeleton height={40} width="100%" />
                  <Skeleton height={40} width="100%" />
                  <Skeleton height={40} width="100%" />
                  <Skeleton height={40} width="100%" />
                </>
              )}
            </CSSTransition>
          </Box>
          {isDesktop && (
            <Box sx={{flex: '0 1 66%'}}>
              {activeTraining === TRAININGS.LECTURE && (
                <LectureMaterial programId={programId} lectureId={currentMaterial} />
              )}
              {activeTraining === TRAININGS.PRESENTATION && (
                <PresentationMaterial programId={programId} presentationId={currentMaterial} />
              )}
              {activeTraining === TRAININGS.VIDEO && <VideoMaterial programId={programId} videoId={currentMaterial} />}
            </Box>
          )}
        </Stack>
      </Box>

      <MUIModal isOpen={isExamOpen} toggle={toggle}>
        {isEisot ? <EisotExamAttention /> : <ExamAttention />}
      </MUIModal>
      <MUIModal isOpen={isTestOpen} toggle={toggleTest}>
        {isEisot ? <EisotTestAttention close={toggleTest} /> : <TestAttention close={toggleTest} />}
      </MUIModal>
    </Box>
  );
};

export default Materials;
