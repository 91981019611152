import {boolean, date, object, string} from 'yup';
import {BASIS_TYPES, CustomerType} from '../../../../constants';
import extractPhone from '../../../../utils/extractPhone';

export const schema = {
  [CustomerType.individual]: object({
    customerTypeId: string().required(),
    name: string().required().trim(),
    surname: string().required().trim(),
    secondName: string().nullable().trim(),
    phone: string()
      .required()
      .min(11, 'введите номер телефона полностью')
      .transform((value) => extractPhone(value)),
    email: string().email().required().trim(),
    legalAddress: string().required().trim(),
    postAddress: string().required().trim(),
    passportSerial: string()
      .required()
      .length(4, 'серия должна быть 4 символа')
      .matches(/^-?\d+\.?\d*$/, 'Серия паспорта может содержать только числа')
      .trim(),
    passportNumber: string()
      .required()
      .length(6, 'номер должен быть 6 символов')
      .matches(/^-?\d+\.?\d*$/, 'Серия паспорта может содержать только числа')
      .trim(),
    passportIssued: string().required().trim(),
    passportIssuedDate: date().typeError('Пожалуйста укажите дату').required('Пожалуйста укажите дату'),
  }),
  [CustomerType.entity]: object({
    customerTypeId: string().required(),
    directorName: string().required().trim(),
    directorSurname: string().required().trim(),
    directorSecondName: string().trim(),
    legalAddress: string().required().trim(),
    postAddress: string().required().trim(),
    inn: string()
      .required()
      .min(10, 'ИНН должен быть минимум 10 символов')
      .max(12, 'ИНН не должен превышать 12 символов')
      .matches(/^-?\d+\.?\d*$/, 'ИНН может содержать только числа')
      .trim(),
    kpp: string()
      .required()
      .length(9, 'КПП должен быть 9 символов')
      .matches(/^-?\d+\.?\d*$/, 'ИНН может содержать только числа')
      .trim(),
    bank: string().nullable().trim().default(''),
    bik: string()
      .transform((value) => (!!value ? value : null))
      .nullable()
      .max(9, 'БИК должен быть 9 символов')
      .matches(/^-?\d+\.?\d*$/, 'БИК может содержать только числа')
      .trim()
      .default(null),
    kAccount: string().trim().nullable(),
    rAccount: string().trim().nullable(),
    phone: string()
      .nullable()
      .min(11, 'введите номер телефона полностью')
      .transform((value) => extractPhone(value)),
    email: string().email().nullable().trim(),
    isBudget: boolean().default(false),
    budgetTypeId: string().when('isBudget', {
      is: true,
      then: string().required().typeError('Пожалуйста укажите тип бюджета'),
      otherwise: string().nullable(),
    }),
    personalAccount: string().nullable(),
    departmentTitle: string().nullable(),
    treasuryAccount: string().nullable(),
    mainAccount: string().nullable(),
    directorProfession: string().required().trim(),
    basisTypeId: string().required(),
    basisNumber: string()
      .trim()
      .when('basisTypeId', {
        is: (value) => value === BASIS_TYPES[0].id,
        then: string().nullable(),
        otherwise: string().required(),
      }),
    basisDate: string().when('basisTypeId', {
      is: (value) => value === BASIS_TYPES[0].id,
      then: string().nullable(),
      otherwise: string().required(),
    }),
    organizationName: string().required().trim(),
  }),
  [CustomerType.individualEntrepreneur]: object({
    customerTypeId: string().required(),
    directorName: string().required().trim(),
    directorSurname: string().required().trim(),
    directorSecondName: string().nullable().trim(),
    postAddress: string().required().trim(),
    legalAddress: string().required().trim(),
    phone: string()
      .nullable()
      .min(11, 'введите номер телефона полностью')
      .transform((value) => extractPhone(value)),
    email: string().email().nullable().trim(),
    inn: string()
      .required()
      .min(10, 'ИНН должен быть минимум 10 символов')
      .max(12, 'ИНН не должен превышать 12 символов')
      .matches(/^-?\d+\.?\d*$/, 'ИНН может содержать только числа')
      .trim(),
    ogrn: string()
      .default(null)
      .nullable()
      .transform((value) => (!!value ? value : null))
      .min(13, 'ОГРН не должен быть меньше 13 символов')
      .max(15, 'ОГРН не должен быть больше 15 символов')
      .matches(/^-?\d+\.?\d*$/, 'ОГРН может содержать только числа')
      .trim(),
    organizationName: string().required().trim(),
    bank: string().nullable().trim().default(''),
    bik: string()
      .transform((value) => (!!value ? value : null))
      .nullable()
      .max(9, 'БИК должен быть 9 символов')
      .matches(/^-?\d+\.?\d*$/, 'БИК может содержать только числа')
      .trim()
      .default(null),
    rAccount: string()
      .nullable()
      .max(20, 'Расч. счет должен быть 20 символов')
      // .matches(/^-?\d+\.?\d*$/, 'Расчетный счет может содержать только числа')
      .trim(),
    kAccount: string()
      .nullable()
      .max(20, 'Корр. счет должен быть 20 символов')
      // .matches(/^-?\d+\.?\d*$/, 'Корр. счет может содержать только числа')
      .trim(),
  }),
};
