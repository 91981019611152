import {Stack, Typography} from '@mui/material';
import {useForm, useWatch} from 'react-hook-form';
import ControlledDatePicker from '../../components/FieldRegister/DatePicker';
import ControlledTextField from '../../components/FieldRegister/NestedTextField';
import {useLazySendEisotAttemptQuery} from '../../api/slices/student';
import {LoadingButton} from '@mui/lab';
import {useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {date, object} from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import ControlledTimepicker from '../../components/FieldRegister/ControlledTimepicker';
import ru from 'dayjs/locale/ru';

const schema = object().shape({
  date: date().typeError('Неправильный формат времени').required('Значение не может быть пустым'),
  time: date()
    .typeError('Неправильный формат времени')
    .test('time', 'Неправильный формат времени', (val) => {
      if (!val) return true;
      if (!dayjs(val).isValid()) return false;
      else return true;
    })
    .required('Значение не может быть пустым'),
});

export const EisotAttempt = ({close = () => {}, program, trainingId}) => {
  const {
    control,
    formState: {errors},
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {time: dayjs().set('hour', 15).set('minute', 0)},
    reValidateMode: 'onBlur',
  });

  const [sendEisotInfo, {isLoading, isSuccess, isError, error}] = useLazySendEisotAttemptQuery();

  useEffect(() => {
    if (isSuccess === true) {
      setTimeout(() => {
        close();
      }, 1500);
    }
  }, [isSuccess]);

  const handleSubmitButton = (data) => {
    dayjs.locale(ru);
    const formatedDate = dayjs(data.date).format('DD.MM.YYYY');
    const formatedTime = dayjs(data.time);

    const eisotDate = dayjs(formatedDate, 'DD.MM.YYYY')
      .add(formatedTime.hour(), 'hour')
      .add(formatedTime.minute(), 'minute');

    const stringDate = eisotDate.format('YYYY-MM-DD HH:mm');

    const sendData = {
      studentTrainingId: trainingId,
      eisotNote: data.eisotNote,
      eisotWishfulAttemptDateTime: stringDate,
    };
    sendEisotInfo(sendData);
  };

  return (
    <Stack gap={1.5}>
      <Typography variant="body1" textAlign={'center'}>
        Вы успешно сдали внутренний экзамен по программе {program?.trainingProgram?.name}
      </Typography>
      <Typography variant="body1" textAlign={'center'}>
        Теперь Вам необходимо пройти <span style={{fontWeight: 'bold'}}>проверку знания на сайте Минтруда в ЕИСОТ</span>
      </Typography>
      <Typography variant="h3" textAlign={'center'} sx={{fontSize: 18}}>
        Выберите желаемую дату и время для Вашей попытки тестирования
      </Typography>
      <Typography variant="body1" textAlign={'center'}>
        И в случае, если ваш договор на обучение полностью оплачен, мы зарегистрируем попытку и вышлем данные для входа
        на Вашу электронную почту
      </Typography>
      <Typography variant="body1" color="primary" textAlign={'center'}>
        График назначения попыток
      </Typography>
      <Stack direction={{s: 'column', sm: 'row'}} alignItems={'center'} justifyContent="space-around">
        <Typography variant="body1" sx={{textTransform: 'uppercase', fontWeight: 'bold'}}>
          пн‒пт 6:00–20:00 (мск)
        </Typography>
        <Typography variant="body1" sx={{textTransform: 'uppercase', fontWeight: 'bold'}}>
          СБ 9:00–16:00 (мск)
        </Typography>
        <Typography variant="body1" sx={{textTransform: 'uppercase', fontWeight: 'bold'}}>
          ВС — выходной
        </Typography>
      </Stack>
      <Typography variant="subtitle2" textAlign="center">
        Дата и время может быть изменено сотрудниками УЦ при необходимости
      </Typography>
      <Stack direction={{s: 'column', sm: 'row'}} gap={1.2}>
        <ControlledDatePicker
          name="date"
          control={control}
          errors={errors}
          label="Дата попытки"
          size="medium"
          sx={{width: '100%'}}
          fullWidth
        />
        <ControlledTimepicker
          control={control}
          errors={errors}
          name="time"
          label="Время попытки"
          size="medium"
          sx={{width: '100%'}}
          minTime={dayjs().set('hour', 6)}
          maxTime={dayjs().set('hour', 20)}
          fullWidth
        />
      </Stack>
      <ControlledTextField
        control={control}
        errors={errors}
        name="eisotNote"
        label="Комментарий (не обязательно)"
        size="medium"
        InputProps={{
          rows: 4,
          multiline: true,
          inputComponent: 'textarea',
        }}
        sx={{width: '100%'}}
        fullWidth
      />
      <Typography variant="subtitle2" textAlign="center">
        Запрос будет обработан в течение 1 рабочего дня
      </Typography>
      <LoadingButton
        loading={isLoading}
        disabled={isLoading}
        variant="contained"
        onClick={handleSubmit(handleSubmitButton)}
        color={isSuccess ? 'success' : 'primary'}
        size="large"
        sx={{height: 60}}
        fullWidth>
        {isSuccess ? 'Запрос успешно отправлен' : 'Отправить запрос на желаемое время попытки в ЕИСОТ'}
      </LoadingButton>

      {isError && (
        <Typography textAlign={'center'} color="red">
          {error?.data?.message}
        </Typography>
      )}
    </Stack>
  );
};
