import {Box, Stack, Typography} from '@mui/material';

import Item from '../Item';
import CustomerInformationBlock from './customerInformationBlock';
// import BonusesInfo from '../BonusesInfo';

import ControlledTextField from '../../../../components/FieldRegister/NestedTextField';
import ControlledMaskedTextField from '../../../../components/FieldRegister/MaskedTextField';
import ControlledAutocomplete from '../../../../components/FieldRegister/nestedAutocomplete';
import ControlledDatePicker from '../../../../components/FieldRegister/DatePicker';

const IndividualForm = ({initialValues, passportIssuedDate, control, errors, isEditing}) => {
  return (
    <Box>
      <Stack direction="row" alignItems="center" spacing={{s: 4.5}} mb={{lg: 3.5, sm: 2.5, s: 2}}>
        <Typography variant="body2">Тип заказчика</Typography>
        <Typography variant="body1">Физическое лицо</Typography>
      </Stack>
      <CustomerInformationBlock name="Основные сведения">
        <Stack spacing={{sm: 3.5, s: 2}}>
          <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
            <Item
              label="Фамилия"
              value={initialValues.surname}
              editable={isEditing}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <ControlledTextField control={control} errors={errors} name="surname" fullWidth />
            </Item>
            <Item
              label="Имя"
              value={initialValues.name}
              editable={isEditing}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <ControlledTextField control={control} errors={errors} name="name" fullWidth />
            </Item>
          </Stack>
          <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
            <Item
              label="Отчество"
              value={initialValues.secondName}
              editable={isEditing}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <ControlledTextField control={control} errors={errors} name="secondName" fullWidth />
            </Item>
            <Item
              label="Телефон"
              value={initialValues.phone}
              editable={isEditing}
              alignItems="center"
              justifyContent={{sm: 'space-between'}}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <ControlledTextField
                control={control}
                errors={errors}
                name="phone"
                // format="+7-(###)-###-####"
                // mask="_"
                fullWidth
              />
            </Item>
          </Stack>
          <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
            <Item
              label="Email"
              value={initialValues.email}
              editable={isEditing}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <ControlledAutocomplete control={control} errors={errors} name="email" type="email" />
            </Item>
            {/*<BonusesInfo />*/}
          </Stack>
          <Item label="Адрес" value={initialValues.legalAddress} editable={isEditing}>
            <ControlledAutocomplete control={control} errors={errors} name="legalAddress" type="address" />
          </Item>
          <Item label="Адрес доставки" value={initialValues.postAddress} editable={isEditing}>
            <ControlledAutocomplete control={control} errors={errors} name="postAddress" type="address" />
          </Item>
        </Stack>
      </CustomerInformationBlock>
      <CustomerInformationBlock name="Паспортные данные">
        <Stack spacing={{sm: 3.5, s: 2}}>
          <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
            <Item
              label="Серия паспорта"
              value={initialValues.passportSerial}
              editable={isEditing}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <ControlledTextField
                control={control}
                errors={errors}
                name="passportSerial"
                // format="####"
                fullWidth
              />
            </Item>
            <Item
              label="Номер паспорта"
              value={initialValues.passportNumber}
              editable={isEditing}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <ControlledTextField
                control={control}
                errors={errors}
                name="passportNumber"
                // format="######"
                fullWidth
              />
            </Item>
          </Stack>
          <Stack direction={{sm: 'row', s: 'column'}} alignItems={{sm: 'center', s: 'flex-start'}} spacing={{s: 2}}>
            <Item
              label="Выдан"
              value={initialValues.passportIssued}
              editable={isEditing}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <ControlledAutocomplete control={control} errors={errors} name="passportIssued" type="fms_unit" />
            </Item>
            <Item
              label="Дата выдачи"
              value={passportIssuedDate}
              editable={isEditing}
              isDate={true}
              itemProps={{width: {sm: '50%', s: '100%'}}}>
              <ControlledDatePicker control={control} errors={errors} name="passportIssuedDate" sx={{width: '100%'}} />
            </Item>
          </Stack>
        </Stack>
      </CustomerInformationBlock>
    </Box>
  );
};

export default IndividualForm;
