import {useParams} from 'react-router';
import {Box, Divider, Skeleton, Stack, Typography} from '@mui/material';
import {getDocumentList} from '../../../../api/slices/user';

import Document from './Document';

const Documents = () => {
  const {id} = useParams();
  const {data, isLoading} = getDocumentList.useQueryState(id);

  return isLoading ? (
    <Box>
      <Typography variant="h2">
        <Skeleton variant="rectangular" width={210} height={40} />
      </Typography>
      <Stack spacing={1}>
        <Skeleton variant="text" sx={{fontSize: '1rem'}} />
        <Skeleton variant="text" sx={{fontSize: '1rem'}} />
        <Skeleton variant="text" sx={{fontSize: '1rem'}} />
        <Skeleton variant="text" sx={{fontSize: '1rem'}} />
      </Stack>
    </Box>
  ) : (
    <Box sx={{minWidth: 280}}>
      <Typography variant="h2" mb={2}>
        Документы
      </Typography>
      {data?.documents.map((document) => (
        <Box key={document.documentTypeId}>
          {document.showPDF || document.showDOCX ? <Divider color="#89AAFF" sx={{mb: 1, mt: 1}} /> : null}
          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={{sm: 4, s: 2}}>
            {document.showPDF || document.showDOCX ? <Typography variant="body1">{document.name}</Typography> : null}
            <Stack direction="column">
              {document.showPDF && (
                <Document
                  format="pdf"
                  name={`${document.name} ${data?.contractNumber}`}
                  documentTypeId={document.documentTypeId}>
                  Скачать PDF
                </Document>
              )}
              {document.showDOCX && (
                <Document
                  format="docx"
                  name={`${document.name} ${data?.contractNumber}`}
                  documentTypeId={document.documentTypeId}>
                  Скачать docx
                </Document>
              )}
            </Stack>
          </Stack>
        </Box>
      ))}
    </Box>
  );
};

export default Documents;
