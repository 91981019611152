import React from 'react';
import PropTypes, {array} from 'prop-types';
import classNames from 'classnames/bind';

import classes from './styles.module.scss';
import {Stack, Typography} from '@mui/material';

let cx = classNames.bind(classes);

const MultiAnswer = ({
  options = [],
  onChange,
  selectedAnswer,
  studentAnswers = [],
  rightAnswers = [],
  disabled,
  isMulti,
  review = false,
}) => {
  const getIsSelectedAnswer = (id) => {
    if (Array.isArray(selectedAnswer)) {
      return selectedAnswer.includes(id);
    } else {
      return id === selectedAnswer;
    }
  };

  return (
    <Stack gap={1.5}>
      {options.map((option, index) => {
        const isRightAnswer = rightAnswers.includes(option.id);
        const isStudentAnswers = studentAnswers.find((answer) => answer?.id === option.id);
        return (
          <Answer
            key={index}
            id={option.id}
            text={option.text}
            onChange={onChange}
            disabled={disabled}
            isMulti={isMulti}
            review={review}
            isSelected={getIsSelectedAnswer(option.id)}
            isRightAnswer={isRightAnswer}
            isStudentAnswers={isStudentAnswers}
          />
        );
      })}
    </Stack>
  );
};

const Answer = ({id, text, disabled, onChange, isSelected, isMulti, review, isRightAnswer, isStudentAnswers}) => {
  const elemText = new DOMParser().parseFromString(text, 'text/html');
  const img = elemText.getElementsByTagName('img');
  const Image = () =>
    React.createElement(img[0].tagName.toLowerCase(), {
      src: img[0].src,
      style: {maxHeight: '150px', maxWidth: '150px'},
    });

  const label = img.length ? <Image /> : elemText.documentElement.textContent;

  const handleAnswerClick = (id) => {
    if (review) {
      return;
    }
    onChange(id);
  };

  const getBackgroundColor = () => {
    if (!isMulti && isSelected) return '#89AAFF';
    if (isRightAnswer) return '#BBFDAB';
    if (!isRightAnswer && isStudentAnswers) return '#FFD2D2';
    if (isRightAnswer && !isStudentAnswers) return '#fff5db';
  };

  return (
    <Stack
      className={classes.container}
      sx={{backgroundColor: getBackgroundColor(), cursor: review ? 'auto' : 'pointer'}}
      onClick={() => handleAnswerClick(id)}>
      {isStudentAnswers && <Typography variant="subtitle2">Ваш ответ {isRightAnswer && '/ Верный ответ'}</Typography>}
      {!isStudentAnswers && isRightAnswer && <Typography variant="subtitle2">Верный ответ</Typography>}
      {isMulti && (
        <input
          disabled={true}
          name={isMulti ? text : 'answerSelection'}
          id={id}
          type={'checkbox'}
          value={id}
          checked={isSelected}
          className={
            !review
              ? cx({
                  input: true,
                  checked: isSelected,
                })
              : cx({
                  input: true,
                  right: isRightAnswer,
                  wrong: !isRightAnswer && isStudentAnswers,
                  shouldRight: isRightAnswer && !isStudentAnswers,
                })
          }
        />
      )}
      <label
        htmlFor={id}
        className={isMulti ? classes.label : classes.single_input}
        style={{cursor: review ? 'auto' : 'pointer'}}>
        {label}
      </label>
    </Stack>
  );
};
MultiAnswer.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  studentAnswers: PropTypes.array,
  rightAnswers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
};

export default MultiAnswer;
