import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

import StudentDesktopBody from './studentDesktobBody';

const StudentDesktop = ({trainings}) => {
  return (
    <TableContainer>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell sx={{border: 'unset'}}>Программа</TableCell>
            <TableCell sx={{border: 'unset'}}>Прогресс</TableCell>
            <TableCell align="center" sx={{border: 'unset'}} />
          </TableRow>
        </TableHead>
        <TableBody>
          {trainings.map((training) => {
            return (
              <StudentDesktopBody
                key={training.trainingId}
                id={training.trainingId}
                trainingName={training.trainingName}
                trainingFullName={training.trainingTypeFullName}
                trainingText={training.trainingText}
                currentPhase={training.currentPhase.number}
                phaseCount={training.phaseCount}
                needDiplomaForAll={training.needDiplomForAll}
                isDiplomaUploaded={training.isDiplomUploaded}
                isDiplomaAccepted={training.isDiplomAccepted}
                needPhotoSign={training.needPhotoSign}
                isPhotoSign={training.isPhotoSign}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StudentDesktop;
