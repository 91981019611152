import {useRouteError} from 'react-router';
import {Link} from 'react-router-dom';

import classes from './styles.module.scss';

const CLIENT_ERROR = 'Некорректный запрос';
const SERVER_ERROR = 'Ведутся технические работы';
const UNKNOWN_ERROR = 'Что-то пошло не так';
const NOT_FOUND = 'Страница не найдена';
const NONE = '';

const getErrorMessage = (status = 403) => {
  if (!status) return UNKNOWN_ERROR;
  if (status < 300) return NONE;
  if (status === 404 && status < 500) return NOT_FOUND;
  if (status === 400 && status < 500) return CLIENT_ERROR;
  if (status >= 500) return SERVER_ERROR;
  return UNKNOWN_ERROR;
};

const ErrorPage = () => {
  const error = useRouteError();
  const statusText = getErrorMessage(error.status);

  return (
    <div className={classes.wrapper}>
      <div className={classes.notfound}>
        <div className={classes.status}>
          <h1>{error.status}</h1>
        </div>
        <h2>Приносим извинения, {statusText}!</h2>
        <p> Страница, которую вы ищите, временно недоступна.</p>
        <p> Пожалуйста перезагрузите страницу или попробуйте позже</p>
        <Link to="/" className={classes.link}>
          Вернуться на главную
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
