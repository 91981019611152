import {Autocomplete, Box, TextField, Typography} from '@mui/material';
import {Controller, get, useFormContext} from 'react-hook-form';

import {useSuggestPromptMutation} from '../../api/slices/public';
import useDebouncedCallback from '../../hooks/useDebouncedCallback';

const ControlledAutocomplete = ({
  control,
  errors,
  name,
  label,
  type,
  validateValue = () => {},
  autoFill = () => {},
  checkDelivery = () => {},
  pattern,
  ...props
}) => {
  const error = get(errors, name);
  const {setError, clearErrors} = useFormContext();
  const [suggestPrompt, {data = [], isLoading}] = useSuggestPromptMutation();

  const loadOptions = useDebouncedCallback((e, value, reason) => {
    return reason === 'input' ? suggestPrompt({type, query: value}) : null;
  }, 500);

  const optionLabel = (option) => {
    const address = option.data.address ? option.data.address.value : '';
    switch (type) {
      case 'party':
        return `${option.value} ${address}`;
      case 'address':
        return option.unrestricted_value;
      case 'bank':
        return `${option.value} ${option.data.bic}`;
      default:
        return option.value;
    }
  };

  const options = type === 'address' ? data.map((option) => ({...option, value: option.unrestricted_value})) : data;

  return (
    <Controller
      control={control}
      name={name}
      render={({field: {name, value, onChange, ref}}) => (
        <Autocomplete
          disablePortal
          autoComplete
          freeSolo
          name={name}
          onChange={autoFill}
          inputValue={value ?? ''}
          onInputChange={(e, value, reason) => {
            if (value !== '' && pattern) {
              if (!pattern.test(value)) {
                setError && setError(name, {message: 'Разрешены только буквы русского алфавита и знак "-"'});
                return;
              } else {
                clearErrors && clearErrors(name);
              }
            }
            onChange(value);
            loadOptions(e, value, reason);
            checkDelivery(e, value, reason);
          }}
          onBlur={() => {
            if (value !== '' && pattern) {
              if (!pattern.test(value)) {
                setError && setError(name, {message: 'Разрешены только буквы русского алфавита и знак "-"'});
                return;
              } else {
                clearErrors && clearErrors(name);
              }
            }
            validateValue();
          }}
          options={options}
          getOptionLabel={(option) => option.value}
          renderOption={(props, option, index) => {
            return (
              <Box component="li" {...props} key={index.index}>
                {optionLabel(option)}
              </Box>
            );
          }}
          filterOptions={(option) => option}
          isOptionEqualToValue={(option, value) => option === value}
          noOptionsText="Ничего не найдено"
          loadingText="Загрузка..."
          loading={isLoading}
          {...props}
          renderInput={(params) => (
            <Box>
              <TextField {...params} size="small" autoComplete="off" error={!!error} label={label} inputRef={ref} />
              {error?.message && (
                <Typography variant="caption" color="error" sx={{mt: 1, ml: 1}}>
                  {error?.message}
                </Typography>
              )}
            </Box>
          )}
        />
      )}
    />
  );
};
export default ControlledAutocomplete;
