import {Box, Typography, useMediaQuery} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import {AUTH_URL} from '../../../constants';

const Logout = () => {
  const isDesktop = useMediaQuery('(min-width:768px)');

  const logout = () => {
    localStorage.clear();
    document.location.replace(AUTH_URL);
  };

  return isDesktop ? (
    <Box onClick={logout} className="header__exit">
      <Typography variant="body1">Выйти</Typography>
    </Box>
  ) : (
    <LogoutIcon fontSize="medium" sx={{color: '#FFF'}} onClick={logout} />
  );
};

export default Logout;
