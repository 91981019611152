const PdfIcon = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1803_820)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.18739 0.5H18.2174L26.7181 9.36058V26.5945C26.7181 28.7534 24.9715 30.5 22.8201 30.5H8.18739C6.02848 30.5 4.28186 28.7534 4.28186 26.5945V4.40553C4.28182 2.24662 6.02844 0.5 8.18739 0.5V0.5Z"
          fill="#D33F1E"
        />
        <path
          opacity="0.302"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.2099 0.5V9.29311H26.7182L18.2099 0.5Z"
          fill="white"
        />
        <path
          d="M8.62219 22.8836V17.4038H10.9535C11.5308 17.4038 11.988 17.5612 12.3329 17.8836C12.6777 18.1984 12.8501 18.6257 12.8501 19.1579C12.8501 19.6902 12.6777 20.1175 12.3329 20.4323C11.988 20.7546 11.5308 20.9121 10.9535 20.9121H10.024V22.8836H8.62219ZM10.024 19.7202H10.7961C11.006 19.7202 11.1709 19.6752 11.2834 19.5703C11.3958 19.4728 11.4558 19.3379 11.4558 19.158C11.4558 18.9781 11.3958 18.8431 11.2834 18.7457C11.171 18.6407 11.006 18.5958 10.7961 18.5958H10.024V19.7202ZM13.4273 22.8836V17.4038H15.3688C15.7511 17.4038 16.111 17.4563 16.4483 17.5687C16.7856 17.6812 17.093 17.8386 17.3628 18.056C17.6327 18.2659 17.8501 18.5507 18.0075 18.9106C18.1574 19.2704 18.2399 19.6827 18.2399 20.1474C18.2399 20.6047 18.1574 21.017 18.0075 21.3768C17.8501 21.7366 17.6327 22.0215 17.3628 22.2314C17.0929 22.4488 16.7856 22.6062 16.4483 22.7186C16.111 22.8311 15.7511 22.8836 15.3688 22.8836H13.4273ZM14.7991 21.6917H15.2039C15.4213 21.6917 15.6237 21.6692 15.8111 21.6167C15.991 21.5643 16.1634 21.4818 16.3283 21.3693C16.4858 21.2569 16.6132 21.0995 16.7031 20.8896C16.7931 20.6797 16.8381 20.4323 16.8381 20.1474C16.8381 19.8551 16.7931 19.6077 16.7031 19.3978C16.6132 19.188 16.4858 19.0305 16.3283 18.9181C16.1634 18.8056 15.991 18.7232 15.8111 18.6707C15.6237 18.6182 15.4213 18.5957 15.2039 18.5957H14.7991V21.6917ZM18.9445 22.8836V17.4038H22.8426V18.5957H20.3463V19.4728H22.3403V20.6572H20.3463V22.8836H18.9445Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1803_820">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PdfIcon;
