import {Stack, Typography} from '@mui/material';
import ExamStatus from './examStatus';

const StudentTraining = ({trainingProgramName, isExamFinished}) => {
  return (
    <Stack mb={1.5}>
      <Stack spacing={1}>
        <Typography variant="subtitle2">Программа обучения</Typography>
        <Typography variant="h5">{trainingProgramName}</Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={{sm: 2, s: 1}}>
        <ExamStatus isExamFinished={isExamFinished} />
      </Stack>
    </Stack>
  );
};

export default StudentTraining;
