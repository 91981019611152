import {Box, Stack, Button, Typography} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import ExamStatus from './examStatus';
import StudentTraining from './studentTraining';

const CustomerMobile = ({fullName, courseNumber, isExamFinished, studentTrainings, loginAsStudent, openContract}) => {
  return (
    <Box>
      <Stack alignItems="space-between">
        <Stack
          direction={{xs: 'row'}}
          justifyContent="space-between"
          alignItems={{xs: 'center', s: 'stretch'}}
          spacing={{xs: 2, s: 1}}
          mb={1.5}>
          <Stack spacing={1}>
            <Typography variant="subtitle2">ФИО</Typography>
            <Typography variant="h5" onClick={openContract}>
              {fullName}
            </Typography>
          </Stack>
          <Stack
            direction={{xs: 'row'}}
            justifyContent="space-between"
            alignItems={{xs: 'center', s: 'stretch'}}
            spacing={{xs: 2, s: 1}}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={{xs: 2, s: 1}}>
              <Stack spacing={1}>
                <Typography variant="subtitle2">№ курса</Typography>
                <Typography variant="h5">{courseNumber}</Typography>
              </Stack>
              <Stack spacing={1}>
                <ExamStatus isExamFinished={isExamFinished} />
              </Stack>
            </Stack>
            <Button
              variant="contained"
              sx={{whiteSpace: 'nowrap'}}
              color="secondary"
              endIcon={<KeyboardArrowRightIcon />}
              onClick={loginAsStudent(courseNumber)}>
              К обучению
            </Button>
          </Stack>
        </Stack>
        {studentTrainings.map((training) => {
          return (
            <StudentTraining
              key={training.id}
              isExamFinished={training.isExamFinished}
              trainingProgramName={training.trainingProgramText}
            />
          );
        })}
      </Stack>
    </Box>
  );
};

export default CustomerMobile;
