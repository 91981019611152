import {useParams} from 'react-router';
import {Link} from 'react-router-dom';

import {publicSlice} from '../../api/slices/public';
import {useGetTrainingQuery} from '../../api/slices/student';

import Loader from '../../components/Loader';
import {Button, Stack, Typography} from '@mui/material';

const EisotTestAttention = ({close}) => {
  const {programId, moduleId} = useParams();
  const {data: trainings, isLoading} = useGetTrainingQuery({programId});

  return isLoading ? (
    <Loader />
  ) : (
    <Stack spacing={1.5}>
      <Typography variant="h2">Пробный тест является ТРЕНАЖЁРОМ для подготовки к тестированию в ЕИСОТ</Typography>
      <Typography variant="body1">
        Рекомендуем многократно проходить Пробный тест, чтобы подготовиться к внутреннему экзамену и тестированию в
        ЕИСОТ.
      </Typography>
      <Typography variant="body1">Количество попыток для Пробного теста не ограничено!</Typography>
      <Typography variant="body1">
        Рекомендуем переходить к внутреннему экзамену только в том случае, если в Пробных тестах Вы ответили верно на
        большинство вопросов.
      </Typography>
      <Stack spacing={1.5}>
        <Button variant="contained" color="secondary" onClick={close}>
          Я еще не готов
        </Button>
        <Button component={Link} to={`/test/${programId}/${moduleId}`} variant="contained" color="primary">
          Начать пробный тест
        </Button>
      </Stack>
    </Stack>
  );
};

export default EisotTestAttention;
