import {Stack, Typography, useMediaQuery} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

const ExamStatus = ({isExamFinished}) => {
  const isDesktop = useMediaQuery('(min-width:768px)');

  return (
    <>
      {isDesktop ? null : <Typography variant="subtitle2">Экзамен</Typography>}
      {isExamFinished ? (
        <Stack direction="row" alignItems="center">
          <Typography variant="body1" color="green">
            Сдан
          </Typography>
          <DoneIcon fontSize="12px" color="success" />
        </Stack>
      ) : (
        <Typography variant="body2">Не сдан</Typography>
      )}
    </>
  );
};

export default ExamStatus;
