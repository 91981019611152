import {useRef, useCallback} from 'react';
import PropTypes from 'prop-types';

const useDebouncedCallback = (callback, delay, dependencies = []) => {
  const timeout = useRef();

  return useCallback(
    (...args) => {
      if (timeout.current != null) {
        clearTimeout(timeout.current);
      }
      timeout.current = window.setTimeout(() => {
        callback(...args);
      }, delay);
    },
    [callback, delay, ...dependencies]
  );
};

useDebouncedCallback.propTypes = {
  callback: PropTypes.func,
  delay: PropTypes.number,
  dependencies: PropTypes.array,
};

export default useDebouncedCallback;
