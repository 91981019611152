import { forwardRef } from "react";
import classNames from "classnames/bind";

import classes from "./styles.module.scss";

const cx = classNames.bind(classes);

const Checkbox = forwardRef(({ id, label, size, ...props }, ref) => {
  return (
    <div className={classes.block}>
      <input
        id={id}
        ref={ref}
        type="checkbox"
        className={cx({ checkbox: true, small: size === "small" })}
        {...props}
      />
      <label htmlFor={id} className={classes.label}>
        {label}
      </label>
    </div>
  );
});

export default Checkbox;
