import {Box, Stack, Typography} from '@mui/material';

const PersonalInfoItem = ({title = '', itemProps, children}) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={3} {...itemProps}>
      <Typography variant="body2" sx={{flex: {sm: '0 1 250px', s: '0 1 200px'}}}>
        {title}
      </Typography>
      <Box sx={{flex: '0 1 100%'}}>{children}</Box>
    </Stack>
  );
};

export default PersonalInfoItem;
