import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {Link} from 'react-router-dom';
import dayjs from 'dayjs';

import {Status} from '../Status';
import CustomerMobile from './CustomerMobile';
import CustomersLoading from './CustomersLoading';
import CustomerMobileLoading from './CustomerMobileLoading';

import {useGetContractListQuery} from '../../../../api/slices/user';

import '../style.scss';

const Customers = ({contracts = [], title, contractStatus}) => {
  const {isLoading} = useGetContractListQuery();

  const isDesktop = useMediaQuery('(min-width:768px)');

  return (
    <Box className="contracts">
      <Typography variant="h2" mb={{s: 1}}>
        {title}
      </Typography>
      {isDesktop ? (
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>Заказчик</TableCell>
                <TableCell align="center">Дата создания</TableCell>
                <TableCell align="center">Договор</TableCell>
                <TableCell align="center">Сумма</TableCell>
                <TableCell align="center">Статус оплаты</TableCell>
                <TableCell align="center">Статус договора</TableCell>
                <TableCell align="center">Данные по договору</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <CustomersLoading />
              ) : contracts.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={7} sx={{border: 'none'}}>
                    <Typography variant="h2" textAlign={'center'}>
                      Нет активных договоров
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                contracts.map((customer) => {
                  return (
                    <TableRow key={customer.id} sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                      <TableCell>
                        <Typography
                          variant="h5"
                          component={Link}
                          to={`user/${customer.customerId}`}
                          color="#000"
                          className="link">
                          {customer.customerComplexName}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h5"> {dayjs(customer.created_at).format('DD.MM.YYYY')}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h5" component={Link} to={`requests/${customer.id}`} color="#0249FE">
                          {customer.number}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h5">{customer.cost} ₽</Typography>
                      </TableCell>
                      <TableCell align="center">
                        {customer?.inArchiveDate ? (
                          'В архиве'
                        ) : (
                          <Status
                            id={customer.id}
                            customerType={customer.customerTypeId}
                            isPayed={customer.isPayed}
                            contract={customer.number}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">{customer?.contractStatusName || contractStatus}</TableCell>
                      <TableCell align="center">
                        <Button
                          component={Link}
                          to={`requests/${customer.id}`}
                          variant="contained"
                          color="secondary"
                          endIcon={<NavigateNextIcon />}>
                          Посмотреть
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : isLoading ? (
        <CustomerMobileLoading />
      ) : contracts.length === 0 ? (
        <Typography variant="h2" textAlign={'center'} sx={{m: 2}}>
          Нет активных договоров
        </Typography>
      ) : (
        contracts.map((customer) => {
          return (
            <CustomerMobile
              key={customer.id}
              id={customer.id}
              customerId={customer.customerId}
              customerTypeId={customer.customerTypeId}
              customerName={customer.customerComplexName}
              creationDate={customer.created_at}
              number={customer.number}
              contractStatusName={customer.contractStatusName}
              isPayed={customer.isPayed}
              cost={customer.cost}
            />
          );
        })
      )}
    </Box>
  );
};

export default Customers;
