const ErrorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
    >
      <path
        d="M9.06253 12.7031C9.07034 13.2148 9.48831 13.625 10 13.625C10.5117 13.625 10.9297 13.2109 10.9375 12.7031L11.25 4.28125C11.2696 3.96094 11.1524 3.64844 10.9258 3.40625C10.6836 3.14844 10.3477 3 10 3C9.65237 3 9.31643 3.14844 9.07425 3.40625C8.84768 3.64844 8.7305 3.96094 8.75003 4.28125L9.06253 12.7031Z"
        fill="#D33F1E"
      />
      <path
        d="M10 18C10.6904 18 11.25 17.4404 11.25 16.75C11.25 16.0596 10.6904 15.5 10 15.5C9.30964 15.5 8.75 16.0596 8.75 16.75C8.75 17.4404 9.30964 18 10 18Z"
        fill="#D33F1E"
      />
    </svg>
  );
};

export default ErrorIcon;
