import {CSSTransition} from 'react-transition-group';
import store from '../../../app/store';
import {studentSlice} from '../../../api/slices/student';
import {EDUCATION_MATERIAL_TYPE} from '../../../constants';

import {Divider, Stack, Typography} from '@mui/material';
import {skipToken} from '@reduxjs/toolkit/query';

const Video = ({name, programId, videoId, educationType, isActive, setActive, isDesktop}) => {
  const openVideo = () => {
    setActive(videoId);
    store.dispatch(studentSlice.endpoints.getVideo.initiate({programId, videoId}, {forceRefetch: !isActive}));
  };

  const openAdLink = () => {
    store
      .dispatch(studentSlice.endpoints.getVideo.initiate({programId, videoId}))
      .unwrap()
      .then((res) => {
        window.open(res.link, '_blank');
      })
      .catch((err) => console.log(err));
  };

  const {data, isSuccess, isError} = studentSlice.endpoints.getVideo.useQueryState(
    !isDesktop ? {programId, videoId} : skipToken
  );

  const getCorrectLink = (link = '') => {
    if (link.includes('/watch?v=')) {
      return link.replace('/watch?v=', '/embed/').slice(0, 41);
    }
    if (link.includes('https://youtu.be/')) {
      return link.replace('https://youtu.be/', 'https://www.youtube.com/embed/').slice(0, 41);
    }
  };

  const link = getCorrectLink(data?.link);
  const parser = new DOMParser();
  name = parser.parseFromString(name, 'text/html').documentElement.textContent;

  return (
    <div>
      {educationType === EDUCATION_MATERIAL_TYPE.adLink ? (
        <Stack spacing={1} mb={2} sx={{cursor: 'pointer'}} className="link">
          <Typography variant="body1" color="error" onClick={openAdLink}>
            {name}
          </Typography>
          <Divider color="#89AAFF" sx={{height: '2px'}} />
        </Stack>
      ) : (
        <Stack spacing={1} mb={2} sx={{cursor: 'pointer'}} color={isActive ? '#0249FE' : 'initial'} className="link">
          <Typography variant="body1" onClick={openVideo}>
            {name}
          </Typography>
          <Divider color="#89AAFF" sx={{height: '2px'}} />
        </Stack>
      )}
      {!isDesktop && (
        <>
          <CSSTransition in={isActive && isSuccess} classNames="video" timeout={300} unmountOnExit>
            <iframe title="video" src={link} style={{width: '100%', height: '50vh'}} />
          </CSSTransition>
          {isError && (
            <Typography variant="body1" color="error">
              Приносим свои извинения, произошла ошибка на сервере, перезагрузите страницу или зайдите позже
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

export default Video;
