import {useFormContext, useWatch, Controller} from 'react-hook-form';
import {CSSTransition} from 'react-transition-group';
import {skipToken} from '@reduxjs/toolkit/query';
import {FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Stack, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import {useGetAvailableContractsRelationsQuery, useGetCustomersQuery} from '../../../api/slices/user';
import {useCheckExpressDeliveryMutation} from '../../../api/slices/public';
import {useGetUserInfoQuery} from '../../../api/slices/auth';
import {DeliveryType} from '../../../constants';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';

import ControlledAutocomplete from '../../../components/FieldRegister/nestedAutocomplete';
import ControlledTextArea from '../../../components/FieldRegister/TextArea';

import ContractPrice from './modules/deliveryInfo/Price';
import RegistrationStep from './modules/RegistrationStep';
import WriteOffBonuses from './modules/deliveryInfo/writtteOffBonuses';
import InformationBlock from './modules/deliveryInfo/informationBlock';
import Promocode from './modules/deliveryInfo/Promocode';
import {useEffect, useState} from 'react';

const DeliveryInfo = ({getContractCost}) => {
  const {
    setValue,
    control,
    getValues,
    formState: {errors},
  } = useFormContext();
  const [checkExpressDelivery, {data, isSuccess, isError}] = useCheckExpressDeliveryMutation({
    fixedCacheKey: 'shared-express-delivery',
  });
  const {data: userInfo} = useGetUserInfoQuery();
  const {data: customers} = useGetCustomersQuery();
  const [deliveryType, setDeliveryType] = useState('customerAddress');

  const isExpressDelivery = useWatch({name: 'deliveryTypeId', control}) === DeliveryType.expressDelivery;
  const deliveryTypeId = useWatch({name: 'deliveryTypeId', control});
  const id = useWatch({name: 'customerId', control});
  const postAddress = useWatch({name: 'postAddress', control});
  const relationContractId = useWatch({name: 'relationContractId', control});

  const {currentData: contractsRelations, isError: contractsRelationsError} = useGetAvailableContractsRelationsQuery(
    id?.value || id,
    {skip: !id}
  );

  const setDeliveryRouteId = (e) => {
    if (e.target.value === DeliveryType.expressDelivery) {
      setValue('deliveryRouteId', data?.data.id);
      getContractCost(data?.data.id);
    } else {
      getContractCost();
    }
  };

  const onDeliveryTypeChange = (e) => {
    if (customers) {
      const selectedCustomer = customers?.data?.filter((c) => c.id === id);
      if (deliveryTypeId === DeliveryType.withoutDelivery) {
        setValue('postAddress', selectedCustomer[0]?.postAddress);
        checkExpressDelivery({address: selectedCustomer[0]?.postAddress});
      }

      if (e.target.value === 'customerAddress') {
        if (selectedCustomer[0]?.legalAddress) {
          setValue('postAddress', selectedCustomer[0]?.legalAddress);
        } else {
          setValue('postAddress', selectedCustomer[0]?.postAddress);
        }
        setValue('deliveryTypeId', DeliveryType.postOfRussia);
        checkExpressDelivery({address: selectedCustomer[0]?.legalAddress});
      }
      if (e.target.value === 'anotherAddress') {
        setValue('postAddress', selectedCustomer[0]?.postAddress);
        setValue('deliveryTypeId', DeliveryType.postOfRussia);
        checkExpressDelivery({address: selectedCustomer[0]?.postAddress});
      }
    }
    setDeliveryType(e.target.value);
  };

  useEffect(() => {
    if (customers) {
      const selectedCustomer = customers?.data?.filter((c) => c.id === id);

      if (deliveryType === 'customerAddress') {
        if (selectedCustomer[0]?.legalAddress) {
          setValue('postAddress', selectedCustomer[0]?.legalAddress);
        } else {
          setValue('postAddress', selectedCustomer[0]?.postAddress);
        }
        setValue('deliveryTypeId', DeliveryType.postOfRussia);
        checkExpressDelivery({address: selectedCustomer[0]?.legalAddress});
      } else if (deliveryType === 'anotherAddress') {
        setValue('deliveryTypeId', DeliveryType.postOfRussia);
        setValue('postAddress', selectedCustomer[0]?.postAddress);
        checkExpressDelivery({address: selectedCustomer[0]?.postAddress});
      } else {
        setValue('deliveryTypeId', DeliveryType.withoutDelivery);
      }
    }
  }, [id]);

  useEffect(() => {
    if (relationContractId) {
      const selectedCustomer = customers?.data?.filter((c) => c.id === id);
      // if (selectedCustomer) {
      //   setValue('postAddress', selectedCustomer[0]?.legalAddress);
      // } else {
      setValue('postAddress', null);
      // }
      setDeliveryType(DeliveryType.withoutDelivery);
      setValue('deliveryTypeId', DeliveryType.withoutDelivery);
    }
  }, [relationContractId]);

  const deliveryPrice = data?.data.cost ? data?.data.cost : 0;

  const relations =
    contractsRelations && !contractsRelationsError
      ? contractsRelations.map((contract) => ({
          relationContractId: contract.id,
          number: Array.isArray(contract.contracts)
            ? contract.contracts
                .map((item) => item.number)
                .toString()
                .replaceAll(',', ', ')
            : contract.contracts.number,
        }))
      : null;

  return (
    <RegistrationStep step="Шаг 3 из 3" title="Информация для доставки оригиналов документов">
      {contractsRelations && (
        <InformationBlock title="Договоры для совместной отправки">
          <FormControl fullWidth>
            <Controller
              control={control}
              name="relationContractId"
              render={({field: {name, value = '', onChange}}) => (
                <RadioGroup
                  name={name}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    setValue('deliveryTypeId', DeliveryType.withoutDelivery);
                    getContractCost();
                  }}>
                  <Stack
                    direction={{sm: 'row', s: 'column'}}
                    spacing={{sm: 2, s: 0.5}}
                    sx={{maxWidth: '100%', flexWrap: 'wrap'}}>
                    {relations.map((contract) => (
                      <Stack direction="row" alignItems="center" key={contract.relationContractId}>
                        <FormControlLabel
                          value={contract.relationContractId}
                          control={<Radio />}
                          label={contract.number}
                        />
                        {value === contract.relationContractId && (
                          <IconButton color="error" onClick={() => setValue('relationContractId', null)}>
                            <CloseIcon />
                          </IconButton>
                        )}
                      </Stack>
                    ))}
                  </Stack>
                </RadioGroup>
              )}
            />
          </FormControl>
        </InformationBlock>
      )}
      <InformationBlock title="Выберите тип доставки">
        <Stack>
          <RadioGroup
            value={deliveryType}
            onChange={(e) => {
              onDeliveryTypeChange(e);
            }}
            sx={{
              flexWrap: 'nowrap',
              mb: {sm: 2, s: 1},
              flexDirection: {xs: 'column', sm: 'row'},
            }}
            className="radio-group">
            <FormControlLabel
              value="customerAddress"
              disabled={!!relationContractId}
              control={<Radio />}
              label="Доставка по адресу заказчика"
              className="radio-label"
            />
            <FormControlLabel
              value="anotherAddress"
              disabled={!!relationContractId}
              control={<Radio />}
              label="Доставка по почтовому адресу"
              className="radio-label"
            />
            <FormControlLabel
              disabled={!!relationContractId}
              value={DeliveryType.withoutDelivery}
              control={<Radio />}
              label="Доставка не требуется"
              className="radio-label"
            />
          </RadioGroup>

          {deliveryType !== DeliveryType.withoutDelivery && (
            <>
              <Stack direction={{sm: 'row', s: 'column'}} spacing={{sm: 2, s: 1}}>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="deliveryTypeId"
                    render={({field: {name, value, onChange}}) => (
                      <RadioGroup
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          setDeliveryRouteId(e);
                        }}
                        sx={{
                          flexWrap: 'nowrap',
                          flexDirection: {xs: 'column', sm: 'row'},
                        }}
                        className="radio-group">
                        <FormControlLabel
                          value={DeliveryType.postOfRussia}
                          control={<Radio />}
                          label="Почта России"
                          disabled={!!relationContractId}
                          className="radio-label"
                        />
                        <CSSTransition in={isSuccess} timeout={300} classNames="fade" unmountOnExit>
                          <FormControlLabel
                            disabled={!!relationContractId}
                            value={DeliveryType.expressDelivery}
                            control={<Radio />}
                            label={`Экспресс доставка cтоимость доставки ${deliveryPrice} руб.`}
                            className="radio-label"
                          />
                        </CSSTransition>
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </Stack>
              <Typography variant="h4">{postAddress}</Typography>
              <Typography variant="subtitle2">Индекс для указанного адреса присваивается автоматически</Typography>
            </>
          )}
          {errors?.postAddress && (
            <Typography color="error">Нужно указать адрес заказчика в личном кабинете</Typography>
          )}
        </Stack>
      </InformationBlock>
      <InformationBlock title="Есть пожелания к заказу?">
        <Stack direction={{sm: 'row', s: 'column'}} spacing={{sm: 2, s: 1}}>
          <ControlledTextArea name="wishes" label="Укажите ваше пожелание" maxLength={1000} sx={{width: {sm: '49%'}}} />
          <Promocode getContractCost={getContractCost} />
        </Stack>
      </InformationBlock>
      <ContractPrice isExpressDelivery={isExpressDelivery} />
      {userInfo?.bonuses ? <WriteOffBonuses bonuses={userInfo?.bonuses} getContractCost={getContractCost} /> : null}
    </RegistrationStep>
  );
};

export default DeliveryInfo;
