import {Controller, useFormContext} from 'react-hook-form';
import {FormControlLabel, Checkbox} from '@mui/material';

const ControlledCheckbox = ({control, ...props}) => {
  return (
    <Controller
      control={control}
      name={props.name}
      render={({field: {onChange, onBlur, name, value, ref}}) => (
        <FormControlLabel
          control={<Checkbox value={value} onChange={onChange} onBlur={onBlur} name={name} ref={ref} {...props} />}
          label={props.label}
        />
      )}
    />
  );
};

export default ControlledCheckbox;
