// export const checkInsuranceNumber = (str) => {
//   if (str === '') return true;
//   let sum = null;
//   let hash = Number(str.slice(-2));

//   str = str.replaceAll(/[ _-]/g, '');

//   for (let i = 0; i < 9; i++) {
//     sum = sum + str[i] * (9 - i);
//   }

//   if (sum < 100) {
//     return sum === hash;
//   } else if (sum > 100) {
//     hash = sum % 101;
//     if (hash === 100) {
//       hash = 0;
//     }
//   } else if (hash === parseInt(str.slice(-2))) {
//     return true;
//   }
// };

export const checkInsuranceNumber = (snils) => {
  let result = false;
  let sum = null;

  if (snils === '') return true;
  snils = snils.replaceAll(/[ _-]/g, '');

  for (let i = 0; i < 9; i++) {
    sum += parseInt(snils[i]) * (9 - i);
  }
  let checkDigit = 0;
  if (sum < 100) {
    checkDigit = sum;
  } else if (sum > 100) {
    checkDigit = sum % 101;
    if (checkDigit === 100) {
      checkDigit = 0;
    }
  }
  if (checkDigit === parseInt(snils.slice(-2))) {
    result = true;
  }

  return result;
};

// if (sum < 100) {
//   return sum === hash;
// } else if (sum === 100 || sum === 101) {
//   return hash === 0;
// } else {
//   return sum % 101 === hash;
// }
