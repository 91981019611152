import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router';
import {Link} from 'react-router-dom';
import {CLIENT_URL, QUESTION_TYPES} from '../../constants';
import {studentSlice, useCheckStudentAnswerMutation, useGetExamQuery, useGetTestQuery} from '../../api/slices/student';
import useToggle from '../../hooks/useToggle';

import AnswerSelection from './Answers/AnswerSelection';
import AnswerReview from './Answers/AnswerReview';
import ProgressBar from '../../features/ProgressBar';
import Timer from '../../features/Timer/Timer';
import Supervisor from '../../features/Supervisor';

import Modal from '../../components/Modal';
import Loader from '../../components/Loader';
import {Box, IconButton, Stack, Typography, useMediaQuery} from '@mui/material';

import './styles.scss';
import store from '../../app/store';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const KnowledgeControl = () => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [pageState, setPageState] = useState('ANSWERING'); // ANSWERING || REVIEWING || GETTINGRESULTS
  const [isSupervisorOpen, toggle] = useToggle();
  const {knowledgeType, programId, moduleId} = useParams();
  const navigate = useNavigate();

  const knowledge = {
    exam: {title: 'Экзамен', timeText: 'экзамена', query: useGetExamQuery},
    test: {title: 'Пробный тест', timeText: 'теста', query: useGetTestQuery},
  };

  const {data, isLoading, isError, isSuccess, refetch} = knowledge[knowledgeType].query(programId, {
    refetchOnMountOrArgChange: true,
  });

  const [checkStudentAnswer, {data: answer, isLoading: isAnswerLoading}] = useCheckStudentAnswerMutation();

  const isLoaderShown = isLoading || pageState === 'GETTINGRESULTS' || isAnswerLoading;

  const testDurationOnAnswerSelection = data?.testDuration
    ? data?.testDuration.split(':').reduce((acc, next) => 60 * acc + +next)
    : null;

  const testDurationOnAnswerReview = answer?.testDuration
    ? answer?.testDuration.split(':').reduce((acc, next) => 60 * acc + +next)
    : null;

  useEffect(() => {
    if (isSuccess) {
      if (data.question) {
        setPageState('ANSWERING');
      }
      if (!data.question) {
        setPageState('GETTINGRESULTS');
      }
    }
  }, [data, knowledgeType, moduleId, navigate]);

  if (isError) {
    document.location.replace(CLIENT_URL);
  }

  const stopTest = () => {
    store.dispatch(studentSlice.endpoints.finishTest.initiate(programId, {forceRefetch: true}));
  };

  const nextTestQuestion = () => {
    if (data.currentQuestionNumber === data.questionCount) {
      store
        .dispatch(studentSlice.endpoints.finishTest.initiate(programId, {forceRefetch: true}))
        .unwrap()
        .then(() => {
          navigate(`/result/${knowledgeType}/${programId}/${moduleId}`, {replace: true});
        })
        .catch((err) => console.log(err));
    } else {
      refetch();
    }
    setPageState('GETTINGRESULTS');
    setSelectedAnswer(null);
  };

  const nextExamQuestion = () => {
    store
      .dispatch(studentSlice.endpoints.getExam.initiate(programId, {forceRefetch: true}))
      .unwrap()
      .then((res) => {
        if (!res.question) {
          store
            .dispatch(studentSlice.endpoints.getExamResult.initiate(moduleId))
            .unwrap()
            .then(() => {
              navigate(`/result/${knowledgeType}/${programId}/${moduleId}/true`, {replace: true});
            })
            .catch((err) => console.log(err));
        }
      });
    setPageState('GETTINGRESULTS');
    setSelectedAnswer(null);
  };

  const SendAnswer = () => {
    const testData = {
      studentQuestionAnswerId: data.studentQuestionAnswerId,
      answerOptions: data.answers,
    };
    data.question.type === QUESTION_TYPES.oneAnswer
      ? (testData.answer = selectedAnswer)
      : (testData.answers = selectedAnswer);

    checkStudentAnswer({testData}).unwrap().then(setPageState('REVIEWING'));
  };

  const handleMultiAnswer = (id) => {
    if (!selectedAnswer) {
      setSelectedAnswer([id]);
    } else {
      const index = selectedAnswer.indexOf(id);
      setSelectedAnswer([...selectedAnswer, id]);
      if (index > -1) {
        selectedAnswer.splice(index, 1);
        setSelectedAnswer([...selectedAnswer]);
      }
    }
  };

  const handleOneAnswer = (id) => {
    setSelectedAnswer(id);
  };

  const getSubtitleTextForQuestion = () => {
    if (!data?.question) return '';
    if (data.question.type === QUESTION_TYPES.oneAnswer) return 'Выберите 1 вариант ответа';
    else return 'Выберите несколько вариантов ответа';
  };

  const elemText = new DOMParser().parseFromString(data?.question?.text, 'text/html');
  const img = elemText.getElementsByTagName('img');
  const Image = () =>
    React.createElement(img[0].tagName.toLowerCase(), {
      src: img[0].src,
      style: {maxHeight: '150px', maxWidth: '150px'},
    });

  const questionText = img.length ? (
    <Stack direction={{sm: 'col', md: 'row'}} gap={3}>
      <Image />
      <Typography variant="h2" sx={{userSelect: 'none', fontWeight: 'bold'}}>
        {elemText.documentElement.textContent}
      </Typography>
    </Stack>
  ) : (
    <Typography variant="h2" sx={{userSelect: 'none', fontWeight: 'bold'}}>
      {elemText.documentElement.textContent}
    </Typography>
  );

  return isLoaderShown ? (
    <Loader />
  ) : (
    <Stack pt={2}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={{md: 3, sm: 2, s: 1}}
        mt={{lg: 3.5, md: 5, sm: 2.5, s: 3.5}}
        mb={{lg: 3.5, md: 5, sm: 3.5, s: 2.5}}>
        {knowledgeType === 'test' && (
          <IconButton
            component={Link}
            // to={`/result/${knowledgeType}/${programId}/${moduleId}`}
            to={`/program/${programId}`}
            replace
            onClick={stopTest}
            sx={{color: '#000'}}>
            <ArrowBackIosNewIcon />
          </IconButton>
        )}
        <Typography variant="h1">{data?.phase?.name || 'Модуль'}</Typography>
      </Stack>

      <Typography variant="h2" textAlign="center">
        {knowledge[knowledgeType].title}
      </Typography>
      <Box className="exam">
        <Stack gap={3}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body1">
              Вопрос {data?.currentQuestionNumber} из {data?.questionCount}
            </Typography>
            <Stack direction="row" gap={1.5}>
              <Typography variant="body1">Время прохождения {knowledge[knowledgeType].timeText}</Typography>
              <Timer initialValue={testDurationOnAnswerSelection ?? testDurationOnAnswerReview} />
            </Stack>
          </Stack>
          <ProgressBar
            answers={data?.answersCorrectness}
            currentQuestion={data?.currentQuestionNumber}
            totalQuestions={data?.questionCount}
          />
          <Stack gap={1}>
            {questionText}
            <Typography variant="body2">
              {pageState === 'ANSWERING' ? getSubtitleTextForQuestion() : 'Результат'}
            </Typography>
          </Stack>
          {pageState === 'ANSWERING' ? (
            <AnswerSelection
              answers={data?.answers}
              handleMultiAnswer={handleMultiAnswer}
              handleOneAnswer={handleOneAnswer}
              selectedAnswer={selectedAnswer}
              test={data}
              buttonHandler={SendAnswer}
            />
          ) : (
            <AnswerReview
              onClick={knowledgeType === 'test' ? nextTestQuestion : nextExamQuestion}
              answers={answer?.answers}
              test={answer}
              comment={answer?.question.note}
            />
          )}
        </Stack>
      </Box>

      {/* <p className="text secondary center">
        Нашли ошибку в задании?&nbsp;&nbsp;
        <span className="text primary pointer" onClick={toggle}>
          Сообщите нам!
        </span>
        <Modal show={isSupervisorOpen} onClose={toggle} title="Отправка сообщения об ошибке">
          <Supervisor close={toggle} emailLabel="email для получения ответа" textLabel="Опишите ошибку" />
        </Modal>
      </p> */}
    </Stack>
  );
};

export default KnowledgeControl;
