import {FormControlLabel, Stack, Switch, Typography} from '@mui/material';
import {Controller, useFormContext} from 'react-hook-form';

const WriteOffBonuses = ({bonuses, getContractCost}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Typography>
        <b>Бонусов доступных для списания:</b> {bonuses} Руб.
      </Typography>
      <Controller
        control={control}
        errors={errors}
        name="isWriteOffBonuses"
        render={({field}) => (
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => {
                  field.onChange(e.target.checked);
                  getContractCost(null, e.target.checked);
                }}
                checked={field.value}
              />
            }
            value={field.value}
            label="Списать"
          />
        )}
      />
    </Stack>
  );
};

export default WriteOffBonuses;
