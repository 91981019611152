import {Controller, get} from 'react-hook-form';
import {TextField, Typography} from '@mui/material';

const ControlledTextField = ({label, name, control, errors, ...props}) => {
  const error = get(errors, name);

  return (
    <Controller
      control={control}
      name={name}
      render={({field: {onChange, onBlur, name, value, ref}}) => (
        <>
          <TextField
            name={name}
            size="small"
            value={value ?? ''}
            label={label}
            onChange={onChange}
            onBlur={onBlur}
            error={!!error}
            inputRef={ref}
            {...props}
          />
          {error?.message && (
            <Typography variant="caption" color="error" sx={{mt: 1, pl: 1}}>
              {error?.message}
            </Typography>
          )}
        </>
      )}
    />
  );
};

export default ControlledTextField;
