import {useState} from 'react';
import {Box, Divider, Stack, Typography} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import {useCalcContractCostMutation} from '../../../../../api/slices/public';

const ContractPrice = ({isExpressDelivery}) => {
  const [showPriceDetails, setShowPriceDetails] = useState(false);

  const [_, {data: cost}] = useCalcContractCostMutation({
    fixedCacheKey: 'contract-price',
  });

  return (
    <Box>
      <Stack>
        <Stack direction="row" alignItems="center" mt={{xl: 3.5, md: 2.5, s: 2}} mb={{xl: 3.5, lg: 2.5, sm: 2, s: 1}}>
          <Typography variant="h4">
            Итого стоимость обучения с учетом доставки:
            <span style={{color: '#0249FE'}}> {cost ? ` ${cost.fullPrice}` : ' 0'} руб.</span>
          </Typography>
          <HelpIcon sx={{color: '#727272', cursor: 'pointer'}} onClick={() => setShowPriceDetails((state) => !state)} />
        </Stack>
        {typeof cost?.isPromocodeActivated === 'boolean' && (
          <Typography sx={{color: cost.isPromocodeActivated ? 'green' : 'red'}} variant="h4">
            {cost.isPromocodeActivated === true ? 'Промокод применен' : 'Промокод не применен'}
          </Typography>
        )}
      </Stack>

      {showPriceDetails ? (
        <Box>
          <Stack spacing={{md: 2.5, sm: 2, s: 1}}>
            {cost?.trainingPrograms.map((training) => (
              <Box key={training.trainingProgramId}>
                <Divider color="#57a5ff" />
                <Stack direction={{sm: 'row', s: 'column'}} spacing={{sm: 2, s: 1}} mt={{md: 2.5, sm: 2, s: 1}}>
                  <Typography variant="body1" fontWeight={600} sx={{flexShrink: 0}}>
                    Программа обучения:
                  </Typography>
                  <Typography variant="body1">
                    {training.trainingProgramName} {training.trainingProgramText}
                  </Typography>
                </Stack>
                <Stack
                  direction={{sm: 'row', s: 'column'}}
                  justifyContent="space-between"
                  spacing={{sm: 3, s: 1}}
                  mt={{sm: 2, s: 1}}>
                  <Typography>
                    <b>Слушателей:</b> {training.studentCount}
                  </Typography>
                  <Typography>
                    <b>Цена программы:</b> {training.singleCost} Руб.
                  </Typography>
                  <Typography>
                    <b>Стоимость за всех слушателей:</b> {training.sumCost} Руб.
                  </Typography>
                </Stack>
              </Box>
            ))}
            <Divider color="#57a5ff" />
            <Typography>
              <b>Цена за обучение:</b> {cost ? cost?.basePrice : 0} Руб.
            </Typography>
            {cost?.promocodeDiscount ? (
              <Typography>
                <b>Скидка по промокоду:</b> {cost.promocodeDiscount} Руб.
              </Typography>
            ) : null}
            <Typography>
              <b>Цена за доставку:</b> {cost?.deliveryCost && isExpressDelivery ? cost.deliveryCost : 0} Руб.
            </Typography>
            <Typography color="#0249FE">
              <b>Итого к оплате:</b> {cost ? cost.fullPrice : 0} Руб.
            </Typography>
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

export default ContractPrice;
