import {Box, Typography} from '@mui/material';
import BonusesList from './BonusesList';
import useToggle from '../../hooks/useToggle';

import MUIModal from '../../components/MUIModal';

const BonusesHistory = ({bonusesAmount}) => {
  const [isBonusesOpen, toggleBonuses] = useToggle(false);

  return (
    <Box sx={{position: 'relative'}}>
      <Typography variant="body1" className="header__bonuses" onClick={toggleBonuses}>
        Бонусы
        <Typography component="span" fontWeight={600} color="#0249FE" ml={1}>
          {bonusesAmount}
        </Typography>
      </Typography>
      <MUIModal isOpen={isBonusesOpen} toggle={toggleBonuses} small>
        <BonusesList bonusesAmount={bonusesAmount} />
      </MUIModal>
    </Box>
  );
};

export default BonusesHistory;
