import {FormControl, TextField, Typography} from '@mui/material';
import {Controller, get} from 'react-hook-form';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker, ruRU} from '@mui/x-date-pickers';

import 'dayjs/locale/ru';
import dayjs from 'dayjs';

const ControlledDatePicker = ({control, errors, label = 'дд.мм.гггг', size = 'small', name, ...props}) => {
  const error = get(errors, name);

  return (
    <Controller
      control={control}
      name={name}
      shouldUnregister
      render={({field: {onChange, onBlur, value, name, ref}}) => (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
          adapterLocale="ru">
          <DatePicker
            name={name}
            label={label}
            value={value ? (dayjs(value, 'DD.MM.YYYY').isValid() ? dayjs(value, 'DD.MM.YYYY') : dayjs(value)) : ''}
            onChange={onChange}
            onBlur={onBlur}
            inputRef={ref}
            format="DD.MM.YYYY"
            {...props}
            renderInput={(params) => (
              <FormControl sx={props.sx}>
                <TextField
                  {...params}
                  inputProps={{...params.inputProps, placeholder: label}}
                  size={size}
                  error={!!error}
                  fullWidth
                />
                {error?.message && (
                  <Typography variant="caption" color="error" sx={{ml: 2}}>
                    {error?.message}
                  </Typography>
                )}
              </FormControl>
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default ControlledDatePicker;
