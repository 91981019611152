import {useEffect, useState} from 'react';
import {Box, Dialog, IconButton} from '@mui/material';
import {Skeleton} from '@mui/lab';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import classNames from 'classnames/bind';
import {studentSlice} from '../../../api/slices/student';

import '../Trainings/styles.scss';

const PresentationMaterial = ({programId, presentationId}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  const {data, isSuccess, isError, isFetching, isLoading} = studentSlice.endpoints.getPresentation.useQueryState({
    programId,
    presentationId,
  });

  useEffect(() => {
    setCurrentSlide(0);
  }, [presentationId]);

  const updateIndex = (index) => {
    if (index < 0) {
      index = data?.slides.length - 1;
    } else if (index >= data?.slides.length) {
      index = 0;
    }
    setCurrentSlide(index);
  };

  if (isLoading || isFetching) {
    return <Skeleton width="100%" height="600px" />;
  }

  const showDialog = () => setDialogOpen(true);
  const hideDialog = () => setDialogOpen(false);

  return data?.slides ? (
    <Box sx={{position: 'sticky', top: '10px'}}>
      <div className="carousel-wrapper">
        <div className="carousel">
          {data?.slides.map((elem, index) => (
            <img
              key={elem.id}
              onClick={showDialog}
              className={classNames({
                photo: true,
                active: index === currentSlide,
                prev: index + 1 === currentSlide,
                next: index - 1 === currentSlide,
              })}
              style={{cursor: 'zoom-in'}}
              alt=""
              src={elem.link}
            />
          ))}
          <IconButton
            onClick={() => updateIndex(currentSlide - 1)}
            sx={{position: 'absolute', left: 0, top: 0, bottom: 0, zIndex: 3}}>
            <ArrowBackIosNewIcon sx={{color: '#000'}} />
          </IconButton>
          <IconButton
            onClick={() => updateIndex(currentSlide + 1)}
            sx={{position: 'absolute', right: 0, top: 0, bottom: 0, zIndex: 3}}>
            <ArrowForwardIosIcon sx={{color: '#000'}} />
          </IconButton>
        </div>
        <span className="text">{`слайд ${currentSlide + 1} из ${data?.slides.length}`}</span>
      </div>
      <Dialog open={dialogOpen} onClose={hideDialog} sx={{width: '65%', height: '90%', margin: 'auto'}} fullScreen>
        <img style={{width: '100%', height: '100%'}} key={currentSlide} alt="" src={data?.slides[currentSlide].link} />
      </Dialog>
    </Box>
  ) : (
    <Box />
  );
};

export default PresentationMaterial;
