import {useParams} from 'react-router';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {Box, Button, Chip, Stack} from '@mui/material';
import {yupResolver} from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import EntityForm from './modules/entityForm';
import IndividualForm from './modules/individualForm';
import IndividualEntrepreneurForm from './modules/individualEntrepreneurForm';

import {schema} from './schema';
import {useLazyGetCustomerQuery, useUpdateCustomerInfoMutation} from '../../../api/slices/user';
import {BASIS_TYPES, BUDGET_TYPE, CustomerType} from '../../../constants';
import useToggle from '../../../hooks/useToggle';
import sendChangeRequest from '../../../utils/sendChangeRequest';

import './style.scss';
import {useEffect} from 'react';

const CustomerForm = ({initialValues = {}}) => {
  const {id} = useParams();
  const [isEditing, toggle] = useToggle(false);
  const [updateCustomerInfo] = useUpdateCustomerInfoMutation();

  const basisTypeId = BASIS_TYPES.map((elem) => ({
    value: elem.id,
    label: elem.label,
  })).find((elem) => elem.value === initialValues.basisTypeId);

  const budgetTypeId = BUDGET_TYPE.map((elem) => ({
    value: elem.id,
    label: elem.label,
  })).find((elem) => elem.value === initialValues.budgetTypeId);

  const basisDate = dayjs(initialValues.basisDate, 'DD.MM.YYYY').isValid()
    ? dayjs(initialValues.basisDate, 'DD.MM.YYYY').format('DD.MM.YYYY')
    : dayjs(initialValues.basisDate).format('DD.MM.YYYY');
  const passportIssuedDate = dayjs(initialValues.passportIssuedDate, 'DD.MM.YYYY').isValid()
    ? dayjs(initialValues.passportIssuedDate, 'DD.MM.YYYY')
    : dayjs(initialValues.passportIssuedDate);

  const methods = useForm({
    resolver: yupResolver(schema[initialValues.customerTypeId]),
    defaultValues: {
      ...initialValues,
      budgetTypeId,
      basisTypeId,
      basisDate,
      passportIssuedDate,
    },
  });

  const {
    register,
    formState: {errors, dirtyFields},
    unregister,
    control,
    handleSubmit,
    setValue,
    reset,
  } = methods;

  const isBudget = useWatch({control, name: 'isBudget'});
  const basisType = useWatch({control, name: 'basisTypeId'});
  // const isForeignCitizenship = useWatch({control, name: 'isForeignCitizenship'});

  const handleFormSubmit = (data) => {
    data.basisDate = data?.basisDate ? dayjs(data?.basisDate).format('DD.MM.YYYY') : null;
    sendChangeRequest({
      fields: dirtyFields,
      data,
      initialValues,
      query: updateCustomerInfo,
      id,
      close: toggle,
    });
  };

  const toggleEditing = (e) => {
    e.preventDefault();
    toggle();
  };

  const autoFillBankFields = (e, data) => {
    if (!data) {
      setValue('bik', null);
      setValue('kAccount', '');
    } else {
      setValue('bik', data.data.bic, {shouldValidate: true});
      setValue('kAccount', data.data.correspondent_account ?? '', {shouldValidate: true});
    }
  };

  const autoFillBikFields = (e, data) => {
    if (!data) {
      setValue('bank', null);
      setValue('kAccount', '');
    } else {
      setValue('bank', data.value, {shouldValidate: true});
      setValue('kAccount', data.data.correspondent_account ?? '', {shouldValidate: true});
    }
  };

  useEffect(() => {
    if (isEditing === false) {
      reset({
        ...initialValues,
        budgetTypeId,
        basisTypeId,
        basisDate,
        passportIssuedDate,
      });
    }
  }, [isEditing]);

  return (
    <Box component="form" onSubmit={handleSubmit(handleFormSubmit)}>
      <Stack justifyContent="center" alignItems={'center'} direction={{sm: 'row', s: 'column'}} spacing={2}>
        {isEditing ? (
          <>
            <Button color="primary" variant="contained" type="submit">
              Внести изменения
            </Button>
            <Button color="secondary" variant="contained" onClick={toggleEditing}>
              Отменить редактирование
            </Button>
          </>
        ) : (
          <Stack gap={1}>
            <Button color="primary" variant="contained" onClick={toggleEditing}>
              Редактировать данные
            </Button>
            {initialValues?.changeQueryActive === true && (
              <Chip color="success" label={' Запрос проверяется администратором'} />
            )}
          </Stack>
        )}
      </Stack>
      <Box className="customer-information">
        <FormProvider {...methods}>
          {CustomerType.individual === initialValues.customerTypeId && (
            <IndividualForm
              initialValues={initialValues}
              passportIssuedDate={passportIssuedDate}
              control={control}
              errors={errors}
              isEditing={isEditing}
            />
          )}
          {CustomerType.entity === initialValues.customerTypeId && (
            <EntityForm
              initialValues={initialValues}
              control={control}
              basisType={basisType}
              basisDate={basisDate}
              budgetTypeId={budgetTypeId}
              isBudget={isBudget}
              errors={errors}
              isEditing={isEditing}
              register={register}
              unregister={unregister}
              autoFillBankFields={autoFillBankFields}
              autoFillBikFields={autoFillBikFields}
            />
          )}
          {CustomerType.individualEntrepreneur === initialValues.customerTypeId && (
            <IndividualEntrepreneurForm
              initialValues={initialValues}
              isEditing={isEditing}
              control={control}
              errors={errors}
              autoFillBankFields={autoFillBankFields}
              autoFillBikFields={autoFillBikFields}
            />
          )}
        </FormProvider>
      </Box>
    </Box>
  );
};

export default CustomerForm;
