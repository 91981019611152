import {toast} from 'react-toastify';
import dayjs from 'dayjs';

const sendChangeRequest = ({fields, data, initialValues, query, id, close}) => {
  const getChangedFields = () => {
    let result = {};
    for (let key in fields) {
      if (data[key] !== initialValues[key]) {
        result[key] = data[key];
      }
    }
    return result;
  };

  const changedFields = getChangedFields();
  if (changedFields && Object.keys(changedFields).length === 0) {
    close();
  }

  if (Object.keys(changedFields).length) {
    query({id, data: changedFields})
      .unwrap()
      .then(() => {
        close();
        toast.success(`Запрос на изменение данных от ${dayjs(new Date()).format('YYYY-MM-DD')} ожидает обработки`, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      })
      .catch(() => {
        toast.error(`Возникла ошибка, попробуйте внести изменения позже`, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      });
  }
};

export default sendChangeRequest;
