import {Controller, get} from 'react-hook-form';
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from '@mui/material';

const ControlledSelect = ({
  control,
  errors,
  name,
  label,
  options = [],
  onSelect = () => {},
  controlStyles,
  ...props
}) => {
  const error = get(errors, name);

  return (
    <FormControl error={!!error} style={controlStyles} size="small">
      <InputLabel id="select-label">{label}</InputLabel>
      <Controller
        control={control}
        name={name}
        render={({field: {onChange, onBlur, name, value}}) => (
          <Select
            labelId="select-label"
            name={name}
            value={value?.value ? value?.value : value ?? ''}
            label={label}
            onChange={(e) => {
              onChange(e);
              onSelect(e);
            }}
            onBlur={onBlur}
            {...props}>
            {options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
};

export default ControlledSelect;
