import {Box, Stack, Typography} from '@mui/material';

const InformationBlock = ({title, extra, children}) => {
  return (
    <Box>
      <Stack
        mt={{lg: 3.5, sm: 2.5, s: 2}}
        mb={{lg: 3.5, sm: 2.5, s: 2}}
        direction="row"
        alignItems="center"
        spacing={1}>
        <Typography variant="h4">{title}</Typography>
        {extra}
      </Stack>
      {children}
    </Box>
  );
};

export default InformationBlock;
