import {Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography, useMediaQuery} from '@mui/material';
import {CSSTransition} from 'react-transition-group';
import {Controller, useFormContext, useWatch} from 'react-hook-form';

import InformationBlock from './informationBlock';
import ControlledCheckbox from '../../../../../components/FieldRegister/Checkbox';
import ControlledTextField from '../../../../../components/FieldRegister/NestedTextField';
import ControlledAutocomplete from '../../../../../components/FieldRegister/nestedAutocomplete';
import ControlledMaskedTextField from '../../../../../components/FieldRegister/MaskedTextField';

import ControlledDatePicker from '../../../../../components/FieldRegister/DatePicker';
import ControlledSelect from '../../../../../components/FieldRegister/Select';

import {BASIS_TYPES, BudgetType} from '../../../../../constants';
import {AutocompleteBIK} from '../../../../../components/FieldRegister/AutocompleteBIK';

const EntityForm = ({autoFillCompanyFields, autoFillBankFields, autoFillBikFields, basisType, clearBudgetFields}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();
  const isTablet = useMediaQuery('(min-width:768px)');

  const isBudget = useWatch({control, name: 'isBudget'});
  console.log(errors);

  return (
    <Box>
      <InformationBlock
        title="Введите данные организации"
        extra={
          <ControlledCheckbox control={control} name="isBudget" label="Бюджетная организация" defaultValue={false} />
        }>
        <CSSTransition in={isBudget} timeout={200} classNames="alert" unmountOnExit onExit={clearBudgetFields}>
          <InformationBlock title="Выберите вид бюджета">
            <FormControl fullWidth>
              <Controller
                control={control}
                name="budgetTypeId"
                shouldUnregister
                render={({field: {name, value, onChange}}) => (
                  <RadioGroup
                    name={name}
                    value={value ?? ''}
                    onChange={onChange}
                    sx={{flexWrap: 'nowrap', mb: 2, flexDirection: {xs: 'column', sm: 'row'}}}
                    className="radio-group">
                    <FormControlLabel
                      value={BudgetType.federal}
                      control={<Radio />}
                      label="Федеральный"
                      className="radio-label"
                    />
                    <FormControlLabel
                      value={BudgetType.regional}
                      control={<Radio />}
                      label="Региональный"
                      className="radio-label"
                    />
                    <FormControlLabel
                      value={BudgetType.local}
                      control={<Radio />}
                      label="Местный"
                      className="radio-label"
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
            {errors?.customer?.budgetTypeId ||
              (errors?.budgetTypeId && (
                <Typography variant="subtitle1" color="error" align="center" mb={2}>
                  {errors?.customer?.budgetTypeId?.message || errors?.budgetTypeId?.message}
                </Typography>
              ))}
            <Stack
              mb={1}
              spacing={{s: 1}}
              direction={{sm: 'row'}}
              flexWrap={{sm: 'wrap'}}
              justifyContent="space-between"
              useFlexGap={true}>
              <ControlledTextField
                control={control}
                errors={errors}
                name="personalAccount"
                label="Лицевой счет"
                sx={{width: {sm: '49%'}}}
              />
              <ControlledTextField
                control={control}
                errors={errors}
                name="departmentTitle"
                label="Наименование территориального органа Федерального казначейства"
                sx={{width: {sm: '49%'}}}
              />
              <ControlledTextField
                control={control}
                errors={errors}
                name="treasuryAccount"
                label="Казначейский счет"
                sx={{width: {sm: '49%'}}}
              />
              <ControlledTextField
                control={control}
                errors={errors}
                name="mainAccount"
                label="Единый казначейский счет (ЕКС)"
                sx={{width: {sm: '49%'}}}
              />
            </Stack>
          </InformationBlock>
        </CSSTransition>
        <Stack
          spacing={{s: 1}}
          direction={{sm: 'row'}}
          flexWrap={{sm: 'wrap'}}
          justifyContent="space-between"
          useFlexGap={true}>
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="organizationName"
            label="Название организации*"
            type="party"
            fullWidth
            autoFill={autoFillCompanyFields}
          />
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="legalAddress"
            label="Юридический адрес организации*"
            type="address"
            fullWidth
          />
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="postAddress"
            label="Почтовый адрес*"
            type="address"
            fullWidth
          />
          <ControlledMaskedTextField
            control={control}
            errors={errors}
            name="inn"
            label="ИНН*"
            format="##########"
            sx={{width: {sm: '32%'}}}
          />
          <ControlledMaskedTextField
            control={control}
            errors={errors}
            name="kpp"
            label="КПП*"
            format="#########"
            sx={{width: {sm: '32%'}}}
          />
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="bank"
            label="Банк"
            type="bank"
            autoFill={autoFillBankFields}
            sx={{width: {sm: '32%'}}}
          />
          <AutocompleteBIK
            control={control}
            errors={errors}
            name="bik"
            label="БИК банка"
            type="bank"
            autoFill={autoFillBikFields}
            sx={{width: {sm: '32%'}}}
          />
          <ControlledMaskedTextField
            control={control}
            errors={errors}
            name="rAccount"
            label="Расч. счет"
            format="####################"
            sx={{width: {sm: '32%'}}}
          />
          <ControlledMaskedTextField
            control={control}
            errors={errors}
            name="kAccount"
            label="Корр. счет"
            format="####################"
            sx={{width: {sm: '32%'}}}
          />
          <ControlledMaskedTextField
            control={control}
            errors={errors}
            name="phone"
            label="Телефон организации"
            format="+7-(###)-###-####"
            mask="_"
            sx={{width: {sm: '49%'}}}
          />
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="email"
            label="Email организации"
            type="email"
            sx={{width: {sm: '49%'}}}
          />
        </Stack>
      </InformationBlock>
      <InformationBlock title="Руководитель">
        <Stack
          spacing={{s: 1}}
          direction={{sm: 'row'}}
          flexWrap={{sm: 'wrap'}}
          justifyContent="space-between"
          useFlexGap={true}>
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="directorSurname"
            label="Фамилия*"
            type="fio"
            sx={{width: {sm: '49%'}}}
            pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
          />
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="directorName"
            label="Имя*"
            type="fio"
            sx={{width: {sm: '49%'}}}
            pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
          />
          <ControlledAutocomplete
            control={control}
            errors={errors}
            name="directorSecondName"
            label="Отчество"
            type="fio"
            sx={{width: {sm: '49%'}}}
            pattern={/^(^[-а-яА-ЯёЁ \s]+$[^0-9]*)$/}
          />
          <ControlledTextField
            control={control}
            errors={errors}
            name="directorProfession"
            label="Должность*"
            sx={{width: {sm: '49%'}}}
          />
          <ControlledSelect
            control={control}
            errors={errors}
            name="basisTypeId"
            label="Действующий на основании"
            options={BASIS_TYPES}
            controlStyles={{width: isTablet ? '49%' : '100%'}}
            sx={{width: '100%'}}
          />
          {!basisType || basisType === BASIS_TYPES[0].id ? null : (
            <>
              <ControlledTextField
                control={control}
                errors={errors}
                name="basisNumber"
                label="Номер"
                sx={{width: {sm: '24%'}}}
              />
              <ControlledDatePicker
                control={control}
                errors={errors}
                name="basisDate"
                label="Дата"
                sx={{width: {sm: '24%'}}}
              />
            </>
          )}
        </Stack>
      </InformationBlock>
    </Box>
  );
};

export default EntityForm;
