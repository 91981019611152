import Select from 'react-select';
import {Button, Stack, Typography} from '@mui/material';
import {Controller, get, useFormContext} from 'react-hook-form';
import {useGetCustomersQuery} from '../../../api/slices/user';
import useToggle from '../../../hooks/useToggle';

import ContractInfo from './CreateNewCustomer';

import RegistrationStep from './modules/RegistrationStep';
import MUIModal from '../../../components/MUIModal';
import dayjs from 'dayjs';

const CustomerInfo = ({setCustomer, getContractCost}) => {
  const {data} = useGetCustomersQuery();

  const [isOpen, toggle] = useToggle(false);
  const {
    control,
    formState: {errors},
    setValue,
  } = useFormContext();

  const setCustomerTypeId = (e) => {
    const customer = data?.data.find((elem) => elem.id === e.value);

    setCustomer(customer.customerTypeId);
    // setValue('postAddress', customer.postAddress);
    // checkExpressDelivery({address: customer.postAddress})
    //   .unwrap()
    //   .then(({data}) => {
    //     setValue('deliveryRegionId', data.id);
    //     getContractCost();
    //   })
    //   .catch(({data}) => console.log(data.error));
  };

  const customersList = data?.data.map((customer) => ({
    value: customer.id,
    label: customer,
  }));

  const customerCreationDate = (cDate) => {
    if (!dayjs(cDate).isValid()) return '';
    const date = dayjs(cDate).format('DD.MM.YYYY');

    return `Дата создания: ${date}`;
  };

  const errorMessage = get(errors, 'customerId');

  return (
    <RegistrationStep
      step="Шаг 2 из 3"
      title="Заполнение информации для подготовки договора"
      subtitle="Ваши персональные данные требуются для подготовки Договора на оказание образовательных услуг в целях реализации ст.54 Федерального закона от 29.12.2012 N 273-ФЗ «Об образовании в Российской Федерации»">
      <Stack>
        <Controller
          control={control}
          name="customerId"
          render={({field: {value, name, onChange, onBlur, ref}}) => (
            <>
              <Select
                name={name}
                value={customersList?.filter((c) => c.value === value) ?? ''}
                placeholder="Выберите заказчика"
                options={customersList}
                onChange={(e) => {
                  onChange(e.value);
                  setValue('relationContractId', null);
                  setCustomerTypeId(e);
                }}
                formatOptionLabel={({label}) => (
                  <Stack>
                    <Typography variant="body1">{label?.complexName}</Typography>
                    <Typography variant="subtitle1">
                      {customerCreationDate(label?.created_at)} / ИНН: {label?.inn}
                    </Typography>
                  </Stack>
                )}
                onBlur={onBlur}
                components={{IndicatorSeparator: () => null}}
                classNamePrefix="select"
                ref={ref}
              />
              <Typography color="error" align="center" gutterBottom>
                {errorMessage?.message}
              </Typography>
            </>
          )}
        />
      </Stack>

      <Button variant="contained" color="secondary" onClick={toggle}>
        Создать нового заказчика
      </Button>

      <MUIModal isOpen={isOpen} toggle={toggle}>
        <ContractInfo close={toggle} />
      </MUIModal>
    </RegistrationStep>
  );
};

export default CustomerInfo;
