import {useRef, useState} from 'react';
import classNames from 'classnames/bind';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {LoadingButton} from '@mui/lab';
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useSendDiplomaMutation} from '../../api/slices/student';

import ImageIcon from './Icons/imageIcon';
import SuccessIcon from './Icons/successIcon';
import ErrorIcon from './Icons/errorIcon';
import DeleteIcon from './Icons/deleteIcon';
import PdfIcon from './Icons/pdfIcon';
import BmpIcon from './Icons/BmpIcon';
import WordIcon from './Icons/wordIcon';

import classes from './styles.module.scss';

const cx = classNames.bind(classes);

const fileType = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': <WordIcon />,
  'application/pdf': <PdfIcon />,
  'image/jpeg': <ImageIcon />,
  'image/jpg': <ImageIcon />,
  'image/png': <ImageIcon />,
  'image/bmp': <BmpIcon />,
};

const fileTypeArray = [...Object.keys(fileType)];

const FileUploader = ({close, email}) => {
  const [files, setFiles] = useState([]);
  const [drag, setDrag] = useState(false);
  const [isOverSize, setIsOverSize] = useState(false);
  const [isFormatWrong, setIsFormatWrong] = useState(false);
  const [expandedAccordions, setExpandedAccordions] = useState([]);

  const inputRef = useRef(null);
  const {handleSubmit} = useForm();
  const [SendDiploma, {isError, isLoading}] = useSendDiplomaMutation();

  const handleFileInput = (e) => {
    e.preventDefault();
    let documents = [...e.target.files];

    documents.forEach((document) => {
      if (document.size > 10000000) {
        setIsOverSize(true);
      }
      if (!fileTypeArray.includes(document.type)) {
        setIsFormatWrong(true);
      }
    });
    setFiles([...files, ...documents]);
  };

  const onSubmit = () => {
    const formData = new FormData();

    files.forEach((file) => formData.append('diplomContent[]', file));

    SendDiploma(formData)
      .unwrap()
      .then(() => {
        close();
        toast.success('Ваш диплом успешно загружен, направлен на проверку.', {
          autoClose: 3000,
          hideProgressBar: true,
        });
      })
      .catch((err) => console.log(err));
  };

  const handleDrag = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDrag(true);
  };

  const handleLeave = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setDrag(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let documents = [...e.dataTransfer.files];

    documents.forEach((document) => {
      if (document.size > 10000000) {
        setIsOverSize(true);
      }
      if (!fileTypeArray.includes(document.type)) {
        setIsFormatWrong(true);
      }
    });
    setFiles([...files, ...documents]);
    setDrag(false);
  };

  const deleteFile = (i) => {
    new Promise((resolve, reject) => {
      resolve(files.filter((file, index) => index !== i));
    })
      .then((res) => {
        setFiles([...res]);

        let bigFile = res.find((elem) => elem.size > 10000000);
        let wrongTypeFile = res.find((elem) => !fileTypeArray.includes(elem.type));
        return {bigFile, wrongTypeFile};
      })
      .then(({bigFile, wrongTypeFile}) => {
        if (bigFile === undefined) {
          setIsOverSize(false);
        } else {
          setIsOverSize(true);
        }
        if (wrongTypeFile === undefined) {
          setIsFormatWrong(false);
        } else {
          setIsFormatWrong(true);
        }
      });
  };

  const isUnavailable = isFormatWrong || isOverSize || files.length === 0 || files.length > 30;

  const getSize = (size) => (size < 10000 ? '0.01' : (size / 1000000).toString().slice(0, 4));

  const handleExpandAccordion = (id) => {
    const alreadyExpanded = expandedAccordions.find((acc) => acc === id);
    if (alreadyExpanded) {
      setExpandedAccordions((prev) => [...prev.filter((acc) => acc !== id)]);
    } else {
      setExpandedAccordions((prev) => [...prev, id]);
    }
  };

  const isExpanded = (id) => expandedAccordions.includes(id);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{minWidth: {sm: 650, xs: 400, s: 270}}}>
      <Box>
        <div className={classes.file__loader}>
          <div className={classes.files}>
            {files.map((file, index) => (
              <div
                key={file.name + index}
                className={cx({
                  file__item: true,
                  error: file.size > 10000000 || !fileTypeArray.includes(file.type),
                })}>
                <div className={classes.file__content}>
                  <div className={classes.item__img}>
                    {fileTypeArray.includes(file.type) ? fileType[file.type] : <BmpIcon />}
                  </div>
                  <div className={classes.text}>
                    <span className={classes.item__name}>{file.name}</span>
                    {file.size > 10000000 && (
                      <>
                        <span className={classes.attention}>Размер файла не может превышать 10 МБ</span>
                      </>
                    )}
                  </div>
                  <span className={classes.size}>{getSize(file.size)} МБ</span>
                  <div className={classes.status}>
                    {file.size > 10000000 || !fileTypeArray.includes(file.type) ? <ErrorIcon /> : <SuccessIcon />}
                  </div>
                  <div className={classes.delete} onClick={() => deleteFile(index)}>
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            className={cx({drag_and_drop_form: true, active: drag})}
            onDragLeave={handleLeave}
            onDragOver={handleDrag}
            onDrop={handleDrop}>
            <input
              id="drag_and_drop"
              ref={inputRef}
              type="file"
              className={classes.input}
              multiple
              onChange={handleFileInput}
            />
            <label htmlFor="drag_and_drop" className={classes.label}>
              <div className={classes.label__text}>
                {drag ? (
                  <p className={classes.drag__active}>Перетащите файл сюда</p>
                ) : (
                  <>
                    <span className={classes.link}>
                      Перетащите сюда или <span className={classes.underline}>выберите файлы</span> для загрузки
                    </span>
                    <p className={classes.notification}>
                      Загружаемый файл должен соответствовать формату pdf, jpeg, jpg, bmp или png и не должен превышать
                      по объёму 10МБ. Количество загружаемых файлов — не более 10.
                    </p>
                  </>
                )}
              </div>
            </label>
          </div>
          {isError && (
            <Typography variant="body1" textAlign="justify" color="error" mt={1}>
              Что-то пошло не так, повторите попытку позже
            </Typography>
          )}
          {isFormatWrong && (
            <Typography variant="body1" textAlign="justify" color="error" mt={1}>
              Вы пытайтесь загрузить файл недопустимого формата, убедитесь что загружаемые файлы соответсвуют одному из
              следующих форматов: pdf, jpeg, jpg, bmp или png
            </Typography>
          )}
        </div>
      </Box>
      <LoadingButton
        loading={isLoading}
        disabled={isUnavailable}
        variant="contained"
        coloe="primary"
        size="small"
        type="submit"
        fullWidth>
        Загрузить
      </LoadingButton>

      <Accordion
        expanded={isExpanded('acc_1')}
        disableGutters
        onChange={() => handleExpandAccordion('acc_1')}
        sx={{m: 0, mt: 4, border: 'none', boxShadow: 'none', borderBottom: '1px solid #0249fe', position: 'unset'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{border: 'none', color: isExpanded('acc_1') && '#0249fe', fontSize: 18}}>
          В дипломе указаны другие фамилия/имя/отчество?
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            Пожалуйста, загрузите дополнительно скан- копию документа, подтверждающего смену ФИО, если ваше ФИО в
            дипломе отличается от ФИО, указанного в договоре на обучение.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={isExpanded('acc_2')}
        disableGutters
        onChange={() => handleExpandAccordion('acc_2')}
        sx={{border: 'none', boxShadow: 'none', borderBottom: '1px solid #0249fe'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{border: 'none', color: isExpanded('acc_2') && '#0249fe', fontSize: 18}}>
          Диплом не на русском языке?
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            Пожалуйста, загрузите скан перевода, если ваш диплом не на русском языке
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={isExpanded('acc_3')}
        disableGutters
        onChange={() => handleExpandAccordion('acc_3')}
        sx={{border: 'none', boxShadow: 'none', borderBottom: '1px solid #0249fe'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{border: 'none', color: isExpanded('acc_3') && '#0249fe', fontSize: 18}}>
          Когда я получу доступ к экзамену?
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            В течение 24 часов менеджеры учебного центра проводят проверку загруженных документов и открывают доступы к
            пробному тестированию и итоговому экзамену. Ответ о доступе к тестированию поступит на вашу электронную
            почту
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={isExpanded('acc_4')}
        disableGutters
        onChange={() => handleExpandAccordion('acc_4')}
        sx={{border: 'none', boxShadow: 'none', borderBottom: '1px solid #0249fe'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{border: 'none', color: isExpanded('acc_4') && '#0249fe', fontSize: 18}}>
          Проблема при загрузке документов?
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            Пожалуйста, сначала проверьте соответствует ли файл требованиям системы (формат, объем, количество). Если
            при соблюдении условий не получается подгрузить диплом, то просим отправить письмо с файлом диплома на нашу
            электронную почту <a href={`mailto:${email}`}> {email} </a>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={isExpanded('acc_5')}
        disableGutters
        onChange={() => handleExpandAccordion('acc_5')}
        sx={{border: 'none', boxShadow: 'none', borderBottom: '1px solid #0249fe'}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{border: 'none', color: isExpanded('acc_5') && '#0249fe', fontSize: 18}}>
          Почему необходимо предоставлять диплом?
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2">
            В соответствии с п.3 ст.76 Федерального закона от 29.12.2012 №273-ФЗ "Об образовании в Российской Федерации"
            к освоению дополнительных профессиональных программ допускаются лица, имеющие среднее профессиональное и
            (или) высшее образование, т.о. необходимо предоставить документ (диплом), подтверждающий наличие у слушателя
            высшего или среднего профессионального образования
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default FileUploader;
