export default function extractPhone(phone = '') {
  let newPhone = '';
  if (phone === null) return '';

  phone.split('').forEach((l) => {
    if (!isNaN(parseInt(l))) {
      newPhone = newPhone + l;
    }
  });
  return newPhone;
}
