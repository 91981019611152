import ReactDOM from 'react-dom';
import {CSSTransition} from 'react-transition-group';
import CloseIcon from '@mui/icons-material/Close';

import classes from './styles.module.scss';
import {useEffect} from 'react';

const Modal = ({onClose, title = '', children, show}) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [show]);

  return ReactDOM.createPortal(
    <CSSTransition in={show} timeout={300} unmountOnExit classNames="modal">
      <div
        className={classes.modal}
        onClick={(e) => {
          e.preventDefault();
          onClose();
        }}>
        <CSSTransition in={show} timeout={300} unmountOnExit classNames="modal_content">
          <div className={classes.content} onClick={(e) => e.stopPropagation()}>
            <button className={classes.close} onClick={onClose}>
              <CloseIcon />
            </button>
            {title && <h2 className={classes.title}>{title}</h2>}
            <div>{children}</div>
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>,
    document.getElementById('modal-root')
  );
};

export default Modal;
