import {Button, IconButton, Stack, Typography, useMediaQuery} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const StudentCardProgramsTitle = ({educationProgram, educationProgramQuantity, removeProgram, removeStudent}) => {
  const isTablet = useMediaQuery('(min-width:768px)');
  const isDesktop = useMediaQuery('(min-width:1000px)');

  const showDeleteUserButton = educationProgram === 1 && isTablet;

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={{md: 0.5, s: 1}} mt={{sm: 0, s: 2}}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={{md: 'space-between', s: 'start'}}
        mb={showDeleteUserButton || educationProgram !== 1 ? 0 : 1.8}>
        <Typography variant="h4" mr={{md: 2.5, s: 1.5}}>
          Программа обучения №{educationProgram}
        </Typography>
        {educationProgramQuantity > 1 ? (
          <IconButton aria-label="delete" onClick={removeProgram} sx={{p: 0}}>
            <CloseIcon fontSize="15px" />
          </IconButton>
        ) : null}
      </Stack>
      {showDeleteUserButton ? (
        isDesktop ? (
          <Button endIcon={<CloseIcon fontSize="15px" />} variant="text" color="error" onClick={removeStudent}>
            <Typography variant="caption">Удалить слушателя</Typography>
          </Button>
        ) : (
          <IconButton aria-label="delete" onClick={removeStudent} sx={{p: 0}}>
            <CloseIcon fontSize="15px" />
          </IconButton>
        )
      ) : null}
    </Stack>
  );
};

export default StudentCardProgramsTitle;
