import {object, string} from 'yup';
import {Button, Typography} from '@mui/material';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {toast} from 'react-toastify';
import {useShareBonusesMutation} from '../../api/slices/user';

import ControlledTextField from '../../components/FieldRegister/NestedTextField';
import classes from './styles.module.scss';

const Share = ({close}) => {
  const [shareBonuses, {error}] = useShareBonusesMutation();

  const schema = object().shape({
    email: string().email('Пожалуйста введите корректный email').required('Значение не может быть пустым'),
    bonusCount: string()
      .required('Значение не может быть пустым')
      .matches(/^-?\d+\.?\d*$/, 'Данное поле может содержать только числа'),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: {errors},
  } = useForm({resolver: yupResolver(schema)});

  const onSubmit = (data) => {
    shareBonuses(data)
      .unwrap()
      .then(() => {
        toast.success(`Бонусы успешно отправлены на указанный Вами email:${data.email}`, {
          autoClose: 2000,
          hideProgressBar: true,
        });
        close();
        reset();
      })
      .catch((err) => new Error(err));
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h2">Укажите почту получателя и количество бонусов</Typography>
      <ControlledTextField control={control} errors={errors} name="email" label="Email" />
      <ControlledTextField control={control} errors={errors} name="bonusCount" label="Кол-во бонусов" />
      {error?.data && (
        <div className={classes.error}>
          <span className="text attention center">{error?.data.error}</span>
        </div>
      )}
      <Button type="submit" variant="contained">
        Отправить
      </Button>
    </form>
  );
};

export default Share;
